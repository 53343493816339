import styled, { css } from 'styled-components'

interface StyledLegalAgreementCheckboxProps {
  withContainer?: boolean
}

const StyledLegalAgreementCheckbox = styled.div<StyledLegalAgreementCheckboxProps>`
  display: flex;
  margin-bottom: 16px;

  .link {
    cursor: pointer;
    text-decoration: underline;
  }

  ${({ withContainer }) =>
    withContainer
      ? css`
          background: ${({ theme }) => theme.color?.CONTENT_ON_COLOR_PRIMARY};
          border-radius: 16px;
          padding: 16px;
        `
      : ''};
`

export { StyledLegalAgreementCheckbox }
