import { userApi } from '@root/api'
import { marketplacePersonalLoanLink } from './constants'

const goToMarketPlace = async (
  trafficSrc: 'INSTACASH_DENIAL' | 'preference_selection',
  medium: 'decline' | 'trial_screen' | 'onboarding'
): Promise<void> => {
  const { tempToken } = await userApi.fetchExchangeToken()

  window.location.href = `${marketplacePersonalLoanLink(
    trafficSrc,
    medium
  )}&t=${tempToken}`
}

export { goToMarketPlace }
