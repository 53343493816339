import { Title, Body, Switch } from '@moneylion/mlds-web'
import { useBackdoorStore } from '@root/store'
import labels from './labels'

const BackdoorCBPlus = (): JSX.Element => {
  const isCbplusBvLinkingBackdoorEnabled = useBackdoorStore(
    (state) => state.isCbplusBvLinkingBackdoorEnabled
  )
  const setIsCbplusBvLinkingBackdoorEnabled = useBackdoorStore(
    (state) => state.setIsCbplusBvLinkingBackdoorEnabled
  )

  return (
    <>
      <div className="mb-3">
        <Title size="medium">{labels.title}</Title>
      </div>
      <div className="flex ai-center">
        <Switch
          value={isCbplusBvLinkingBackdoorEnabled}
          onChange={(e) => {
            setIsCbplusBvLinkingBackdoorEnabled(e.target.checked)
          }}
        />
        <Body size="medium">
          {labels.cbplusBvLinking}{' '}
          {isCbplusBvLinkingBackdoorEnabled ? '(On)' : '(Off)'}
        </Body>
      </div>
    </>
  )
}

export { BackdoorCBPlus }
