import styled, { css } from 'styled-components'

interface HeadingContainerProps {
  maxWidth?: number
  marginBottom?: number
}

export const HeadingContainer = styled.div<HeadingContainerProps>`
  text-align: center;
  margin: 0 auto 16px;

  ${({ maxWidth }) =>
    maxWidth
      ? css`
          max-width: ${maxWidth}px;
        `
      : ''};
  ${({ marginBottom }) =>
    marginBottom
      ? css`
          margin-bottom: ${marginBottom * 8}px;
        `
      : ''};
`

export const MainContainer = styled.div`
  max-width: 448px;
  margin: 0 auto 16px;
`

export const WhiteRoundedContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color?.CONTENT_ON_COLOR_PRIMARY};
  border-radius: 16px;
`

export const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.color?.BACKGROUND_CONTENT};
  border-radius: 16px;
  overflow: hidden;
`

export const BVLinkingContainer = styled.div`
  min-width: 448px;

  @media only screen and (max-width: 496px) {
    min-width: 100%;
  }
`
