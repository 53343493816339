import {
  IGetUserRewardsResponse,
  IGetInvestmentAccountResponse,
  AddressValidateReq,
  AddressValidateRes,
  UpdateUserReq,
  UpdateUserRes,
  ICheckIfUserEmailExistsPayload,
  ICheckIfUserEmailExistsResponse,
  IGetUserProfileResponse,
  IConfirmDeliveryResponse,
  IChallengeMFAIdPayload,
  IResetPasswordPayload,
  ISendForgetPasswordEmailPayload,
  ISendForgetPasswordEmailResponse,
  IChallengeMFAIdResponse,
  IConvertExchangeTokenResp,
  IExchangeTokenResponse,
  ILoginUserPayload,
  ILoginUserResponse,
  InitiatePhoneNumberUpdateReq,
  InitiatePhoneNumberUpdateRes,
} from '@root/types/User'
import { useTestValuesStore } from '@root/store'
import { ENV } from '@root/config'
import { https } from '@root/utils'
import recaptchaApi from './recaptchaApi'

const loginUser = async (
  payload: ILoginUserPayload,
  signal: AbortSignal
): Promise<ILoginUserResponse> => {
  const { shouldBypassCaptcha } = useTestValuesStore.getState()
  const body: any = { ...payload }

  if (!shouldBypassCaptcha || ENV.APP_ENV === 'production') {
    const recaptchaToken = await recaptchaApi.getRecaptchaToken('LOGIN')
    body.recaptchaToken = recaptchaToken
  }

  const resp = await (
    await https({
      shouldAddRequestId: true,
      useRecaptchaHeader: true,
      shouldCheckToken: false,
      useAuthApi: true,
      shouldBypassCaptcha,
      signal,
    })
  ).post('/login', body)

  return resp.data as ILoginUserResponse
}

const fetchExchangeToken = async (): Promise<IExchangeTokenResponse> => {
  const resp = await (await https({ useAuthApi: true })).post('/exchange')
  return resp.data
}

const convertExchangeToken = async (
  tempToken: string
): Promise<IConvertExchangeTokenResp> => {
  const body = {
    tempToken,
    recaptchaToken: await recaptchaApi.getRecaptchaToken('token_exchange'),
  }

  const resp = await (
    await https({
      useAuthApi: true,
      useRecaptchaHeader: true,
      shouldAddRequestId: true,
      shouldCheckToken: false,
    })
  ).put('/exchange/convert', body)

  return resp.data
}

const checkIfUserEmailExists = async (
  payload: ICheckIfUserEmailExistsPayload
): Promise<ICheckIfUserEmailExistsResponse> => {
  const token = await recaptchaApi.getRecaptchaToken('CHECK_EMAIL')
  const resp = await (
    await https()
  ).get(`/user/email?email=${payload.email}&recaptchaToken=${token}`)

  return resp.data as ICheckIfUserEmailExistsResponse
}

const getUserProfile = async (
  withSsn?: boolean
): Promise<IGetUserProfileResponse> => {
  const resp = await (
    await https()
  ).get('/user/getProfile', {
    params: { withProfilePicturePreview: true, withSsn },
  })
  return resp.data as IGetUserProfileResponse
}

/* MFA APIs START */

// Request for MFA code
const confirmDelivery = async (
  deliveryOptionId: string,
  signal: AbortSignal
): Promise<IConfirmDeliveryResponse> => {
  const resp = await (
    await https({ useAuthApi: true, signal })
  ).post('/confirmDelivery', {
    deliveryOptionId,
  })
  return resp.data as IConfirmDeliveryResponse
}

const resendDelivery = async (
  mfaSessionId: string,
  signal: AbortSignal
): Promise<IConfirmDeliveryResponse> => {
  const resp = await (
    await https({ useAuthApi: true, signal })
  ).post('/resendDelivery', {
    mfaSessionId,
  })
  return resp.data as IConfirmDeliveryResponse
}

const challengeMFAId = async (
  payload: IChallengeMFAIdPayload,
  signal: AbortSignal
): Promise<IChallengeMFAIdResponse> => {
  const resp = await (
    await https({ useAuthApi: true, signal })
  ).post('login/challenge', payload)
  return resp.data
}

const sendForgetPasswordEmail = async (
  payload: ISendForgetPasswordEmailPayload,
  signal: AbortSignal
): Promise<ISendForgetPasswordEmailResponse> => {
  const { shouldBypassCaptcha } = useTestValuesStore.getState()
  const body: ISendForgetPasswordEmailPayload = { ...payload }

  if (!shouldBypassCaptcha || ENV.APP_ENV === 'production') {
    const recaptchaToken = await recaptchaApi.getRecaptchaToken(
      'reset_password'
    )
    body.recaptchaToken = recaptchaToken
  }

  const resp = await (
    await https({
      useRecaptchaHeader: true,
      shouldBypassCaptcha,
      shouldCheckToken: false,
      useNewDashboardHeader: true,
      signal,
    })
  ).post('user/forgetPassword', body)

  return resp.data
}

const resetPassword = async (
  payload: IResetPasswordPayload,
  signal: AbortSignal
): Promise<object> => {
  const { shouldBypassCaptcha } = useTestValuesStore.getState()
  const body: IResetPasswordPayload = { ...payload }

  if (!shouldBypassCaptcha || ENV.APP_ENV === 'production') {
    const recaptchaToken = await recaptchaApi.getRecaptchaToken(
      'reset_password'
    )
    body.recaptchaToken = recaptchaToken
  }
  const resp = await (
    await https({
      useRecaptchaHeader: true,
      shouldBypassCaptcha,
      shouldCheckToken: false,
      signal,
    })
  ).post('user/resetPassword', body)

  return resp.data
}

const getUserRewards = async (): Promise<IGetUserRewardsResponse> => {
  const rewardsResponse = await (await https()).get('user/rewards')
  return rewardsResponse.data.data.data
}

const getUserInvestmentAccount =
  async (): Promise<IGetInvestmentAccountResponse> => {
    const rewardsResponse = await (await https()).get('user/investment')
    return rewardsResponse.data.data
  }
/* MFA APIs END */

const validateAddress = async (
  payload: AddressValidateReq
): Promise<AddressValidateRes> => {
  const response = await (
    await https()
  ).post('user/address/v2/validate', payload)
  return response.data
}

const updateUser = async (payload: UpdateUserReq): Promise<UpdateUserRes> => {
  const response = await (
    await https({ expectErrorData: true })
  ).put(`user/me`, payload)
  return response.data
}

/**
 * To challenge and update phone number
 */
const challengeAndUpdatePhoneNumber = async (
  body: InitiatePhoneNumberUpdateReq
): Promise<InitiatePhoneNumberUpdateRes> => {
  const response = await (
    await https({ expectErrorData: true })
  ).post(`user/initiatePhoneNumber/update`, body)

  return response.data
}

const userApi = {
  loginUser,
  fetchExchangeToken,
  convertExchangeToken,
  checkIfUserEmailExists,
  getUserProfile,
  confirmDelivery,
  resendDelivery,
  challengeMFAId,
  sendForgetPasswordEmail,
  resetPassword,
  getUserRewards,
  getUserInvestmentAccount,
  validateAddress,
  updateUser,
  challengeAndUpdatePhoneNumber,
}

export default userApi
