import { Card, Icon, Body } from '@moneylion/mlds-web'
import React from 'react'
import styled from 'styled-components'
import { ENV } from '@root/config'
import { useAuthStore } from '@root/store'
import { useRemoteConfig } from '@root/hooks'
import { REMOTE_CONFIG_FEATURE_FLAG } from '@root/constants/featureFlagNames'
import labels from './labels'

const StyledMobileFooter = styled(Card)`
  .legal-links {
    gap: 16px;

    p {
      color: ${({ theme }) => theme.color?.CONTENT_SECONDARY};
      white-space: nowrap;
    }
  }

  .social-channels {
    gap: 20px;

    a {
      display: inline-block;
      height: 20px;
      font-size: 20px;

      i:before {
        height: 20px;
        line-height: 0.3;
      }
    }
  }
`

interface MobileFooterProps {
  className?: string
}

const MobileFooter: React.FunctionComponent<MobileFooterProps> = ({
  className = '',
}) => {
  const user = useAuthStore((state) => state.user)
  const { treatment, status } = useRemoteConfig(
    REMOTE_CONFIG_FEATURE_FLAG.WEB.cpcEnabled
  )
  const shouldEnableCPC = treatment?.asBoolean()

  return (
    <StyledMobileFooter className={`hidden-desktop ${className}`}>
      <div className="legal-links flex flex-col mb-4">
        {status === 'success' && shouldEnableCPC && (
          <Body size="medium">
            <a
              href={`${ENV.COMMUNICATION_PREFERENCES_URL}?email=${user?.email}`}
              target="_blank"
              rel="noreferrer"
              aria-label="Communication Preference Link"
            >
              {labels.communicationPreferences}
            </a>
          </Body>
        )}
        <Body size="medium">
          <a
            href={`${ENV.MONEYLION_HOMEPAGE_URL}/privacy-notice`}
            target="_blank"
            rel="noreferrer"
            aria-label="Privacy Policy Link"
          >
            {labels.privacyPolicy}
          </a>
        </Body>
        <Body size="medium">
          <a
            href={`${ENV.MONEYLION_HOMEPAGE_URL}/terms-and-conditions`}
            target="_blank"
            rel="noreferrer"
            aria-label="Terms & Conditions Link"
          >
            {labels.termsAndConditions}
          </a>
        </Body>
        <Body size="medium">
          <a
            href={`${ENV.MONEYLION_HOMEPAGE_URL}/terms-and-conditions#advertiser`}
            target="_blank"
            rel="noreferrer"
            aria-label="Advertiser Disclosure Link"
          >
            {labels.advertiserDisclosure}
          </a>
        </Body>
        <Body size="medium">
          <a
            href={ENV.MONEYLION_HELP_LINK}
            target="_blank"
            rel="noreferrer"
            aria-label="Support link"
          >
            {labels.support}
          </a>
        </Body>
      </div>
      <div className="social-channels flex">
        <a
          href={ENV.MONEYLION_SOCIAL_LINKS.facebook}
          target="_blank"
          rel="noreferrer"
          aria-label="Facebook link"
        >
          <Icon name="facebook" />
        </a>
        <a
          href={ENV.MONEYLION_SOCIAL_LINKS.twitter}
          target="_blank"
          rel="noreferrer"
          aria-label="Twitter link"
        >
          <Icon name="twitter" />
        </a>
        <a
          href={ENV.MONEYLION_SOCIAL_LINKS.linkedin}
          target="_blank"
          rel="noreferrer"
          aria-label="LinkedIn link"
        >
          <Icon name="linkedin" />
        </a>
      </div>
    </StyledMobileFooter>
  )
}

export { MobileFooter }
