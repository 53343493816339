import styled from 'styled-components'

export const BackdoorModalContainer = styled.div`
  width: 500px;
  background: white;
  height: 500px;
  position: absolute;
  bottom: 35px;
  right: 0;
  border: 1px solid black;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  overflow: scroll;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.mobile}) {
    width: 88vw;
  }

  .border-container {
    border-bottom: 1px solid ${({ theme }) => theme.color?.BORDER};
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`
