import IMarketplaceGetCategories, { ActiveOffer } from '@root/types/Marketplace'
import { https } from '@root/utils'

export interface APIResponse<T> {
  data: T
  code: string
  message: string
}

const getContentCategories = async (
  userId: string
): Promise<IMarketplaceGetCategories[]> => {
  const categories = await (
    await https()
  ).get('/marketplace/content/categories', {
    params: {
      userId,
    },
  })
  return categories?.data?.data?.data
}

const getActiveOffers = async (userId: string): Promise<ActiveOffer[]> => {
  const activeOffers = await (
    await https()
  ).get<APIResponse<ActiveOffer[]>>(`/marketplace/v1/offers/loan/${userId}`)
  return activeOffers.data.data
}

const marketplaceApi = {
  getContentCategories,
  getActiveOffers,
}

export default marketplaceApi
