import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  .icon-wrapper {
    background-color: ${({ theme }) => theme.color?.CONTENT_PRIMARY};
    padding: 8px;
    border-radius: 100%;
    color: ${({ theme }) => theme.color?.BACKGROUND_CONTENT};
  }
  z-index: 5;
`

export const StyledLayout = styled.div<{ maxWidth: string }>`
  display: flex;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth}};
  .placeholder {
    margin-top: 53px;
  }

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
      padding-top: 50px;
      padding-bottom: 24px;
    }
    @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
      padding: 50px 16px 24px 16px;
    }
  `}
`
