import { TOnboardingFlow } from '@root/utils'

export const estimatedTimeToFinish: Record<TOnboardingFlow, number> = {
  organic: 5,
  instacash: 7,
  roarmoney: 5,
  cbplus: 5,
  crypto: 5,
  investment: 5,
}
