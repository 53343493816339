import { create } from 'zustand'
import { marketplaceApi } from '@root/api'
import IMarketplaceGetCategories, { ActiveOffer } from '@root/types/Marketplace'

interface MPLInitialState {
  categories: IMarketplaceGetCategories[]
  activeOffers: ActiveOffer[]
  areActiveOffersLoading: boolean
  areCategoriesLoading: boolean
}

export interface MPLState extends MPLInitialState {
  getCategories: (userId: string) => void
  getActiveOffers: (userId: string) => void
  reset: () => void
}

const defaultState: MPLInitialState = {
  categories: [],
  activeOffers: [],
  areActiveOffersLoading: true,
  areCategoriesLoading: true,
}

const useMPLStore = create<MPLState>()((set) => ({
  ...defaultState,
  getCategories: async (userId: string) => {
    const categories = await marketplaceApi.getContentCategories(userId)
    set({ categories, areCategoriesLoading: false })
  },
  getActiveOffers: async (userId: string) => {
    const activeOffers = await marketplaceApi.getActiveOffers(userId)

    set({ activeOffers, areActiveOffersLoading: false })
  },
  reset: () => {
    set(defaultState)
  },
}))

export { useMPLStore }
