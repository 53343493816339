import { Card } from '@moneylion/mlds-web'
import React, { useState } from 'react'
import styled from 'styled-components'
import IUser from '@root/types/User'
import { DownloadAppModal } from '@root/components/Modals'
import { maskPhoneNumber, maskSSN, maskEmail } from '@root/utils/masks'
import { analytics as analyticsUtils } from '@root/utils'
import { ProfileDataField } from '../../components/ProfileDataField'
import labels from './labels'

interface PersonalInfoProps {
  user: IUser | null
  roarTag?: string
}

const StyledCard = styled(Card)`
  padding: 12px 24px;

  > * + * {
    border-top: 1px solid
      ${({ theme }) => theme.color?.BUTTON_SECONDARY_BACKGROUND};
  }
`

const PersonalInfo = ({ user, roarTag }: PersonalInfoProps): JSX.Element => {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  // closing mechanism for modal exit button
  const toggleDialog = () => {
    setShowDialog(!showDialog)
  }

  const toggleDialogWithTracking = (element: string) => {
    setShowDialog(!showDialog)
    if (!showDialog) {
      analyticsUtils.trackWithOSPlatformPrefilledProfile(element)
    }
  }

  return (
    <>
      <DownloadAppModal isOpen={showDialog} toggle={toggleDialog} />
      <StyledCard className="personal-info">
        <ProfileDataField
          name={labels.name}
          className="capitalize"
          content={`${user?.firstName} ${user?.lastName}`}
        />

        {user?.email && (
          <ProfileDataField name="Email" content={maskEmail(user.email)} />
        )}
        {roarTag && (
          <ProfileDataField
            name={labels.roarTag}
            content={roarTag}
            label={
              !user?.roarTagsData.areRoarTagsSearchable
                ? labels.private
                : labels.public
            }
            toggleDialog={() => toggleDialogWithTracking('roartag-edit')}
          />
        )}
        {user?.ssn && (
          <ProfileDataField
            name={labels.socialSecurityNumber}
            content={maskSSN(`*****${user.ssn.slice(9)}`)}
            isVerified
          />
        )}
        {user?.phoneNumber && (
          <ProfileDataField
            name={labels.phoneNumber}
            content={maskPhoneNumber(
              `******${user.phoneNumber.number.slice(6)}`
            )}
            isVerified={user.phoneNumber.status === 'VERIFIED'}
            toggleDialog={() => {
              toggleDialogWithTracking('phone-edit')
            }}
          />
        )}
        {user?.address && (
          <ProfileDataField
            name={labels.address}
            content={
              <>
                {user.address.addressLine1}

                {user?.address?.addressLine2 && (
                  <>
                    <br />
                    {user?.address?.addressLine2}
                  </>
                )}
                <br />
                {`${user.address.city}, ${user.address?.state} ${user.address?.postalCode}`}
                <br />
                {user.address.country}
              </>
            }
            isVerified
            toggleDialog={() => {
              toggleDialogWithTracking('address-edit')
            }}
          />
        )}
      </StyledCard>
    </>
  )
}

export { PersonalInfo }
