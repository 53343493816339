import { Body, Label, List } from '@moneylion/mlds-web'
import { ListTitle, ListTitleProps } from '../../components/ListTitle'
import { WhiteRoundedContainer } from '@onboarding/components'

interface TextListLabelProps {
  leftLabel: string
  rightLabel?: string
}

interface TextListProps {
  listTitle?: ListTitleProps
  items: TextListLabelProps[]
}

const TextList = ({ listTitle, items }: TextListProps): JSX.Element => {
  return (
    <WhiteRoundedContainer className="mb-3">
      <List>
        {listTitle && (
          <List.Item
            divider="none"
            disabledEffects={['hover', 'active', 'focus', 'visited']}
          >
            <ListTitle
              text={listTitle.text}
              withHelpIcon={listTitle.withHelpIcon}
            />
          </List.Item>
        )}
        {items.map((item: TextListLabelProps, index: number) => (
          <List.Item
            key={item.leftLabel + index}
            disabledEffects={['hover', 'active', 'focus', 'visited']}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Body color="secondary" size="large">
                {item.leftLabel}
              </Body>
              {item.rightLabel && <Label>{item.rightLabel}</Label>}
            </div>
          </List.Item>
        ))}
      </List>
    </WhiteRoundedContainer>
  )
}

export { TextList }
