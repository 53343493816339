import { create } from 'zustand'

interface TestValuesStoreState {
  shouldBypassCaptcha: boolean
  setShouldBypassCaptcha: (value: boolean) => void
}

const useTestValuesStore = create<TestValuesStoreState>()((set) => ({
  shouldBypassCaptcha: false,
  setShouldBypassCaptcha: (value) => set({ shouldBypassCaptcha: value }),
}))

export { useTestValuesStore }
