import { Icon } from '@moneylion/mlds-web'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { ENV } from '@root/config'
import { BackdoorModal } from '../BackdoorModal'
import { BackdoorSettingsContainer } from './BackdoorSettings.styles'

const BackdoorSettings = (): JSX.Element | null => {
  const [openModal, setOpenModal] = useState(false)

  return createPortal(
    ENV.APP_ENV === 'production' ? null : (
      <BackdoorSettingsContainer>
        <Icon
          className="cursor"
          name="settings"
          onClick={() => setOpenModal((prev) => !prev)}
          size={30}
        />
        {openModal && <BackdoorModal />}
      </BackdoorSettingsContainer>
    ),
    document.body
  )
}

export { BackdoorSettings }
