const liteAccount = {
  firstName: [
    (firstName: string) => !!firstName || 'First name is required',
    (firstName: string) =>
      firstName.length > 2 || 'First name must be at least 3 characters',
    (firstName: string) =>
      /^[a-zA-Z- ]+$/.test(firstName) ||
      'First name can only contain alphabets, hyphen and whitespaces.',
  ],
  lastName: [
    (lastName: string) => !!lastName || 'Last name is required',
    (lastName: string) =>
      lastName.length > 2 || 'Last name must be at least 3 characters',
    (lastName: string) =>
      /^[a-zA-Z- ]+$/.test(lastName) ||
      'First name can only contain alphabets, hyphen and whitespaces.',
  ],
  email: [
    (email: string) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) ||
      'Please enter a valid email address.',
  ],
  password: [
    (password: string) =>
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password) ||
      'Password must contain at least 8 characters, a lower, upper case letter, and a digit.',
    (password: string) =>
      password.length < 129 || 'Password length can not exceed 129 characters.',
  ],
}

const login = {
  email: [
    (email: string) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) ||
      'Please enter a valid email address.',
  ],
  password: [
    (email: string) => email.trim().length > 0 || 'Password is required',
  ],
}

const validations = {
  liteAccount,
  login,
}

export { validations }
