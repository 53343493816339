import styled, { useTheme } from 'styled-components'
import Image from 'next/image'
import { Body, Card, Label } from '@moneylion/mlds-web'
import { useRouter } from 'next/router'
import { useOnboardingStore } from '@root/store'
import { getOnboardingFlow } from '@root/utils'
import { estimatedTimeToFinish } from './labels'
import { Stepper } from '@onboarding/components'
import headerLogo from 'public/assets/svgs/ml-logo-dark.svg'

const StyledOnboardingSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 472px;
  max-width: 35%;
  background-color: ${({ theme }) => theme.color?.BACKGROUND_CONTENT};
  position: sticky;
  top: 0;
  height: 100vh;
`

const OnboardingSidebar = (): JSX.Element => {
  const theme = useTheme()
  const { pathname } = useRouter()
  const flow = getOnboardingFlow(pathname)
  const isIntentFlow = useOnboardingStore((state) => state.isIntentFlow)

  return (
    <StyledOnboardingSidebar className="py-4 px-7 hidden-mobile">
      <Image
        src={headerLogo}
        alt="MoneyLion Logo"
        width={180}
        height={54}
        priority
      />
      <div className="pt-6">
        <Stepper type="desktop" />
      </div>

      {isIntentFlow && (
        <Card
          background={{
            color: theme.color?.BACKGROUND_PAGE,
          }}
          style={{
            padding: '8px 18px',
            width: 'fit-content',
          }}
        >
          <Body>
            <Label className="mr-2" size="large">
              {estimatedTimeToFinish[flow]} minutes
            </Label>
            estimated time to finish setting up.
          </Body>
        </Card>
      )}
    </StyledOnboardingSidebar>
  )
}

export { OnboardingSidebar }
