import dayjs from 'dayjs'
import { subscriptionApi } from '@root/api'
import { usePaymentScheduleStore, useEsignStore, useUIStore } from '@root/store'
import {
  TPayFrequency,
  TPayFrequencyType,
  IPayDate,
} from '@root/types/PaymentSchedule'
import { formats } from '@root/utils'

interface PaymentScheduleReturn {
  updateCbPlusSubscriptionAccount: (
    payFrequency: TPayFrequency,
    payFrequencyType: TPayFrequencyType,
    payDates: IPayDate
  ) => Promise<void>
}

const usePaymentSchedule = (): PaymentScheduleReturn => {
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)
  const setCbPlusMandatoryEsignDocuments = useEsignStore(
    (state) => state.setCbPlusMandatoryEsignDocuments
  )
  const setCbPlusOptionalEsignDocuments = useEsignStore(
    (state) => state.setCbPlusOptionalEsignDocuments
  )
  const setFee = usePaymentScheduleStore((state) => state.setFee)
  const setPayFrequencyType = usePaymentScheduleStore(
    (state) => state.setPayFrequencyType
  )

  const updateCbPlusSubscriptionAccount = async (
    payFrequency: TPayFrequency,
    payFrequencyType: TPayFrequencyType,
    payDates: IPayDate
  ): Promise<void> => {
    const { lastPayDate, nextPayDate } = payDates
    const request = {
      payDate1: lastPayDate && formats.date(dayjs(lastPayDate), 'MM/DD/YYYY'),
      payDate2:
        payFrequency === 'Semi-Monthly' && nextPayDate
          ? formats.date(dayjs(nextPayDate), 'MM/DD/YYYY')
          : undefined,
      payFrequency,
      payFrequencyType,
    }

    try {
      const { data } =
        await subscriptionApi.postCbPlusUpdateSubscriptionAccount(request)

      if (!data || !data.feeAmount || !data.feeDates) {
        return Promise.reject(new Error('FEE_INFO_NOT_FOUND'))
      }

      if (data.mandatorySubscriptionESignDocuments.length) {
        setCbPlusMandatoryEsignDocuments(
          data.mandatorySubscriptionESignDocuments
        )
      }

      if (data.optionalBackupAuthESignDocuments.length) {
        setCbPlusOptionalEsignDocuments(data.optionalBackupAuthESignDocuments)
      }

      setPayFrequencyType(payFrequencyType)
      setFee({
        amount: data.feeAmount,
        date: data.feeDates[0],
      })
    } catch (error) {
      setShowAppDialog(true)
      return Promise.reject(error)
    }
  }

  return { updateCbPlusSubscriptionAccount }
}

export { usePaymentSchedule }
