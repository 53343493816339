import { Icon, IconProps, IconsId } from '@moneylion/mlds-web'
import { useTheme } from 'styled-components'

interface CircleIconProps {
  iconId: IconsId
  iconSize?: IconProps['size']
  iconColor?: IconProps['color']
  backgroundColor?: 'default' | 'accent'
  width?: number
  height?: number
  className?: string
}

const CircleIcon = ({
  iconId,
  iconSize,
  iconColor,
  backgroundColor = 'default',
  width = 40,
  height = 40,
  className,
}: CircleIconProps): JSX.Element => {
  const theme = useTheme()

  const bgColor =
    backgroundColor === 'default'
      ? theme.color?.BUTTON_SECONDARY_BACKGROUND
      : theme.color?.BACKGROUND_CONTENT_ACCENT

  return (
    <div
      className={className}
      style={{
        backgroundColor: bgColor,
        borderRadius: '100%',
        width,
        minWidth: width,
        height,
        minHeight: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Icon name={iconId} size={iconSize} color={iconColor} />
    </div>
  )
}

export { CircleIcon }
