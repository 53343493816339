import { create } from 'zustand'
import { loansApi, subscriptionApi } from '@root/api'
import ILoan from '@root/types/Loan'
import { useBackdoorStore } from './backdoorStore'
import { useBankStore } from './bankStore'

interface DisbursementInfo {
  disbursementAmt: number
  escrowDisbursementAmt: number
  apr: number
  totalAmount: number
}
interface CBPInitialState {
  cbpLoan?: ILoan[] | null
  disbursementInfo: DisbursementInfo
}

export interface CBPStore extends CBPInitialState {
  getCBPLoan: () => Promise<ILoan[] | undefined>
  checkCBPlusBvUnderwriting: (signal?: AbortSignal) => Promise<boolean>
  reset: () => void
}

const initialState: CBPInitialState = {
  cbpLoan: undefined,
  disbursementInfo: {
    apr: 0,
    disbursementAmt: 0,
    escrowDisbursementAmt: 0,
    totalAmount: 0,
  },
}
const useCBPStore = create<CBPStore>()((set) => ({
  ...initialState,
  getCBPLoan: async () => {
    const loan = await loansApi.getLatestLoan()
    if (loan.data) {
      set({ cbpLoan: loan.data })
    } else {
      set({ cbpLoan: null })
    }

    return loan.data
  },
  checkCBPlusBvUnderwriting: async (signal) => {
    const { isCbplusBvLinkingBackdoorEnabled, cbplusBvLinkingTestValues } =
      useBackdoorStore.getState()

    const { setLinkedBank } = useBankStore.getState()

    const { data } = await subscriptionApi.postCBPlusBvUnderwriting(
      isCbplusBvLinkingBackdoorEnabled ? cbplusBvLinkingTestValues : undefined,
      signal
    )

    const {
      status,
      disbursementAmt,
      escrowDisbursementAmt,
      apr,
      bankName,
      last4DigitAccountNumber,
    } = data

    if (status === 'CBP_SUCCESS') {
      set({
        disbursementInfo: {
          disbursementAmt,
          escrowDisbursementAmt,
          apr,
          totalAmount: disbursementAmt + escrowDisbursementAmt,
        },
      })
      setLinkedBank({
        bankName,
        last4DigitAccountNumber,
      })
    }

    return status === 'CBP_SUCCESS'
  },
  reset: () => {
    set(initialState)
  },
}))

export { useCBPStore }
