export const MFA_LABEL = {
  title: 'Let’s make sure it’s you',
  subtitle: 'How should we send your verification code',
  getHelp: 'Get help',
  sendMeCodeCta: 'Send me a code',
  information: 'This step is used to ensure account security',
}

export const MFA_CODE_LABEL = {
  title: 'Enter the 4-digit code',
  incorrectCodeText: (numberOfAttempts: number) =>
    `Incorrect code. Attempts remaining ${numberOfAttempts} of 3`,
  resendCodeText: 'Resend code',
  nextButtonCta: 'Next',
}

export const LOGIN_LABEL = {
  title_01: 'Glad to have you backk.',
  errorMessage: 'Incorrect username or password. Please try again.',
  rememberMe: 'Remember me',
  forgotPassword: 'Forgot Password?',
  login: 'Log in',
  createAccount_01: 'Not a member? ',
  createAccount_02: 'Sign up now',
}

export const RM_IDT_FAILED_LABEL = {
  title: 'Sorry, unable to create your RoarMoney℠ account',
  body_01:
    'MoneyLion reviews every account application carefully to meet security standards through an identity verification process. This helps us prevent fraud and protects our customers.',
  body_02:
    "Unfortunately, we're unable to verify your identity and won't be able to open a RoarMoney account for you at this time.",
  button: 'Close',
}

export const RM_IDV_FAILED_LABEL = {
  title: 'Sorry, unable to create account',
  body: 'Our banking partner requires us to verify your identity to ensure secure account setup, but we were unable to verify the info you provided. If you think this is an error, please contact us.',
  button_01: 'Contact us',
  button_02: 'Close',
}

export const CBPLUS_MEMBERSHIP_FEE_LABEL = {
  title: 'Why is there a membership fee?',
  body_01:
    'Credit Builder Plus is a proven, all-in-one program to build credit — More than half of our Credit Builder Plus members improve their credit scores by an average of 47 points in only 60 days!',
  body_02: (apr: number) =>
    `For a low monthly fee, members get access to a ${apr}% APR credit builder loan, 0% APR Instacash  advances, premium checking and managed investing accounts, monthly Lion's Share cash rewards, and more.`,
  body_03:
    'It would cost far more (and take more time!) to try to access all these beneficial features individually. Most important, having better credit can save you thousands in interest over time — well worth the low monthly cost of this proven program.',

  button: 'Got it',
}

export const CBPLUS_QUALIFIED_CLOSE_LABEL = {
  title: 'Are you sure?',
  body: 'You could get cash today, start raising your credit, and unlock more rewards. Want to go back and take advantage of this valuable program?',
  button_01: 'Yes, I want to continue',
  button_02: 'No, take me home',
}

export const IC_RM_SUCCESS_LABEL = {
  imageSrc: '/assets/svgs/check-white.svg',
  title: 'Your RoarMoney℠ account is ready!',
  body: 'Only one more step to go - let’s check your Instacash eligibility.',
  button: 'Get Instacash!',
}

export const ADD_ADDRESS_GET_BILLING_ADDRESS_FAILED_LABEL = {
  title: 'It’ll be worth the wait',
  body: 'Our engineers are doing a quick update, so we can’t add your card at the moment. Please try again soon — thanks for your patience!',
  button1: 'Try again',
  button2: 'Cancel',
}

export const ADD_ADDRESS_ELIGIBILITY_FAILED_LABEL = {
  title: 'Please try another card',
  body: 'Looks like you’ve recently tried to add some addresses we couldn’t verify. For account security, you may not add another address at this time. Please try linking a card tied to an existing verified billing address.',
  button: 'Try another card',
}

export const ADD_CARD_FAILED_LABEL = {
  /**
   * @usage: For [POST] `/wallet/generateEsignDoc` endpoint
   * @description: This is a generic error for the [POST] `/wallet/generateEsignDoc` endpoint
   */
  GENERATE_ESIGN_GENERIC_ERROR: {
    title: 'Let’s try that again',
    body1: 'An unexpected time out session occurs. Please try again.',
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Card processing error
   */
  WAL1010: {
    title: 'Please contact your bank',
    body1: `Looks like your bank declined your card. We wish we could help, but you\'ll need to contact your bank to resolve. Good luck, and we\'ll be waiting for you!`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Failed to add card as card is already linked to another user
   */
  WAL1023: {
    title: 'Card ownership not confirmed',
    body1: `Looks like we can\'t verify you as the card owner. Please be sure you\'re adding a card that belongs to you.`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: InvalidCardType - Prepaid card is not accepted
   */
  WAL1027: {
    title: 'Please try another card',
    body1: `Sorry, prepaid cards can\'t be supported for payments. Please try adding another card.`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Debit Card Fund option is marked hard return
   */
  WAL1039: {
    title: 'Please try another card',
    body1: `Looks like a previous dispute or another issue is preventing us from taking payments. Please use another card.`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Card type is not accepted
   */
  WAL2033: {
    title: 'Please try another card',
    body1: `Unfortunately, this type of card can\'t be used in your Wallet. Please try using a different card.`,
    button1: 'Add new card',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Invalid ML Debit card
   */
  WAL2034: {
    title: 'Card ownership not confirmed',
    body1: `Looks like we can\'t verify you as the card owner. Please be sure you\'re adding a card that belongs to you.`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Card already exists
   */
  WAL2037: {
    title: `We\'re seeing double!`,
    body1: `Looks like this card is in your Wallet already, so it can\'t be added again. You\'re all set. Feel free to add different cards of course!`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Unrecognized fund type
   */
  WAL4001: {
    title: 'Did you move recently?',
    body1: `Looks like the address we have for you doesn\'t match the one your bank provided. That\'s okay! Just update your address or try another card.`,
    button1: 'Update address',
    button2: 'Add new card',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: One or more products are invalid
   */
  WAL4002: {
    title: `It\'ll be worth the wait`,
    body1: `Our engineers are doing a quick update, so we can\'t add your card at the moment. Please try again soon — thanks for your patience!`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: No valid processors found for Product
   */
  WAL4003: {
    title: 'Please try another card',
    body1: `This card issuer doesn\'t support address verification, a key step to protect your identity, so it cannot be added to your Wallet. Please try a different card.`,
    button1: 'Add new card',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Invalid User ID
   */
  WAL4004: {
    title: 'Please contact your bank',
    body1: `Looks like your bank declined your card. We wish we could help, but you\'ll need to contact your bank to resolve. Good luck, and we\'ll be waiting for you!`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Invalid Product ID
   */
  WAL4005: {
    title: `Let\'s double-check those digits!`,
    body1: `Looks like you may have entered your card details incorrectly. It happens to the best of us! Please try again now.`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Invalid Processor ID
   */
  WAL4006: {
    title: 'Please contact your bank',
    body1: `Looks like your bank declined your card. We wish we could help, but you\'ll need to contact your bank to resolve. Good luck, and we\'ll be waiting for you!`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @failReason: Card Failed Retokenize
   */
  WAL1031: {
    title: `It\'ll be worth the wait`,
    body1: `Our engineers are doing a quick update, so we can\'t add your card at the moment. Please try again soon — thanks for your patience!`,
    button1: 'Add new card',
    button2: 'Cancel',
  },

  /**
   * @usage: For [POST] `/wallet/card` endpoint
   * @description: This is a generic error for the [POST] `/wallet/card` endpoint
   */
  ADD_CARD_GENERIC_ERROR: {
    title: `It\'ll be worth the wait`,
    body1: `Our engineers are doing a quick update, so we can\'t add your card at the moment. Please try again soon — thanks for your patience!`,
    button1: 'Try again',
    button2: 'Cancel',
  },

  /**
   * @usage: For [GET] `/cash-advance/repayment/fundOptions` endpoint
   * @failReason: Fund option is marked hard return
   */
  WAL1029: {
    title: 'Please try another card',
    body1: `Looks like a previous dispute or another issue is preventing us from taking payments. Please use another card.`,
    button1: 'Close',
  },

  /**
   * @usage: For [GET] `/cash-advance/repayment/fundOptions` endpoint
   * @failReason: Debit card has hard return
   */
  FUND004: {
    title: 'Please try another card',
    body1: `Looks like a previous dispute or another issue is preventing us from taking payments. Please use another card.`,
    button1: 'Close',
  },

  /**
   * @usage: For [GET] `/cash-advance/repayment/fundOptions` endpoint
   * @description: No fund id found from the [GET] `/cash-advance/repayment/fundOptions` endpoint
   */
  MISSING_FUND_ID_ERROR: {
    title: 'Please try another card',
    body1: `Unfortunately, this type of card can’t be used for Instacash. Please add a debit card.`,
    button1: 'Add debit card',
    button2: 'Cancel',
  },

  /**
   * @usage:
   *  1. For [GET] `/cash-advance/repayment/fundOptions` endpoint
   *  2. For [POST] `/wallet/v2/product` endpoint
   * @description: This is a generic error for the [GET] `/cash-advance/repayment/fundOptions` endpoint and [POST] `/wallet/v2/product` endpoint
   */
  LINK_DEBIT_CARD_GENERIC_ERROR: {
    title: 'Update not completed',
    body1: `Looks like your update request didn\‘t go through. Please be sure you have good internet connection and try again.`,
    button1: 'Close',
  },
}

export const INSTACASH_HARD_REJECT_LABEL = {
  title: 'Thanks for checking us out!',
  body: `Unfortunately based on the information you've provided, you're ineligible for Instacash at this time. Please note, our Customer Care team is unable to assist with eligibility requirements. However, if you'd like to learn more, you can check out our Instacash eligibility FAQs.`,
  button1: 'Read FAQs',
  button2: 'Got it',
}

export const INSTACASH_SOFT_REJECT_LABEL = {
  imageSrc: '/assets/svgs/instacash-soft-reject-banner.svg',
  title: 'One more step to go!',
  body: (name: string) =>
    `Hey ${name}, to proceed with your request, we require you to add a few more additional documents. Please download the app to complete your disbursement.`,
  button: 'Download now!',
}

export const ADDRESS_ERROR_DIALOG_LABEL = {
  BYPASS_ALLOWED: {
    title: 'The address is classified as not receiving mail by the USPS',
    body: [
      'This address seems right, but unfortunately the USPS cannot deliver mail to it. This might affect your ability to use some of our banking features later on, but you can still use our other products!',
      'Would you like to continue?',
    ],
    button_01: 'Yes',
    button_02: 'No, edit address',
  },

  CORRECTED: {
    title: 'Just one last thing..',
    body: [
      'We want to make sure we got it right!',
      'Therefore we have modified your address slightly to make sure it matches the right format. We check this based on the verified USPS database.',
      'Please double check if your address looks correct.',
    ],
    button_01: 'Let’s check',
  },

  INVALID: {
    title: 'Let’s double check',
    body: [
      'We want to make sure we got it right!',
      "Looks like the address you provided doesn't completely match records, please review your address for any errors and try again.",
      'Error:',
    ],
    button_01: 'Try again',
  },

  INVALID_V2: {
    title: 'Let’s double check',
    body: [
      'We want to make sure we got it right!',
      "Looks like the address you provided doesn't completely match records, please review your address for any errors and try again.",
      'Error(s):',
    ],
    button_01: 'Try again',
  },

  INVALID_PO_BOX: {
    title: 'Oops! Almost there',
    body: [
      'It looks like the address you entered might be a P.O. box. For your security, please use your home address, not a P.O. box.',
    ],
    button_01: 'Try again',
  },

  INVALID_PO_BOX_V2: {
    title: 'Oops! Almost there',
    body: [
      "Unfortunately, we don't support P.O. Boxes. Please update with your physical home address.",
    ],
    button_01: 'Try again',
  },
}

export const INVALID_PHONE_NUMBER_LABEL = {
  title: 'Oops!',
  body: [
    "You've entered an invalid mobile phone number. Please enter a mobile phone number that's currently in use.",
  ],
  button: `Let's try again`,
}

export const PHONE_NUMBER_CLAIMED_LABEL = {
  title: "There's a problem...",
  body: (phoneNumber: string) => [
    `It looks like ${phoneNumber} is linked to another MoneyLion account.`,
    'If you want to claim this number, kindly download the MoneyLion app to continue onboarding.',
  ],
  cancel: 'Cancel',
  downloadApp: 'Download App',
}

export const SSN_ASSOCIATED_LABEL = {
  title: 'This SSN is already associated with a profile.',
  description: [
    'First, please make sure your SSN is correct. If it is, please call our customer support at 888-704-6970',
  ],
  button: 'Ok',
}

export const RM_INELIGIBLE_FAILED_LABEL = {
  title: `Let's find a better fit`,
  description: [
    `Looks like you're ineligible for RoarMoney. You may contact us if you have questions. We also invite you to check out 0% APR Instacash and managed investing!`,
  ],
  button_01: 'Contact us',
  button_02: 'Close',
}

export const IDV_BLACKLISTED_LABEL = {
  title: 'Sorry, unable to create\n account',
  description: [
    `It looks like you're ineligible for a MoneyLion account. If you'd like us to investigate further, please submit a help request.`,
  ],
  button_01: 'Request help',
  button_02: 'Close',
}

export const INVALID_REFERRAL_CODE_FAILED_LABEL = {
  title: 'Referral code does not exist',
  description: [
    'This referral code is not in use, please insert correct code.',
  ],
  button: 'Got it',
}

export const INVALID_ROARTAG_FAILED_LABEL = {
  title: '$RoarTag is invalid',
  description: [
    'Looks like your request couldn’t be completed. Please try again with a different $RoarTag or referral code.',
  ],
  button: 'Got it',
}

export const REFERRAL_RELATION_EXISTED_FAILED_LABEL = {
  title: 'Referral code already submitted',
  description: [
    `Looks like you've already submitted this referral code. Click "skip" to proceed.`,
  ],
  button: 'Got it',
}

export const REFERRAL_RELATION_NOT_ALLOWED_FAILED_LABEL = {
  title: 'Referral code already submitted',
  description: [
    `Looks like you've already submitted a different referral code, click "skip" to proceed.`,
  ],
  button: 'Got it',
}

export const WEB_TO_APP_LABEL = {
  organic: {
    image: 'app-dialog-banner-v3',
    title: (firstName: string) => `Hey ${firstName},`,
    description: [
      'Unlock the complete MoneyLion experience and gain access to free financial tips, loan offers, and more!',
    ],
  },
  instacash: {
    image: 'app-dialog-banner-v3-instacash',
    title: (firstName: string) => `Hey ${firstName},`,
    description: [
      'Get up to $500 in cash advances, loan offers, free financial tips, and more when you unlock the full MoneyLion experience.',
    ],
  },
  roarmoney: {
    image: 'app-dialog-banner-v3-roarmoney',
    title: (firstName: string) => `Welcome ${firstName},`,
    description: [
      'Experience all the perks of RoarMoney℠ with our all-in-one financial app. Get paid up to 2 days early, access free financial tips, and more!',
    ],
  },
  investment: {
    image: 'app-dialog-banner-v3-investment',
    title: (firstName: string) => `Welcome ${firstName},`,
    description: [
      'Take control of your investments with a money app that does it all. Set your preferred investment plan on auto and enjoy free financial tips, plus so much more.',
    ],
  },
  cbplus: {
    image: 'app-dialog-banner-v3-cbplus',
    title: (firstName: string) => `Welcome ${firstName},`,
    description: [
      'Take control of your credit score with the MoneyLion app! Build and monitor your credit score, access free financial tips, and enjoy many more features.',
    ],
  },
  button_01: 'Download the free app',
  button_02: 'Continue on web',
}
