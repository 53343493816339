import { useContext, useEffect, useState } from 'react'
import { SplitContext, useClient } from '@splitsoftware/splitio-react'
import { Treatments } from '@splitsoftware/splitio/types/splitio'
import { useAuthStore } from '@root/store'

interface UseSplitReturn {
  treatments?: Treatments
  isReady: boolean
}

/**
 * useSplit - A hook to get a feature flag from Split.io
 *
 * Usage example:
 *
 * ```jsx
 *  const { isReady, treatments } = useSplit(['YOUR_FEATURE_NAME'])
 *
 *  // Check if Split.io is ready (boolean):
 *  console.log(isReady)
 *
 *  // Retrieve your feature flag treatment:
 *  console.log(treatments['YOUR_FEATURE_NAME'])
 * ```
 *
 **/
const useSplit = (featureNames: string[]): UseSplitReturn => {
  const user = useAuthStore((state) => state.user)

  const accountClient = useClient(user?.email)

  const { isReady } = useContext(SplitContext)

  const [treatments, setTreatments] = useState<Treatments>()

  useEffect(() => {
    if (accountClient === null) return
    accountClient.on(accountClient.Event.SDK_READY, () => {
      setTreatments(accountClient.getTreatments(featureNames))
    })
  }, [accountClient, featureNames])

  return {
    isReady,
    treatments: treatments,
  }
}

export { useSplit }
