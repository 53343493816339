const labels = {
  profile: 'Profile',
  logOut: 'Log out',
  communicationPreferences: 'Communication Preferences',
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms & Conditions',
  advertiserDisclosure: 'Advertiser Disclosure',
  support: 'Support',
}

export { labels }
