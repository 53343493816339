import { https } from '@root/utils'
import { ENV } from '@root/config'
import { BankingActiveDocument } from '@root/types'

interface GetBankingEsignRes {
  data: {
    activeAgreements: BankingActiveDocument[]
  }
}

interface SignBankingEsignBody {
  documentIds: string[]
}

interface SignBankingEsignRes {
  data: {
    message: string
  }
  status: number
  message?: string
}

interface CreateCheckingAccountBody {
  depositCode: number
  employmentCode: number
  addressId: string
  leadGenTransactionId: string | null
  paymentForm: string
}

interface CreateCheckingAccountRes {
  data?: {
    message: string
  }
  status: number
  message?: string
}

const baseUrl = `${ENV.API_URL}/banking`

const getBankingEsign = async (
  signal?: AbortSignal
): Promise<GetBankingEsignRes> => {
  const response = await (await https({ signal })).get(`${baseUrl}/esign`)
  return response.data
}

const signBankingEsign = async (
  body: SignBankingEsignBody
): Promise<SignBankingEsignRes> => {
  const response = await (await https()).post(`${baseUrl}/esign/sign`, body)
  return response.data
}

const createCheckingAccount = async (
  body: CreateCheckingAccountBody
): Promise<CreateCheckingAccountRes> => {
  const response = await (
    await https()
  ).post(`${baseUrl}/checkingAccount/create`, body)
  return response.data
}

const bankingApi = {
  getBankingEsign,
  signBankingEsign,
  createCheckingAccount,
}

export default bankingApi
