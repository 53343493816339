import '@moneylion/mlds-web/styles/fonts/fonts.css'
import '@moneylion/mlds-web/styles/fonts/icons.css'
import { ThemeProvider } from 'styled-components'
import { Dialog, GlobalStyle } from '@moneylion/mlds-web'
import '@root/styles/index.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import smartlookClient from 'smartlook-client'
import Script from 'next/script'
import { NextWebVitalsMetric } from 'next/app'
import { ENV } from '@root/config'
import theme from '@root/theme'
import AppDialog from '@root/modules/AppDialog'
import { NotificationModal } from '@root/components/NotificationModal'
import { AppPropsWithLayout } from '@root/types'
import { useRemoteConfig } from '@root/hooks'
import { REMOTE_CONFIG_FEATURE_FLAG } from '@root/constants/featureFlagNames'
import { reportNextWebVitals } from '@root/utils'

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  reportNextWebVitals(metric)
}

export default function App({
  Component,
  pageProps,
}: AppPropsWithLayout): JSX.Element {
  // Register MLDS <Dialog /> showDialogsFn to show dialogs
  const registerShowDialogsFn = (showDialogsFn: any) => {
    window.showDialogs = showDialogsFn
  }

  const { treatment, status } = useRemoteConfig(
    REMOTE_CONFIG_FEATURE_FLAG.WEB.smartlook
  )
  const smartlookTreatment = treatment?.asBoolean()

  useEffect(() => {
    // load Smartlook
    async function smartlookInit() {
      if (
        status === 'success' &&
        smartlookTreatment &&
        !smartlookClient.initialized()
      ) {
        smartlookClient.init(ENV.SMARTLOOK, { region: 'us', cookies: true })
      }
    }

    smartlookInit()

    datadogRum.init({
      applicationId: ENV.DATADOG.appId,
      clientToken: ENV.DATADOG.clientToken,
      site: 'datadoghq.com',
      service: ENV.DATADOG.service,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: ENV.APP_VERSION,
      sampleRate: 100,
      sessionReplaySampleRate: 20,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
      silentMultipleInit: true,
    })

    if (ENV.APP_ENV !== 'production') {
      datadogRum.startSessionReplayRecording()
    }
  }, [status])

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <ThemeProvider theme={theme}>
      <Script id="segment-key">
        {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.3";analytics.load("${ENV.SEGMENT_KEY}");}}();`}
      </Script>
      <Script id="appflyer">
        {`!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF", "pba,banners",{pba: {webAppId: "${ENV.APPS_FLYER_PBA_KEY}"}, banners: {key: "${ENV.APPS_FLYER_WEB_KEY}"}});`}
      </Script>
      <GlobalStyle />
      <AppDialog />
      <NotificationModal />
      {getLayout(<Component {...pageProps} />)}
      <Dialog.Manager registerShowDialogsFn={registerShowDialogsFn} />
    </ThemeProvider>
  )
}
