export const SPLIT_FEATURE_FLAG = {
  ONBOARDING: {
    shouldIpAddressIncluded: 'ob_web_ic_apply_ip_enabled',
  },
}

export const REMOTE_CONFIG_FEATURE_FLAG = {
  WEB: {
    allowUserIdOnSegment: 'WEB_ALLOW_USERID_ON_SEGMENT',
    cpcEnabled: 'WEB_CPC_ENABLED',
    smartlook: 'WEB_SMARTLOOK',
  },
  IAM: {
    loginRollout: 'IAM_LOGIN_ROLLOUT',
  },
  MPL: {
    dashLoanOffersWall: 'MPL_WEB_DASH_LOAN_OFFERS_WALL',
  },
}
