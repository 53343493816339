import React from 'react'
import { Button, Icon, IconsId, Body } from '@moneylion/mlds-web'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useAuthStore } from '@root/store'
import { ProfileImage } from '@root/experiences/Profile'
import { labels } from './labels'

const NAVS = [
  {
    name: labels.productAndOffers,
    icon: 'money',
    path: '/products-offers',
  },
  {
    name: labels.accounts,
    icon: 'bank',
    path: '/accounts',
  },
  {
    name: labels.referrals,
    icon: 'addUser',
    path: '/referrals',
    state: { referrer: 'primary_global_menu' },
  },
  {
    name: labels.profile,
    icon: '',
    path: '/profile',
  },
]

const StyledNavBar = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  height: 60px;
  width: 100%;
  background-color: white;
  box-shadow: 0px -4px 9px 0px rgba(0, 0, 0, 0.1);

  a {
    -webkit-tap-highlight-color: transparent;
  }
`

const CustomButton = styled(Button)<{ isActive: boolean }>`
  border-radius: 0;
  background-color: transparent;
  height: 100%;
  color: ${({ isActive, theme }) =>
    isActive ? theme.color?.CONTENT_PRIMARY : theme.color?.CONTENT_TERTIARY};

  &:before {
    background-color: transparent !important;
  }

  span {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`

const MobileNavBar: React.FunctionComponent = () => {
  const router = useRouter()
  const user = useAuthStore((state) => state.user)

  const onClickTab = async (nav: typeof NAVS[0]) => {
    if (router.pathname === nav.path) {
      window.scrollTo(0, 0)
    }

    router.push(nav.path)
  }

  return (
    <StyledNavBar className="hidden-desktop flex">
      {NAVS.map((nav) => (
        <div
          key={nav.path}
          className="flex-1 flex jc-center ai-center"
          role="none"
          onClick={() => onClickTab(nav)}
        >
          <CustomButton
            type="button"
            isActive={router.pathname === nav.path}
            fullWidth
          >
            {nav.name === 'Profile' ? (
              <ProfileImage
                image={user?.profilePicturePreviewUrl}
                size="XSmall"
                hasBackground={!!user?.profilePicturePreviewUrl}
              />
            ) : (
              <Icon color="inherit" name={nav.icon as IconsId} />
            )}
            <Body color="inherit" size="medium">
              {nav.name}
            </Body>
          </CustomButton>
        </div>
      ))}
    </StyledNavBar>
  )
}

export { MobileNavBar }
