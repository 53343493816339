import { BackdoorCBPlus } from '../BackdoorCBPlus'
import { BackdoorGeneric } from '../BackdoorGeneric'
import { BackdoorInstacash } from '../BackdoorInstacash'
import { BackdoorRoarMoney } from '../BackdoorRoarMoney'
import { BackdoorModalContainer } from './BackdoorModal.styles'

const BackdoorModal = (): JSX.Element => {
  return (
    <BackdoorModalContainer>
      <div className="border-container">
        <BackdoorGeneric />
      </div>

      <div className="border-container">
        <BackdoorCBPlus />
      </div>

      <div className="border-container">
        <BackdoorInstacash />
      </div>

      <div className="border-container">
        <BackdoorRoarMoney />
      </div>
    </BackdoorModalContainer>
  )
}

export { BackdoorModal }
