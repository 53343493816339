import React from 'react'
import { Icon, IconsId, Body } from '@moneylion/mlds-web'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'
import { ProfileMenu } from '@root/modules/ProfileMenu'
import {
  StyledSidebar,
  StyledSideBarContent,
  CustomButton,
} from './SideBar.styles'
import { labels } from './labels'

const SIDEBAR_NAVS = [
  {
    name: labels.productAndOffers,
    icon: 'money',
    path: '/products-offers',
  },
  {
    name: labels.accounts,
    icon: 'bank',
    path: '/accounts',
  },
  {
    name: labels.referrals,
    icon: 'addUser',
    path: '/referrals',
    state: { referrer: 'primary_global_menu' },
  },
]

const SideBar: React.FunctionComponent = () => {
  const router = useRouter()

  const onClickTab = async (nav: typeof SIDEBAR_NAVS[0]) => {
    if (router.pathname === nav.path) {
      window.scrollTo(0, 0)
    }

    router.push(nav.path)
  }

  return (
    <StyledSidebar id="sidebar" className="hidden-mobile">
      <StyledSideBarContent
        id="sidebar-content"
        className="px-4 pt-6 pb-5 flex flex-col"
      >
        <div className="mb-6">
          <Link
            href="/"
            id="sidebar-logo"
            style={{ display: 'inline-flex' }}
            aria-label="MoneyLion logo - go to homepage"
          >
            <Image
              src="/assets/svgs/ml-logo.svg"
              alt="MoneyLion Logo"
              width={214}
              height={64}
              priority
            />
          </Link>
        </div>

        <div className="flex flex-col flex-1">
          <div className="flex-1">
            {SIDEBAR_NAVS.map((each) => (
              <div key={each.path} className="pb-4">
                <CustomButton
                  color="secondary"
                  className="px-3 py-1"
                  isActive={router.pathname === each.path}
                  type="button"
                  fullWidth
                  onClick={() => onClickTab(each)}
                >
                  <Icon size={24} name={each.icon as IconsId} />
                  <Body size="xl" className="pl-3">
                    {each.name}
                  </Body>
                </CustomButton>
              </div>
            ))}
          </div>
          <ProfileMenu />
        </div>
      </StyledSideBarContent>
    </StyledSidebar>
  )
}

export { SideBar }
