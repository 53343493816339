/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from 'react'
import Image from 'next/image'
import { useTheme } from 'styled-components'
import { Button, Heading, Input, List, Body } from '@moneylion/mlds-web'
import Skeleton from 'react-loading-skeleton'
import { useDebounce } from 'usehooks-ts'
import IBank, { IBankSummary } from '@root/types/Bank'
import { bankApi } from '@root/api'
import { BankSource } from '@root/api/bankApi'
import { CircleIcon } from '../CircleIcon'
import { labels } from './labels'
import { BankListContainer, TopBankContainer } from './BVLinking.styles'

type BVLinkingProps<BVLinkingType = 'modal' | 'page'> =
  BVLinkingType extends 'modal'
    ? {
        type: 'modal'
        source: BankSource
        onClickBank: (bank: IBank) => void
        onClickButton?: undefined
        selectedBank?: undefined
        isLoading?: undefined
      }
    : {
        type: 'page'
        source: BankSource
        onClickBank: (bank: IBank) => void
        onClickButton: (bank?: IBank | IBankSummary) => Promise<void>
        selectedBank: IBank | IBankSummary | undefined
        isLoading: boolean
      }

const BVLinking = ({
  type,
  source,
  selectedBank,
  onClickBank,
  onClickButton,
  isLoading,
}: BVLinkingProps): JSX.Element => {
  const theme = useTheme()
  const [banks, setBanks] = useState<IBank[]>([])
  const [topBanks, setTopBanks] = useState<IBank[]>([])
  const [isLoadingBanks, setIsLoadingBanks] = useState<boolean>(true)
  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  useEffect(() => {
    if (topBanks.length === 0) {
      getTopBanks()
    } else {
      setSearchText('')
    }
  }, [topBanks.length])

  useEffect(() => {
    if (debouncedSearchText) {
      getBanks()
    }
  }, [debouncedSearchText])

  const getTopBanks = async (): Promise<void> => {
    try {
      const resp = await bankApi.fetchTopBanks(source)
      setTopBanks([...(resp.data?.institutions || [])])
    } catch {
      setTopBanks([])
    } finally {
      setIsLoadingBanks(false)
    }
  }

  const getBanks = async (): Promise<void> => {
    try {
      setIsLoadingBanks(true)
      const resp = await bankApi.findBanks(debouncedSearchText, source)
      setBanks([...(resp.data?.institutions || [])])
    } catch {
      setBanks([])
    } finally {
      setIsLoadingBanks(false)
    }
  }

  const renderBanks = (): JSX.Element => {
    if (!debouncedSearchText) {
      return renderTopBanks()
    }

    return banks.length > 0 ? (
      renderBankList()
    ) : (
      <Body size="medium" color="secondary" className="text-center">
        Can&rsquo;t find your bank. Please search again.
      </Body>
    )
  }

  const renderBankPlaceholder = (): JSX.Element => (
    <CircleIcon iconId="bank" iconColor={theme.color?.CONTENT_TERTIARY} />
  )

  const renderTopBanks = (): JSX.Element => (
    <TopBankContainer>
      {topBanks.map((each, index) => (
        <div
          data-testid={`listItem_topBankCard_${index + 1}`}
          className="top-bank-card"
          key={each.id}
          onClick={() => onClickBank(each)}
          role="none"
          style={{
            pointerEvents: isLoading ? 'none' : 'auto',
            backgroundColor:
              selectedBank?.id === each.id
                ? theme.color?.BACKGROUND_CONTENT_ACCENT
                : undefined,
          }}
        >
          {each.logoUrl ? (
            <Image
              loading="lazy"
              src={each.logoUrl}
              alt={each.name}
              width={210}
              height={73}
            />
          ) : (
            renderBankPlaceholder()
          )}
        </div>
      ))}
    </TopBankContainer>
  )

  const renderBankList = (): JSX.Element => (
    <BankListContainer>
      <List>
        {banks.map((each, index) => (
          <List.Item
            data-testid={`listItem_searchBankCard_${index + 1}`}
            key={each.id}
            bullet={{
              icon: !each.logoUrl ? 'bank' : undefined,
              iconColor: theme.color?.CONTENT_TERTIARY,
              color: theme.color?.BUTTON_SECONDARY_BACKGROUND,
              url: each.logoUrl || '',
            }}
            active={selectedBank?.id === each.id}
            onClick={() => onClickBank(each)}
          >
            <List.Label main={each.name} sub={each.displayUrl} />
          </List.Item>
        ))}
      </List>
    </BankListContainer>
  )

  return (
    <>
      <Heading
        className={`mt-3 mb-1 ${type === 'page' && 'text-center'}`}
        size="small"
      >
        {labels.title}
      </Heading>

      {type === 'modal' && (
        <Body color="secondary" size="medium">
          {labels.description}
        </Body>
      )}

      <Input
        data-testid="input_searchBank"
        className="mt-3 mb-6"
        label="Search"
        leadingIcon="search"
        disabled={isLoading}
        loading={isLoading}
        onChange={(e) => setSearchText(e.target.value)}
      />

      {isLoadingBanks ? (
        <Skeleton height={300} borderRadius={8} />
      ) : (
        renderBanks()
      )}

      {type === 'page' && (
        <div className="mt-8">
          <Button
            data-testid="button_linkMyBankAccount"
            fullWidth
            color="primary"
            className="mb-3"
            onClick={() => onClickButton && onClickButton(selectedBank)}
            disabled={!selectedBank || isLoading}
            loading={isLoading}
          >
            {labels.button}
          </Button>

          {!debouncedSearchText && (
            <Body size="medium" color="tertiary" className="text-center">
              {labels.guide}
            </Body>
          )}
        </div>
      )}
    </>
  )
}

const BVLinkingMemo = memo(BVLinking)

export { BVLinkingMemo as BVLinking }
