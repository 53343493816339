import { Body } from '@moneylion/mlds-web'
import React from 'react'
import styled, { css } from 'styled-components'

interface DisclaimerProps {
  labels: string[]
}

const StyledDisclaimerContainer = styled.div`
  margin: 0 16px;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
      margin: 0;
    }
  `}
`

const Disclaimer = ({ labels }: DisclaimerProps): JSX.Element => {
  return (
    <StyledDisclaimerContainer>
      <>
        {labels.map((label, index) => (
          <Body
            key={`disclaimer-${index + 1}`}
            className={index < labels.length ? 'pb-3' : ''}
            size="small"
            color="secondary"
          >
            {label}
          </Body>
        ))}
      </>
    </StyledDisclaimerContainer>
  )
}

export { Disclaimer }
