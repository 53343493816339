import IMask from 'imask'

export const maskPhoneNumber = (phoneNumber: string): string => {
  const masked = IMask.createMask({
    mask: '***-***-****',
    placeholderChar: '*',
    lazy: false,
  })

  return masked.resolve(phoneNumber)
}

export const maskSSN = (ssn: string): string => {
  const masked = IMask.createMask({
    mask: '***-**-****',
    placeholderChar: '*',
    lazy: false,
  })

  return masked.resolve(ssn)
}

export const maskEmail = (email: string): string => {
  const [name, domain] = email.split('@')
  return `${name[0]}****@${domain}`
}

const masks = {
  maskPhoneNumber,
  maskSSN,
  maskEmail,
}

export { masks }
