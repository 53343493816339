import {
  ONBOARDING_CBPLUS_PAGES_PATHS,
  ONBOARDING_CRYPTO_PAGES_PATHS,
  ONBOARDING_INSTACASH_PAGES_PATHS,
  ONBOARDING_INVESTMENT_PAGES_PATHS,
  ONBOARDING_ORGANIC_PAGES_PATHS,
  ONBOARDING_ROARMONEY_PAGES_PATHS,
  PROTECTED_PAGES_PATHS,
} from '@root/constants'
import { TOnboardingFlow, getOnboardingFlow } from './getOnboardingFlow'

type PartialProductPaths = Partial<typeof PROTECTED_PAGES_PATHS>
type InstacashPagesPaths = keyof typeof ONBOARDING_INSTACASH_PAGES_PATHS
type CryptoPagesPaths = keyof typeof ONBOARDING_CRYPTO_PAGES_PATHS
type InvestmentPagesPaths = keyof typeof ONBOARDING_INVESTMENT_PAGES_PATHS
type RoarMoneyPagesPaths = keyof typeof ONBOARDING_ROARMONEY_PAGES_PATHS
type CBPlusPagesPaths = keyof typeof ONBOARDING_CBPLUS_PAGES_PATHS
type OrganicPagesPaths = keyof typeof ONBOARDING_ORGANIC_PAGES_PATHS

/**
 * @description To get the current step of the page for the stepper component
 * @param {pathname}: The pathname in the url
 * @param {isIntentFlow}: To indicate the flow is an organic or intent flow. The usage is for the Instacash flow, because the stepper points are different on organic and intent flow.
 * @returns {number}: Returns the current active step number
 */
const mapStepperProgress = (
  pathname: string,
  isIntentFlow?: boolean
): number => {
  const currentFlow = getOnboardingFlow(pathname)
  const currentProductPagePaths = getProductPagePaths(currentFlow)
  const currentPage = Object.entries(currentProductPagePaths).find(
    ([, value]) => value === pathname
  )
  if (!currentPage) return 0
  const currentPageName = currentPage[0]

  switch (currentFlow) {
    case 'cbplus':
      return getCbplusProgressStep(currentPageName as CBPlusPagesPaths)
    case 'crypto':
      return getCryptoProgressStep(currentPageName as CryptoPagesPaths)
    case 'instacash':
      if (isIntentFlow) {
        return getInstacashIntentProgressStep(
          currentPageName as InstacashPagesPaths
        )
      }

      return getInstacashOrganicProgressStep(
        currentPageName as InstacashPagesPaths
      )
    case 'investment':
      return getInvestmentProgressStep(currentPageName as InvestmentPagesPaths)
    case 'roarmoney':
      return getRoarMoneyProgressStep(currentPageName as RoarMoneyPagesPaths)
    default:
      return getOrganicProgressStep(currentPageName as OrganicPagesPaths)
  }
}

const getProductPagePaths = (
  currentFlow: TOnboardingFlow
): PartialProductPaths => {
  switch (currentFlow) {
    case 'cbplus':
      return ONBOARDING_CBPLUS_PAGES_PATHS
    case 'crypto':
      return ONBOARDING_CRYPTO_PAGES_PATHS
    case 'instacash':
      return ONBOARDING_INSTACASH_PAGES_PATHS
    case 'investment':
      return ONBOARDING_INVESTMENT_PAGES_PATHS
    case 'roarmoney':
      return ONBOARDING_ROARMONEY_PAGES_PATHS
    default:
      return ONBOARDING_ORGANIC_PAGES_PATHS
  }
}

const getOrganicProgressStep = (currentPageName: OrganicPagesPaths): number => {
  switch (currentPageName) {
    case 'dobAddress':
    case 'phoneNumber':
    case 'ssnVerification':
      return 1
    default:
      return 0
  }
}

const getCbplusProgressStep = (currentPageName: CBPlusPagesPaths): number => {
  switch (currentPageName) {
    case 'cbplusDobAddress':
    case 'cbplusPhoneNumber':
    case 'cbplusSsnVerification':
      return 1
    case 'cbplusIntro':
    case 'cbplusBVPreroll':
    case 'cbplusBVLinking':
    case 'cbplusQualified':
    case 'cbplusNonQualified':
    case 'cbplusCreditReserve':
    case 'cbplusPayFrequency':
    case 'cbplusWeeklyAndBiweekly':
    case 'cbplusWeeklyAndBiweeklyLastPaid':
    case 'cbplusTwiceAMonth':
    case 'cbplusTwiceAMonthFirstPaycheck':
    case 'cbplusTwiceAMonthFirstAndSecondPaycheck':
    case 'cbplusMonthly':
    case 'cbplusMonthlyDayOfWeek':
    case 'cbplusMonthlyDateOfMonth':
    case 'cbplusAccountActivation':
      return 2
    case 'cbplusSuccess':
      return 3
    default:
      return 0
  }
}

const getCryptoProgressStep = (currentPageName: CryptoPagesPaths): number => {
  switch (currentPageName) {
    case 'cryptoDobAddress':
    case 'cryptoPhoneNumber':
    case 'cryptoSsnVerification':
      return 1
    case 'cryptoIntro':
    case 'cryptoRoarmoneyAccountActivation':
      return 2
    case 'cryptoPreSuccess':
      return 3
    default:
      return 0
  }
}

const getInstacashIntentProgressStep = (
  currentPageName: InstacashPagesPaths
): number => {
  switch (currentPageName) {
    case 'instacashBVPreroll':
    case 'instacashBVLinking':
      return 1
    case 'instacashQualified':
    case 'instacashTrialQualified':
    case 'instacashDobAddress':
    case 'instacashPhoneNumber':
    case 'instacashSsnVerification':
    case 'instacashRoarmoneyAccountActivation':
      return 2
    case 'instacashAddCardStart':
    case 'instacashAddCard':
    case 'instacashAddCardPickBillingAddress':
    case 'instacashAddCardAddBillingAddress':
    case 'instacashAddCardBackupAuth':
      return 3
    case 'instacashDisbursementActivation':
    case 'instacashDisbursementDestination':
      return 4
    case 'instacashDisbursementSuccess':
      return 5
    default:
      return 0
  }
}

const getInstacashOrganicProgressStep = (
  currentPageName: InstacashPagesPaths
): number => {
  switch (currentPageName) {
    case 'instacashBVPreroll':
    case 'instacashBVLinking':
    case 'instacashQualified':
    case 'instacashTrialQualified':
    case 'instacashDobAddress':
    case 'instacashPhoneNumber':
    case 'instacashSsnVerification':
      return 1
    case 'instacashRoarmoneyAccountActivation':
    case 'instacashAddCardStart':
    case 'instacashAddCard':
    case 'instacashAddCardPickBillingAddress':
    case 'instacashAddCardAddBillingAddress':
    case 'instacashAddCardBackupAuth':
    case 'instacashDisbursementActivation':
    case 'instacashDisbursementDestination':
      return 2
    case 'instacashDisbursementSuccess':
      return 3
    default:
      return 0
  }
}

const getInvestmentProgressStep = (
  currentPageName: InvestmentPagesPaths
): number => {
  switch (currentPageName) {
    case 'investmentDobAddress':
    case 'investmentPhoneNumber':
    case 'investmentSsnVerification':
      return 1
    case 'investmentIntro':
    case 'investmentQuestion1':
    case 'investmentQuestion2':
    case 'investmentQuestion3':
    case 'investmentQuestion4':
    case 'investmentAccountActivation':
      return 2
    case 'investmentPortfolio':
      return 3
    default:
      return 0
  }
}

const getRoarMoneyProgressStep = (
  currentPageName: RoarMoneyPagesPaths
): number => {
  switch (currentPageName) {
    case 'roarmoneyDobAddress':
    case 'roarmoneyPhoneNumber':
    case 'roarmoneySsnVerification':
      return 1
    case 'roarmoneyIntro':
    case 'roarmoneyAccountActivation':
      return 2
    case 'roarmoneyActivateCard':
      return 3
    default:
      return 0
  }
}

export { mapStepperProgress }
