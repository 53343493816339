import { useTheme } from 'styled-components'
import { Heading, Icon } from '@moneylion/mlds-web'
import { ReactNode, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { ENV } from '@root/config'
import { Header } from '@root/modules/Header'
import { MobileNavBar } from '@root/modules/MobileNavBar'
import { SideBar } from '@root/modules/SideBar'
import { analytics as analyticsUtils } from '@root/utils'
import { setupChatbot, useAuth } from '@root/hooks'
import PlaceholderLoader from '@root/components/Loader/PlaceholderLoader'
import { MetaTagsProps } from '@root/types'
import { UNPROTECTED_PAGES_PATHS } from '@root/constants'
import { segmentApi } from '@root/api'
import { MetaTags } from '../MetaTags'
import { Container, StyledLayout } from './DashboardLayout.styles'

export interface DashboardLayoutProps extends MetaTagsProps {
  maxWidth?: string
  children: ReactNode
}

const DashboardLayout = (props: DashboardLayoutProps): JSX.Element => {
  const { children, maxWidth = '1280px', title } = props
  const theme = useTheme()
  const router = useRouter()
  const [isChatbotReady, setIsChatbotReady] = useState(false)
  const [hasInitChat, setHasInitChat] = useState(false)
  const { status, user, token } = useAuth()

  const handleAppDownloadClick = () => {
    window.open(ENV.ONE_LINKS.default, '_blank')
    analyticsUtils.trackWithOSPlatformPrefilledMobile()
  }

  const handleChatToggle = async () => {
    if (isChatbotReady && user && token && !hasInitChat) {
      setHasInitChat(true)
      await setupChatbot(user, token)
    }
    analyticsUtils.trackWithOSPlatformPrefilledBot('desktop-bottom-right')
    return adaEmbed.toggle()
  }

  // redirect to `/` if user is not logged in
  useEffect(() => {
    if (status === 'unauthorized') {
      router.push(UNPROTECTED_PAGES_PATHS.root)
    }
    segmentApi.page()
  }, [status])

  return (
    <>
      <MetaTags title={title} />
      <Script
        src="https://static.ada.support/embed2.js"
        onReady={() => {
          ;(window as any).adaSettings = {
            handle: 'moneylion',
            lazy: true,
          }

          setIsChatbotReady(true)
        }}
      />
      {status === 'loading' || status === 'unauthorized' ? (
        <PlaceholderLoader />
      ) : (
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{ width: '300px', flexShrink: 0 }}
            className="hidden-mobile"
          />
          <SideBar />
          <Header
            className="hidden-desktop"
            ctaButton={{
              label: 'Get app',
              onClick: () => handleAppDownloadClick(),
            }}
            isChatbotReady={isChatbotReady}
          />

          <div
            style={{
              background: theme.color?.BACKGROUND_PAGE,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Container className="cta-container hidden-mobile">
              <button
                className="icon-wrapper"
                type="button"
                aria-label="Chat with us"
                onClick={handleChatToggle}
                disabled={!isChatbotReady}
              >
                <Icon
                  style={{ color: theme.color?.BACKGROUND_CONTENT }}
                  name="chat"
                />
              </button>
            </Container>

            <StyledLayout maxWidth={maxWidth}>
              <div className="placeholder" />
              <div id="page-heading" className="mb-3 page-heading">
                <Heading size="medium">{title}</Heading>
              </div>
              {children}

              <div className="mb-4 hidden-mobile" />
              <div className="mb-8 hidden-desktop" />
            </StyledLayout>
          </div>
          <MobileNavBar />
        </div>
      )}
    </>
  )
}

export { DashboardLayout }
