import { useEffect, useRef, useState } from 'react'
import IBank from '@root/types/Bank'
import { useUIStore } from '@root/store'
import { bankApi } from '@root/api'
import { BankSource, PlaidMetadata } from '@root/api/bankApi'

interface InitializePlaidProps {
  source: BankSource
  onSuccess?: (bankId: string) => void
  onExit?: () => void
  setIsLinking?: (value: boolean) => void
}

interface LaunchPlaidPayload {
  bvMainSession: string
  bvSubSession: string
  token: string
  bank: IBank
  isRelinkBank: boolean
  oAuthId: string
}

const usePlaid = ({
  source,
  onExit = () => void 0,
  onSuccess = () => void 0,
  setIsLinking,
}: InitializePlaidProps) => {
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)

  const [bank, setBank] = useState<IBank>({} as IBank)
  const [isRelinkBank, setIsRelinkBank] = useState<boolean>(false)
  const [bvMainSession, setBvMainSession] = useState<string>('')
  const [bvSubSession, setBvSubSession] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [oAuthId, setOAuthId] = useState<string>('')

  const plaidHandler = useRef<any>(undefined)

  useEffect(() => {
    if (token) {
      plaidHandler.current = window.Plaid.create({
        token,
        onSuccess: handlePlaidSuccess,
        onExit,
        onEvent: handlePlaidEvent,
        receivedRedirectUri: oAuthId ? window.location.href : undefined,
      })

      if (plaidHandler.current) {
        plaidHandler.current.open()
      }
    }
  }, [token, oAuthId])

  const handlePlaidSuccess = async (publicToken: string) => {
    try {
      setIsLinking && setIsLinking(true)
      const resp = await bankApi.plaidLinkSuccess({
        publicToken,
        bvMainSession,
        bvSubSession,
        institutionProviderId: bank.institutionProviderId,
        source,
      })

      onSuccess(resp.data.bankId)
    } catch {
      setShowAppDialog(true)
      setIsLinking && setIsLinking(false)
    }
  }

  const handlePlaidEvent = (eventName: string, metadata: PlaidMetadata) => {
    bankApi.plaidLinkEvent({
      bvMainSession,
      bvSubSession,
      eventName,
      institutionProviderId: bank.institutionProviderId,
      metadata,
      relink: isRelinkBank,
      source,
    })
  }

  const launchPlaid = (payload: LaunchPlaidPayload) => {
    setIsRelinkBank(payload.isRelinkBank)
    setBvMainSession(payload.bvMainSession)
    setBvSubSession(payload.bvSubSession)
    setBank(payload.bank)
    setOAuthId(payload.oAuthId)
    setToken(payload.token)
  }

  const removePlaid = () => {
    setToken('')

    if (plaidHandler.current) {
      plaidHandler.current.destroy()
    }
  }

  return {
    launchPlaid,
    removePlaid,
  }
}

export default usePlaid
