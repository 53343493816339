import { RoarMoneyTestValues, CBPlusTestValues } from '@root/store'
import ISubscriptionStatus, {
  IGetSubscriptionEsignRequest,
  IGetSubscriptionEsignData,
  ISignSubscriptionEsignRequest,
  ISignSubscriptionEsignData,
  ICbPlusUpdateSubscriptionAccountRequest,
  ICbPlusUpdateSubscriptionAccountData,
  ICreateCbPlusAccountRequest,
  ICreateCbPlusAccountData,
  ICheckInstacashIntentEligibleBvRequest,
  ICheckInstacashIntentEligibleBvData,
  CreateMLLiteRes,
  SubscribeInstacashBody,
  SubscribeInstacashRes,
  ICheckInstacashEligibleBvRes,
} from '@root/types/Subscription'
import { https } from '@root/utils'

interface IGetSubscriptionEsignResponse {
  code: string
  data: IGetSubscriptionEsignData
  message: string
}

interface ISignSubscriptionEsignResponse {
  code: string
  data: ISignSubscriptionEsignData
  message: string
}

export interface CBPlusBvUnderwritingBody {
  testValues: CBPlusTestValues
  roarMoneyTestValues: RoarMoneyTestValues
}

interface CBPlusBVUnderwritingRes {
  code: number
  message: string
  data: {
    status: string
    message: string
    riskDecisionSnapShotId: string
    disbursementAmt: number
    escrowDisbursementAmt: number
    apr: number
    bankName: string
    bankAccountNumber: string
    bankRoutingNumber: string
    last4DigitAccountNumber: string
    decisionType: string
  }
}

interface ICbPlusUpdateSubscriptionAccountResponse {
  code: string
  data: ICbPlusUpdateSubscriptionAccountData
  message: string
}

interface ICreateCbPlusAccountResponse {
  code: string
  data: ICreateCbPlusAccountData
  message: string
}

interface ICheckInstacashEligibleBvResponse {
  code: string
  data: ICheckInstacashIntentEligibleBvData
  message: string
}

const getSubscriptionStatus = async (): Promise<ISubscriptionStatus> => {
  const subscriptionStatusRes = await (await https()).get(`subscription/status`)
  return subscriptionStatusRes?.data.data
}

/**
 * ! WARNING: This endpoint does not work when requesting for "CBPLUSLOAN". Use [postCbPlusUpdateSubscriptionAccount] instead to retrieve the Esign
 * @usage: For the all flows, except CB Plus Flow
 * @description: Get Esign documents
 * @param {request}: IGetSubscriptionEsignRequest
 * @returns {IGetSubscriptionEsignData}: Returns the mandatory and optional Esign documents
 */
const postGetSubscriptionEsign = async (
  request: IGetSubscriptionEsignRequest,
  signal?: AbortSignal
): Promise<IGetSubscriptionEsignResponse> => {
  const response = await (
    await https({ signal })
  ).post('/onboarding/v2/esign', request)
  return response.data
}

/**
 * @usage: For the all flows
 * @description: Sign Esign documents
 * @param {request}: ISignSubscriptionEsignRequest
 * @returns {ISignSubscriptionEsignData}: Returns the status code 0 or 115 for successful Esign
 */
const postSignSubscriptionEsign = async (
  request: ISignSubscriptionEsignRequest,
  signal?: AbortSignal
): Promise<ISignSubscriptionEsignResponse> => {
  const response = await (
    await https({ signal })
  ).post('/onboarding/esign/sign', request)
  return response.data
}

const postCBPlusBvUnderwriting = async (
  body?: CBPlusBvUnderwritingBody,
  signal?: AbortSignal
): Promise<CBPlusBVUnderwritingRes> => {
  const response = await (
    await https({ signal })
  ).post(`/onboarding/cbplus/v5/bvUnderwriting`, body || {})
  return response.data
}

/**
 * @usage: For the Payment Schedule Flow
 * @description: Update payment details
 * @param {request}: ICbPlusUpdateSubscriptionAccountRequest
 * @returns {ICbPlusUpdateSubscriptionAccountData}: Returns the fee amount and fee dates
 */
const postCbPlusUpdateSubscriptionAccount = async (
  request: ICbPlusUpdateSubscriptionAccountRequest
): Promise<ICbPlusUpdateSubscriptionAccountResponse> => {
  const response = await (
    await https()
  ).post('/onboarding/cbplus/v2/updateSubscriptionAccount', request)
  return response.data
}

/**
 * @usage: For the CB Plus Flow
 * @description: Create CB Plus Account
 * @param {request}: ICreateCbPlusAccountRequest
 * @returns {ICreateCbPlusAccountData}: Returns the fee amount and fee dates
 */
const postCreateCbPlusAccount = async (
  request: ICreateCbPlusAccountRequest
): Promise<ICreateCbPlusAccountResponse> => {
  const response = await (
    await https()
  ).post('/onboarding/cbplus/v2/subscription', request)
  return response.data
}

/**
 * @flow: Instacash
 * @description: Check user eligibility for Instacash
 * @param {request}: Test values
 * @returns: Returns the result and underwriting transaction Id
 */
const postInstacashIntentEligibleBv = async (
  request: ICheckInstacashIntentEligibleBvRequest
): Promise<ICheckInstacashEligibleBvResponse> => {
  const response = await (
    await https()
  ).post('/onboarding/instacash/intent/v2/eligibleBv', request)
  return response.data
}

const postInstacashEligibleBv = async (
  request: ICheckInstacashIntentEligibleBvRequest
): Promise<ICheckInstacashEligibleBvRes> => {
  const response = await (
    await https()
  ).post('/onboarding/instacash/v6/eligibleBv', request)
  return response.data
}

const createMLLite = async (signal?: AbortSignal): Promise<CreateMLLiteRes> => {
  const response = await (
    await https({ signal })
  ).post('/onboarding/account/create/ml-lite')
  return response.data
}

const subscribeInstacash = async (
  body: SubscribeInstacashBody
): Promise<SubscribeInstacashRes> => {
  const response = await (
    await https({ expectErrorData: true })
  ).post('/onboarding/instacash/v3/subscription', body)
  return response.data
}

const subscriptionApi = {
  getSubscriptionStatus,
  postGetSubscriptionEsign,
  postSignSubscriptionEsign,
  postCBPlusBvUnderwriting,
  postCbPlusUpdateSubscriptionAccount,
  postCreateCbPlusAccount,
  postInstacashIntentEligibleBv,
  createMLLite,
  subscribeInstacash,
  postInstacashEligibleBv,
}

export default subscriptionApi
