const labels = {
  name: 'Name',
  roarTag: 'RoarTag',
  private: 'Private',
  public: 'Public',
  socialSecurityNumber: 'Social Security Number',
  phoneNumber: 'Phone number',
  address: 'Address',
}

export default labels
