import { Button } from '@moneylion/mlds-web'
import styled from 'styled-components'

export const StyledSideBarContent = styled.div`
  height: 100vh;
  width: 100%;

  .moneylion-logo {
    max-width: 100%;
  }

  #sidebar-logo svg {
    width: 213px;
    height: 64px;
  }
`

export const CustomButton = styled(Button)<{ isActive: boolean }>`
  border-radius: 24px;
  height: 42px;
  min-height: 42px;
  min-width: 150px;

  span {
    justify-content: start;
  }

  & > span > span {
    // Label component
    font-weight: 400;
  }

  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.color?.BUTTON_SECONDARY_BACKGROUND
      : theme.color?.BACKGROUND_CONTENT};
`

export const StyledSidebar = styled.div`
  max-width: 300px;
  width: 100%;
  background: ${({ theme }) => theme.color?.BACKGROUND_CONTENT};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
`
