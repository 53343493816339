import { Icon } from '@moneylion/mlds-web'
import { HeaderButtonContainer } from '@root/components/HeaderButtonContainer.styles'

interface CloseButtonProps {
  onClickClose?: () => void
  withBackgroundColor?: boolean
  size?: number
}

const CloseButton = ({
  onClickClose,
  withBackgroundColor = true,
  size = 24,
}: CloseButtonProps): JSX.Element => (
  <HeaderButtonContainer
    onClick={onClickClose}
    role="button"
    aria-label="close button"
    withBackgroundColor={withBackgroundColor}
  >
    <Icon size={size} name="close" />
  </HeaderButtonContainer>
)

export { CloseButton }
