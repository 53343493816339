import { Body, Icon } from '@moneylion/mlds-web'

export interface ListTitleProps {
  text: string
  withHelpIcon?: boolean
  onClickOpenHelpModal?: () => void
}

const ListTitle = ({
  text,
  withHelpIcon = false,
  onClickOpenHelpModal,
}: ListTitleProps): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Body color="primary" size="large">
        {text}
      </Body>
      {withHelpIcon && (
        <Icon
          style={{ cursor: 'pointer' }}
          name="help"
          color="tertiary"
          onClick={onClickOpenHelpModal}
        />
      )}
    </div>
  )
}

export { ListTitle }
