import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Body } from '@moneylion/mlds-web'

type TooltipProps = {
  children: ReactNode
  tooltipText: string
}

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipText = styled.span`
  display: none;
  width: 230px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color?.BACKGROUND_CONTENT};
  color: ${({ theme }) => theme.color?.CONTENT_PRIMARY};
  text-align: center;
  border-radius: 16px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  right: 0
  margin-right: 60px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    white-space: nowrap;
    right: 0;
  }

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

const Tooltip: React.FC<TooltipProps> = ({ children, tooltipText }) => {
  return (
    <TooltipContainer>
      {children}
      <TooltipText>
        <Body size="large">{tooltipText}</Body>
      </TooltipText>
    </TooltipContainer>
  )
}

export default Tooltip
