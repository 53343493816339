const labels = {
  title: 'Generic Backdoor',
  prefill: 'Prefill signup form',
  onboardStateRmIfs: {
    label: 'Onboard State RM IFS',
    options: ['none', 'lowrisk', 'mediumrisk', 'highrisk'],
  },
}

export default labels
