import { useEffect, FunctionComponent } from 'react'
import Image from 'next/image'
import { Dialog, Body } from '@moneylion/mlds-web'
import { useUIStore } from '@root/store'
import { useCloseModalEventListener } from '@root/helpers'
import labels from './labels'

const AppDialog: FunctionComponent = () => {
  const showAppDialog = useUIStore((state) => state.showAppDialog)
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)

  const options = useUIStore((state) => state.appDialogOptions)
  useCloseModalEventListener(() => setShowAppDialog(false))

  useEffect(() => {
    if (showAppDialog) {
      showDialog()
      setShowAppDialog(false, options)
    }
  }, [showAppDialog])

  const showDialog = (): void => {
    const titleAlign = options?.titleAlign || 'text-center'
    const textAlign = options?.textAlign || 'text-center'
    const dialogWidth = options?.dialogSize === 'Large' ? 448 : undefined
    window.showDialogs(
      <Dialog
        isDismissable={!options || options?.isDismissable || false}
        width={dialogWidth}
      >
        {({ closeDialog }) =>
          options ? (
            <>
              {options.imageSrc && (
                <Image
                  src={options.imageSrc}
                  alt="banner"
                  width={options?.dialogSize === 'Large' ? 400 : 280}
                  height={options?.dialogSize === 'Large' ? 225 : 110}
                />
              )}
              <Dialog.Title className={titleAlign} text={options.title} />
              {options.subtitle && (
                <Body
                  className={textAlign}
                  style={{ width: '100%', textTransform: 'capitalize' }}
                >
                  {options.subtitle}
                </Body>
              )}
              {options.text &&
                options.text.length !== 0 &&
                options.text.map((item, index) => (
                  <Body
                    key={`dialog-text-${index}`}
                    className={textAlign}
                    color="secondary"
                    style={{ width: '100%' }}
                  >
                    {item}
                  </Body>
                ))}
              <Dialog.Button.Container direction={options.buttonDirection}>
                {options.buttons.map((button) => (
                  <Dialog.Button
                    style={{ flex: '1 1 auto' }}
                    key={button.label}
                    onClick={() => {
                      if (button.onClick) {
                        button.onClick(closeDialog)
                      } else {
                        closeDialog()
                      }
                    }}
                    color={button.color || 'primary'}
                  >
                    {button.label}
                  </Dialog.Button>
                ))}
              </Dialog.Button.Container>
            </>
          ) : (
            // Will show generic error modal by default
            <>
              <Dialog.Title text={labels.title} />
              <Dialog.Text text={labels.text} />
              <Dialog.Button.Container direction="row">
                <Dialog.Button onClick={closeDialog} fullWidth>
                  {labels.gotItButton}
                </Dialog.Button>
              </Dialog.Button.Container>
            </>
          )
        }
      </Dialog>
    )
  }

  return null
}

export default AppDialog
