import Cookies from 'js-cookie'
import { useAuthStore, useOnboardingStore } from '@root/store'
import { formats } from '@root/utils'
import { windowAnalyticsObjectExists } from '@root/helpers'

const page = (): void => {
  windowAnalyticsObjectExists() &&
    window.analytics.page(undefined, undefined, {
      'Next.js-hydration': window.nextIsFirstPageMetricSent
        ? undefined
        : window.nextHydration,
      'Next.js-render': window.nextRender,
      'Next.js-route-change-to-render': window.nextRouteChangeToRender,
    })
}

interface UserTraits {
  email?: string
  ml_user_id?: string
  name?: string
  phone?: string
  username?: string
}

const identify = (
  userId: string | undefined,
  traitsOrCallback?: UserTraits | (() => void),
  options = {},
  callback = () => void 0
): void => {
  if (typeof traitsOrCallback === 'function') {
    windowAnalyticsObjectExists() &&
      window.analytics.identify(userId, traitsOrCallback)
  } else {
    windowAnalyticsObjectExists() &&
      window.analytics.identify(userId, traitsOrCallback, options, callback)
  }
}

const trackWithOSPlatform = (
  event: string,
  properties: { [key: string]: any } = {},
  options: { [key: string]: any } = {},
  callback = () => void 0
): void => {
  const { user } = useAuthStore.getState()
  const { isIntentFlow } = useOnboardingStore.getState()

  const defaultProperties: { [key: string]: unknown } = {
    os: formats.OS(),
    platform: 'Web',
    source: 'webApp', // To cater for the legacy segment events
    ml_user_id: user?.id,
    flow: isIntentFlow ? 'intent' : 'default',
  }
  windowAnalyticsObjectExists() &&
    window.analytics.track(
      event,
      {
        ...defaultProperties,
        ...properties,
      },
      options,
      callback
    )
}

const trackOnelink = (
  properties: { [key: string]: any },
  options: { [key: string]: any } = {},
  callback = () => void 0
): void => {
  const userId = Cookies.get('userId') // TODO: get this from the cookies or zustand store
  windowAnalyticsObjectExists() &&
    window.analytics.track(
      'acc_appsflyer_onelink_direct',
      {
        ...properties,
        ml_user_id: userId,
        source: 'webApp',
        entry_point: window.location.pathname,
      },
      options,
      callback
    )
}

const segmentApi = {
  page,
  identify,
  trackWithOSPlatform,
  trackOnelink,
}

export default segmentApi
