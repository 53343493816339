import {
  IGoogleAddressAutocomplete,
  IGoogleAddressDetail,
} from '@root/types/Address'
import { https } from '@root/utils'

interface IGetAddressAutocompleteResponse {
  code: string
  data: IGoogleAddressAutocomplete[]
  message: string
}

interface IGetAddressDetailResponse {
  code: string
  data: IGoogleAddressDetail
  message: string
}

/**
 * @usage: For the Address Flow
 * @description: Step 1 - Get address suggestions
 * @param {address}: The address from the search input field
 * @returns {IGoogleAddressAutocomplete[]}: Returns a list of autocomplete address suggestions
 */
const getAddressAutocomplete = async (
  address: string
): Promise<IGetAddressAutocompleteResponse> => {
  const response = await (
    await https()
  ).get(`/user/address/autocomplete?address=${address}`)
  return response.data
}

/**
 * @usage: For the Address Flow
 * @description: Step 2 - Get address details
 * @param {placeId}: The address place id from Google Maps API
 * @returns {IGoogleAddressDetail}: Returns the address details
 */
const getAddressDetail = async (
  placeId: string
): Promise<IGetAddressDetailResponse> => {
  const response = await (
    await https()
  ).get(`/user/address/detail?placeId=${placeId}`)
  return response.data
}

const googleMapsApi = {
  getAddressAutocomplete,
  getAddressDetail,
}

export default googleMapsApi
