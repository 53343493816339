const ONBOARDING = '/onboarding'

const MODULE = {
  addCard: '/add-card',
  paymentSchedule: '/payment-schedule',
}

const PRODUCT = {
  cbplus: '/cbplus',
  crypto: '/crypto',
  instacash: '/instacash',
  investment: '/investment',
  roarmoney: '/roarmoney',
}

const ADD_CARD_PATHS = {
  addCardStart: `${MODULE.addCard}/start`,
  addCard: `${MODULE.addCard}/add-card`,
  addCardPickBillingAddress: `${MODULE.addCard}/pick-billing-address`,
  addCardAddBillingAddress: `${MODULE.addCard}/add-billing-address`,
  addCardBackupAuth: `${MODULE.addCard}/backup-auth`,
}

const CBPLUS_PAGES_PATHS = {
  cbplusReferral: `${PRODUCT.cbplus}/referral`,
  cbplusDobAddress: `${PRODUCT.cbplus}/dob-and-address`,
  cbplusPhoneNumber: `${PRODUCT.cbplus}/phone-number`,
  cbplusSsnVerification: `${PRODUCT.cbplus}/ssn-verification`,
  cbplusIntro: `${PRODUCT.cbplus}/intro`,
  cbplusBVPreroll: `${PRODUCT.cbplus}/bv-preroll`,
  cbplusBVLinking: `${PRODUCT.cbplus}/bv-linking`,
  cbplusQualified: `${PRODUCT.cbplus}/qualified`,
  cbplusNonQualified: `${PRODUCT.cbplus}/non-qualified`,
  cbplusCreditReserve: `${PRODUCT.cbplus}/credit-reserve`,
  cbplusAccountActivation: `${PRODUCT.cbplus}/account-activation`,
  cbplusSuccess: `${PRODUCT.cbplus}/success`,
}

const CRYPTO_PAGES_PATHS = {
  cryptoReferral: `${PRODUCT.crypto}/referral`,
  cryptoDobAddress: `${PRODUCT.crypto}/dob-and-address`,
  cryptoPhoneNumber: `${PRODUCT.crypto}/phone-number`,
  cryptoSsnVerification: `${PRODUCT.crypto}/ssn-verification`,
  cryptoIntro: `${PRODUCT.crypto}/intro`,
  cryptoPreSuccess: `${PRODUCT.crypto}/pre-success`,
}

const INVESTMENT_PAGES_PATHS = {
  investmentReferral: `${PRODUCT.investment}/referral`,
  investmentDobAddress: `${PRODUCT.investment}/dob-and-address`,
  investmentPhoneNumber: `${PRODUCT.investment}/phone-number`,
  investmentSsnVerification: `${PRODUCT.investment}/ssn-verification`,
  investmentIntro: `${PRODUCT.investment}/intro`,
  investmentQuestion1: `${PRODUCT.investment}/question-1`,
  investmentQuestion2: `${PRODUCT.investment}/question-2`,
  investmentQuestion3: `${PRODUCT.investment}/question-3`,
  investmentQuestion4: `${PRODUCT.investment}/question-4`,
  investmentAccountActivation: `${PRODUCT.investment}/account-activation`,
  investmentPortfolio: `${PRODUCT.investment}/portfolio`,
}

const PAYMENT_SCHEDULE_PATHS = {
  payFrequency: `${MODULE.paymentSchedule}/pay-frequency`,
  weeklyAndBiweekly: `${MODULE.paymentSchedule}/weekly-and-biweekly`,
  weeklyAndBiweeklyLastPaid: `${MODULE.paymentSchedule}/weekly-and-biweekly/last-paid`,
  twiceAMonth: `${MODULE.paymentSchedule}/twice-a-month`,
  twiceAMonthFirstPaycheck: `${MODULE.paymentSchedule}/twice-a-month/first-paycheck`,
  twiceAMonthFirstAndSecondPaycheck: `${MODULE.paymentSchedule}/twice-a-month/first-and-second-paycheck`,
  monthly: `${MODULE.paymentSchedule}/monthly`,
  monthlyDayOfWeek: `${MODULE.paymentSchedule}/monthly/day-of-week`,
  monthlyDateOfMonth: `${MODULE.paymentSchedule}/monthly/date-of-month`,
}

const ROARMONEY_PAGES_PATHS = {
  roarmoneyReferral: `${PRODUCT.roarmoney}/referral`,
  roarmoneyDobAddress: `${PRODUCT.roarmoney}/dob-and-address`,
  roarmoneyPhoneNumber: `${PRODUCT.roarmoney}/phone-number`,
  roarmoneySsnVerification: `${PRODUCT.roarmoney}/ssn-verification`,
  roarmoneyIntro: `${PRODUCT.roarmoney}/intro`,
  roarmoneyAccountActivation: `${PRODUCT.roarmoney}/account-activation`,
  roarmoneyActivateCard: `${PRODUCT.roarmoney}/activate-card`,
}

const INSTACASH_PAGES_PATHS = {
  instacashReferral: `${PRODUCT.instacash}/referral`,
  instacashDobAddress: `${PRODUCT.instacash}/dob-and-address`,
  instacashPhoneNumber: `${PRODUCT.instacash}/phone-number`,
  instacashSsnVerification: `${PRODUCT.instacash}/ssn-verification`,
  instacashBVPreroll: `${PRODUCT.instacash}/bv-preroll`,
  instacashBVLinking: `${PRODUCT.instacash}/bv-linking`,
  instacashQualified: `${PRODUCT.instacash}/qualified`,
  instacashTrialQualified: `${PRODUCT.instacash}/trial-qualified`,
  instacashDisbursementActivation: `${PRODUCT.instacash}/disbursement-activation`,
  instacashDisbursementDestination: `${PRODUCT.instacash}/disbursement-destination`,
  instacashDisbursementSuccess: `${PRODUCT.instacash}/disbursement-success`,
}

const LITE_ONBOARDING_PAGES_PATHS = {
  referral: `/referral`,
  welcome: `/welcome`,
}

const CORE_ONBOARDING_PAGES_PATHS = {
  dobAddress: `/dob-and-address`,
  phoneNumber: `/phone-number`,
  ssnVerification: `/ssn-verification`,
}

export const ONBOARDING_CBPLUS_PAGES_PATHS = {
  cbplusReferral: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusReferral}`,
  cbplusDobAddress: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusDobAddress}`,
  cbplusPhoneNumber: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusPhoneNumber}`,
  cbplusSsnVerification: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusSsnVerification}`,
  cbplusIntro: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusIntro}`,
  cbplusBVPreroll: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusBVPreroll}`,
  cbplusBVLinking: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusBVLinking}`,
  cbplusQualified: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusQualified}`,
  cbplusNonQualified: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusNonQualified}`,
  cbplusCreditReserve: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusCreditReserve}`,
  cbplusInvestmentIntro: `${ONBOARDING}${PRODUCT.cbplus}${INVESTMENT_PAGES_PATHS.investmentIntro}`,
  cbplusInvestmentQuestion1: `${ONBOARDING}${PRODUCT.cbplus}${INVESTMENT_PAGES_PATHS.investmentQuestion1}`,
  cbplusInvestmentQuestion2: `${ONBOARDING}${PRODUCT.cbplus}${INVESTMENT_PAGES_PATHS.investmentQuestion2}`,
  cbplusInvestmentQuestion3: `${ONBOARDING}${PRODUCT.cbplus}${INVESTMENT_PAGES_PATHS.investmentQuestion3}`,
  cbplusInvestmentQuestion4: `${ONBOARDING}${PRODUCT.cbplus}${INVESTMENT_PAGES_PATHS.investmentQuestion4}`,
  cbplusInvestmentAccountActivation: `${ONBOARDING}${PRODUCT.cbplus}${INVESTMENT_PAGES_PATHS.investmentAccountActivation}`,
  cbplusInvestmentPortfolio: `${ONBOARDING}${PRODUCT.cbplus}${INVESTMENT_PAGES_PATHS.investmentPortfolio}`,
  cbplusPayFrequency: `${ONBOARDING}${PRODUCT.cbplus}${PAYMENT_SCHEDULE_PATHS.payFrequency}`,
  cbplusWeeklyAndBiweekly: `${ONBOARDING}${PRODUCT.cbplus}${PAYMENT_SCHEDULE_PATHS.weeklyAndBiweekly}`,
  cbplusWeeklyAndBiweeklyLastPaid: `${ONBOARDING}${PRODUCT.cbplus}${PAYMENT_SCHEDULE_PATHS.weeklyAndBiweeklyLastPaid}`,
  cbplusTwiceAMonth: `${ONBOARDING}${PRODUCT.cbplus}${PAYMENT_SCHEDULE_PATHS.twiceAMonth}`,
  cbplusTwiceAMonthFirstPaycheck: `${ONBOARDING}${PRODUCT.cbplus}${PAYMENT_SCHEDULE_PATHS.twiceAMonthFirstPaycheck}`,
  cbplusTwiceAMonthFirstAndSecondPaycheck: `${ONBOARDING}${PRODUCT.cbplus}${PAYMENT_SCHEDULE_PATHS.twiceAMonthFirstAndSecondPaycheck}`,
  cbplusMonthly: `${ONBOARDING}${PRODUCT.cbplus}${PAYMENT_SCHEDULE_PATHS.monthly}`,
  cbplusMonthlyDayOfWeek: `${ONBOARDING}${PRODUCT.cbplus}${PAYMENT_SCHEDULE_PATHS.monthlyDayOfWeek}`,
  cbplusMonthlyDateOfMonth: `${ONBOARDING}${PRODUCT.cbplus}${PAYMENT_SCHEDULE_PATHS.monthlyDateOfMonth}`,
  cbplusAccountActivation: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusAccountActivation}`,
  cbplusSuccess: `${ONBOARDING}${CBPLUS_PAGES_PATHS.cbplusSuccess}`,
}

export const ONBOARDING_CRYPTO_PAGES_PATHS = {
  cryptoReferral: `${ONBOARDING}${CRYPTO_PAGES_PATHS.cryptoReferral}`,
  cryptoDobAddress: `${ONBOARDING}${CRYPTO_PAGES_PATHS.cryptoDobAddress}`,
  cryptoPhoneNumber: `${ONBOARDING}${CRYPTO_PAGES_PATHS.cryptoPhoneNumber}`,
  cryptoSsnVerification: `${ONBOARDING}${CRYPTO_PAGES_PATHS.cryptoSsnVerification}`,
  cryptoIntro: `${ONBOARDING}${CRYPTO_PAGES_PATHS.cryptoIntro}`,
  cryptoRoarmoneyAccountActivation: `${ONBOARDING}${PRODUCT.crypto}${ROARMONEY_PAGES_PATHS.roarmoneyAccountActivation}`,
  cryptoPreSuccess: `${ONBOARDING}${CRYPTO_PAGES_PATHS.cryptoPreSuccess}`,
}

export const ONBOARDING_INSTACASH_PAGES_PATHS = {
  instacashReferral: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashReferral}`,
  instacashDobAddress: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashDobAddress}`,
  instacashPhoneNumber: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashPhoneNumber}`,
  instacashSsnVerification: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashSsnVerification}`,
  instacashBVPreroll: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashBVPreroll}`,
  instacashBVLinking: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashBVLinking}`,
  instacashQualified: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashQualified}`,
  instacashTrialQualified: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashTrialQualified}`,
  instacashRoarmoneyAccountActivation: `${ONBOARDING}${PRODUCT.instacash}${ROARMONEY_PAGES_PATHS.roarmoneyAccountActivation}`,
  instacashAddCardStart: `${ONBOARDING}${PRODUCT.instacash}${ADD_CARD_PATHS.addCardStart}`,
  instacashAddCard: `${ONBOARDING}${PRODUCT.instacash}${ADD_CARD_PATHS.addCard}`,
  instacashAddCardPickBillingAddress: `${ONBOARDING}${PRODUCT.instacash}${ADD_CARD_PATHS.addCardPickBillingAddress}`,
  instacashAddCardAddBillingAddress: `${ONBOARDING}${PRODUCT.instacash}${ADD_CARD_PATHS.addCardAddBillingAddress}`,
  instacashAddCardBackupAuth: `${ONBOARDING}${PRODUCT.instacash}${ADD_CARD_PATHS.addCardBackupAuth}`,
  instacashDisbursementActivation: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashDisbursementActivation}`,
  instacashDisbursementDestination: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashDisbursementDestination}`,
  instacashDisbursementSuccess: `${ONBOARDING}${INSTACASH_PAGES_PATHS.instacashDisbursementSuccess}`,
}

export const ONBOARDING_INVESTMENT_PAGES_PATHS = {
  investmentReferral: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentReferral}`,
  investmentDobAddress: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentDobAddress}`,
  investmentPhoneNumber: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentPhoneNumber}`,
  investmentSsnVerification: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentSsnVerification}`,
  investmentIntro: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentIntro}`,
  investmentQuestion1: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentQuestion1}`,
  investmentQuestion2: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentQuestion2}`,
  investmentQuestion3: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentQuestion3}`,
  investmentQuestion4: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentQuestion4}`,
  investmentAccountActivation: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentAccountActivation}`,
  investmentPortfolio: `${ONBOARDING}${INVESTMENT_PAGES_PATHS.investmentPortfolio}`,
}

export const ONBOARDING_ROARMONEY_PAGES_PATHS = {
  roarmoneyReferral: `${ONBOARDING}${ROARMONEY_PAGES_PATHS.roarmoneyReferral}`,
  roarmoneyDobAddress: `${ONBOARDING}${ROARMONEY_PAGES_PATHS.roarmoneyDobAddress}`,
  roarmoneyPhoneNumber: `${ONBOARDING}${ROARMONEY_PAGES_PATHS.roarmoneyPhoneNumber}`,
  roarmoneySsnVerification: `${ONBOARDING}${ROARMONEY_PAGES_PATHS.roarmoneySsnVerification}`,
  roarmoneyIntro: `${ONBOARDING}${ROARMONEY_PAGES_PATHS.roarmoneyIntro}`,
  roarmoneyAccountActivation: `${ONBOARDING}${ROARMONEY_PAGES_PATHS.roarmoneyAccountActivation}`,
  roarmoneyActivateCard: `${ONBOARDING}${ROARMONEY_PAGES_PATHS.roarmoneyActivateCard}`,
}

export const ONBOARDING_ORGANIC_PAGES_PATHS = {
  referral: `${ONBOARDING}${LITE_ONBOARDING_PAGES_PATHS.referral}`,
  welcome: `${ONBOARDING}${LITE_ONBOARDING_PAGES_PATHS.welcome}`,
  dobAddress: `${ONBOARDING}${CORE_ONBOARDING_PAGES_PATHS.dobAddress}`,
  phoneNumber: `${ONBOARDING}${CORE_ONBOARDING_PAGES_PATHS.phoneNumber}`,
  ssnVerification: `${ONBOARDING}${CORE_ONBOARDING_PAGES_PATHS.ssnVerification}`,
}

/**
 * @description Temporary design, will revisit our pages path structure later
 * @usage LITE_CORE_ONBOARDING_ON_VARIOUS_FLOW[flow].dobAddress
 */
export const LITE_CORE_ONBOARDING_ON_VARIOUS_FLOW = {
  organic: {
    referral: ONBOARDING_ORGANIC_PAGES_PATHS.referral,
    dobAddress: ONBOARDING_ORGANIC_PAGES_PATHS.dobAddress,
    phoneNumber: ONBOARDING_ORGANIC_PAGES_PATHS.phoneNumber,
    ssnVerification: ONBOARDING_ORGANIC_PAGES_PATHS.ssnVerification,
  },
  instacash: {
    referral: ONBOARDING_INSTACASH_PAGES_PATHS.instacashReferral,
    dobAddress: ONBOARDING_INSTACASH_PAGES_PATHS.instacashDobAddress,
    phoneNumber: ONBOARDING_INSTACASH_PAGES_PATHS.instacashPhoneNumber,
    ssnVerification: ONBOARDING_INSTACASH_PAGES_PATHS.instacashSsnVerification,
  },
  cbplus: {
    referral: ONBOARDING_CBPLUS_PAGES_PATHS.cbplusReferral,
    dobAddress: ONBOARDING_CBPLUS_PAGES_PATHS.cbplusDobAddress,
    phoneNumber: ONBOARDING_CBPLUS_PAGES_PATHS.cbplusPhoneNumber,
    ssnVerification: ONBOARDING_CBPLUS_PAGES_PATHS.cbplusSsnVerification,
  },
  crypto: {
    referral: ONBOARDING_CRYPTO_PAGES_PATHS.cryptoReferral,
    dobAddress: ONBOARDING_CRYPTO_PAGES_PATHS.cryptoDobAddress,
    phoneNumber: ONBOARDING_CRYPTO_PAGES_PATHS.cryptoPhoneNumber,
    ssnVerification: ONBOARDING_CRYPTO_PAGES_PATHS.cryptoSsnVerification,
  },
  investment: {
    referral: ONBOARDING_INVESTMENT_PAGES_PATHS.investmentReferral,
    dobAddress: ONBOARDING_INVESTMENT_PAGES_PATHS.investmentDobAddress,
    phoneNumber: ONBOARDING_INVESTMENT_PAGES_PATHS.investmentPhoneNumber,
    ssnVerification:
      ONBOARDING_INVESTMENT_PAGES_PATHS.investmentSsnVerification,
  },
  roarmoney: {
    referral: ONBOARDING_ROARMONEY_PAGES_PATHS.roarmoneyReferral,
    dobAddress: ONBOARDING_ROARMONEY_PAGES_PATHS.roarmoneyDobAddress,
    phoneNumber: ONBOARDING_ROARMONEY_PAGES_PATHS.roarmoneyPhoneNumber,
    ssnVerification: ONBOARDING_ROARMONEY_PAGES_PATHS.roarmoneySsnVerification,
  },
}

export const PROTECTED_PAGES_PATHS = {
  offers: '/products-offers',
  accounts: '/accounts',
  referrals: '/referrals',
  profile: '/profile',
  ...ONBOARDING_ORGANIC_PAGES_PATHS,
  ...ONBOARDING_CBPLUS_PAGES_PATHS,
  ...ONBOARDING_CRYPTO_PAGES_PATHS,
  ...ONBOARDING_INSTACASH_PAGES_PATHS,
  ...ONBOARDING_INVESTMENT_PAGES_PATHS,
  ...ONBOARDING_ROARMONEY_PAGES_PATHS,
}

export const UNPROTECTED_PAGES_PATHS = {
  root: '/',
  login: '/login',
  mfaOptions: '/login/mfa-options',
  mfaCode: '/login/mfa-code',
  forgotPassword: '/login/forgotpassword',
  forgotPasswordConfirm: '/login/forgotpassword/confirm',
  logout: '/logout',
}
