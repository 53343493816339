import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface FeatureFlagStoreInitialState {
  shouldIpAddressIncluded?: SplitIO.Treatment
}

type FeatureFlagTreatment = Record<
  keyof FeatureFlagStoreInitialState,
  SplitIO.Treatment
>

export interface FeatureFlagStore extends FeatureFlagStoreInitialState {
  setTreatment: (featureFlagTreatment: FeatureFlagTreatment) => void
  reset: () => void
}

const initialState: FeatureFlagStoreInitialState = {
  shouldIpAddressIncluded: undefined,
}

/**
 * @caution Avoid storing every treatment, it is recommended to call and forget. Only store the treatments when they are needed to be PERSISTED for other pages or components upon user's hard refresh.
 *
 * Usage example:
 *
 * ```jsx
 *  setTreatment({shouldIpAddressIncluded: 'on'})
 * ```
 *
 **/
const useFeatureFlagStore = create<FeatureFlagStore>()(
  persist(
    (set) => ({
      ...initialState,
      setTreatment: (featureFlagTreatment) => {
        set({ ...featureFlagTreatment })
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      name: 'featureFlagStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useFeatureFlagStore }
