import { Dispatch, SetStateAction, useState } from 'react'
import { useRouter } from 'next/router'
import { lifecycleApi } from '@root/api'
import { ENV } from '@root/config'
import {
  OnboardStateProductName,
  OnboardStateRequest,
  OnboardStateResponse,
} from '@root/types'
import { useBackdoorStore, useUIStore } from '@root/store'
import { useAbortController } from './useAbortController'

interface FetchOnboardingState {
  productName: OnboardStateProductName
}

const useOnboardState = (): {
  fetchOnboardState: (params: FetchOnboardingState) => Promise<void>
  isLoading: boolean
  result?: OnboardStateResponse['data']
  canEnterFlow: boolean | undefined
  setCanEnterFlow: Dispatch<SetStateAction<boolean | undefined>>
} => {
  const router = useRouter()

  const onboardStateRoarmoneyIfs = useBackdoorStore(
    (state) => state.onboardStateRoarmoneyIfs
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [canEnterFlow, setCanEnterFlow] = useState<boolean | undefined>(
    undefined
  )
  const [result, setResult] = useState<OnboardStateResponse['data']>()
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)

  const { abortSignal } = useAbortController()

  const fetchOnboardState = async ({
    productName,
  }: FetchOnboardingState): Promise<void> => {
    setIsLoading(true)

    let request: OnboardStateRequest = { productName }

    if (
      ENV.APP_ENV !== 'production' &&
      onboardStateRoarmoneyIfs &&
      productName === 'DDA2'
    ) {
      request = {
        ...request,
        testValues: {
          rmIfsBackdoorId: onboardStateRoarmoneyIfs,
        },
      }
    }

    await lifecycleApi
      .checkOnboardState(request, abortSignal)
      .then((res) => {
        setResult(res.data)

        if (res.data.prerequisite.includes('IDV')) {
          router.back()
        }

        setCanEnterFlow(res.data.eligible)
      })
      .catch(() => {
        setShowAppDialog(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    fetchOnboardState,
    result,
    isLoading,
    canEnterFlow,
    setCanEnterFlow,
  }
}

export { useOnboardState }
