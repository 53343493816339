import React, { useEffect, useState } from 'react'
import { Card, Icon } from '@moneylion/mlds-web'
import IBank from '@root/types/Bank'
import { BVLinking } from '@root/components/BVLinking'
import { BankSource } from '@root/api/bankApi'
import { Overlay } from './BankLinkingModal.styles'

interface BankLinkingModalProps {
  source: BankSource
  isVisible: boolean
  onClickClose: () => void
  onClickBank: (bank: IBank) => void
}

const BankLinkingModal: React.FunctionComponent<BankLinkingModalProps> = ({
  source,
  isVisible,
  onClickClose,
  onClickBank,
}) => {
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false)

  useEffect(() => {
    if (isVisible) {
      setShouldShowModal(true)
    } else {
      setTimeout(() => {
        setShouldShowModal(false)
      }, 180)
    }
  }, [isVisible])

  if (!shouldShowModal) {
    return null
  }

  return (
    <Overlay isVisible={isVisible}>
      <Card className="pa-4 modal-container">
        <div className="text-right">
          <Icon name="close" className="cursor" onClick={onClickClose} />
        </div>
        <BVLinking type="modal" source={source} onClickBank={onClickBank} />
      </Card>
    </Overlay>
  )
}

export default BankLinkingModal
