import IBank, { IBankInfo, IBankSummary } from '@root/types/Bank'
import { https } from '@root/utils'

export interface IFetchTopBanksResp {
  data: {
    institutions: IBank[]
  }
}

export type BankSource = 'webpfm' | 'webinstacash' | 'webcbplus'

const fetchTopBanks = async (
  source: BankSource
): Promise<IFetchTopBanksResp> => {
  const resp = await (await https()).post('/bank/institution/top', { source })
  return resp.data
}

const findBanks = async (
  query: string,
  source: BankSource
): Promise<IFetchTopBanksResp> => {
  const body = {
    source,
    query,
    page: 0,
    size: 4,
  }

  const resp = await (await https()).post('/bank/institution/search', body)
  return resp.data
}

interface IInitiateLinkingBody {
  bvMainSession: string
  bvSubSession: string
  institutionProviderId: number
  redirectUri?: string
  source: string
}

interface IInitiateLinkingResp {
  data: {
    isRelink: boolean
    plaidAttribute?: {
      linkToken: string
    }
    finicityAttribute?: {
      connectUrl: string
    }
    requestId: string
  }
}

const initiateLinking = async (
  body: IInitiateLinkingBody
): Promise<IInitiateLinkingResp> => {
  const resp = await (
    await https()
  ).post('/bank/linking/initiate', {
    ...body,
  })

  return resp.data
}

interface IPlaidLinkSuccessBody {
  bvMainSession: string
  bvSubSession: string
  institutionProviderId: number
  publicToken: string
  source: BankSource
}

interface IPlaidLinkSuccessResp {
  data: {
    bankId: string
  }
}

const plaidLinkSuccess = async (
  body: IPlaidLinkSuccessBody
): Promise<IPlaidLinkSuccessResp> => {
  const resp = await (
    await https()
  ).post('/bank/linking/plaid/login-success', {
    ...body,
  })

  return resp.data
}

type PlaidMetaDataViewName =
  | 'CONNECTED'
  | 'CREDENTIAL'
  | 'ERROR'
  | 'EXIT'
  | 'LOADING'
  | 'MFA'
  | 'SELECT_ACCOUNT'
  | 'SELECT_INSTITUTION'
  | 'TRANSITION_VIEW'
  | 'IS_MATCHED_USER'
  | 'IS_MATCHED_USER_UI'
  | 'CONSENT'

export interface PlaidMetadata {
  error_code: string
  error_message: string
  error_type: string
  exit_status: string
  institution_id: string
  institution_name: string
  institution_search_query: string
  link_session_id: string
  mfa_type: string
  request_id: string
  timestamp: string
  view_name: PlaidMetaDataViewName
}

interface IPlaidLinkEventBody {
  eventName: string
  metadata: PlaidMetadata
  relink: boolean
  institutionProviderId: number
  bvMainSession: string
  bvSubSession: string
  source: BankSource
}

const plaidLinkEvent = async (body: IPlaidLinkEventBody) => {
  const resp = await (
    await https()
  ).post('/bank/linking/plaid/link-event', {
    ...body,
  })
  return resp.data
}

interface IFetchBankAccountStatusResp {
  data: {
    banks: IBankInfo[]
  }
}

const fetchBankAccountStatus = async (
  source: BankSource,
  bankId?: string
): Promise<IFetchBankAccountStatusResp> => {
  const resp = await (
    await https()
  ).post('/bank/info/status', {
    source,
    bankId,
  })

  return resp.data
}

export interface IFetchBankAccountsBody {
  includePendingBank?: boolean
}

export interface IFetchBankAccountsResp {
  data: {
    banks: IBankSummary[]
  }
}

const fetchBankAccounts = async (
  body: IFetchBankAccountsBody = { includePendingBank: true }
): Promise<IFetchBankAccountsResp> => {
  const options = { ...body, includeInsLogoUrl: true }

  const resp = await (await https()).post('/bank/v2/info/account', { options })
  return resp.data
}
interface IFinicityLinkSuccessBody {
  institutionProviderId: number
  bvMainSession: string
  bvSubSession: string
  source: BankSource
}

interface IFinicityLinkSuccessResp {
  data: {
    bankId: string
  }
}

const finicityLinkSuccess = async (
  body: IFinicityLinkSuccessBody
): Promise<IFinicityLinkSuccessResp> => {
  const resp = await (
    await https()
  ).post('/bank/linking/finicity/login-success', {
    ...body,
  })
  return resp.data
}

interface IFinicityConnectEventBody {
  type: string
  metadata: any
  relink: boolean
  institutionProviderId: number
  bvMainSession: string
  bvSubSession: string
  source: BankSource
}

const finicityConnectEvent = async (body: IFinicityConnectEventBody) => {
  const resp = await (
    await https()
  ).post('/bank/linking/finicity/connect-event', {
    ...body,
  })

  return resp.data
}

const deleteBankAccount = async (bankId: string, source: BankSource) => {
  const resp = await (
    await https()
  ).post('/bank/linking/remove', {
    bankId,
    source,
  })
  return resp.data
}

const bankApi = {
  fetchTopBanks,
  findBanks,
  initiateLinking,
  plaidLinkSuccess,
  plaidLinkEvent,
  fetchBankAccountStatus,
  fetchBankAccounts,
  finicityLinkSuccess,
  finicityConnectEvent,
  deleteBankAccount,
}

export default bankApi
