import { create } from 'zustand'
import IPlus, {
  IInvestmentRiskQuestion,
  TInvestmentRiskQuestionKey,
  IInvestmentRiskAnswers,
  TInvestmentRiskTolerance,
} from '@root/types/Plus'
import { plusApi, sessionStorageApi } from '@root/api'

interface IInvestment {
  accountNo: string
  availableBalance: number
}

interface ICreditReserve {
  accountNo: string
  availableBalance: number
}

interface IInvestmentRiskScore {
  score: number
  riskTolerance: TInvestmentRiskTolerance
}

interface InvestmentInitialState {
  investment?: IInvestment | null
  creditReserve?: ICreditReserve | null
  investmentRiskQuestions?: IInvestmentRiskQuestion[] | undefined
  investmentRiskAnswers?: IInvestmentRiskAnswers | undefined
  investmentRiskScore?: IInvestmentRiskScore | undefined
}

export interface InvestmentState extends InvestmentInitialState {
  getInvestmentDetails: () => Promise<IPlus | undefined>
  getInvestmentRiskAnswers: () => IInvestmentRiskAnswers | undefined
  getInvestmentRiskQuestions: () => Promise<
    IInvestmentRiskQuestion[] | undefined
  >
  getInvestmentRiskScore: () => Promise<IInvestmentRiskScore | undefined>
  setInvestmentRiskAnswers: (
    questionKey: TInvestmentRiskQuestionKey,
    answerId: string | boolean
  ) => void
  setInvestmentRiskScore: (riskScore: IInvestmentRiskScore) => void
  reset: () => void
}

const initialState: InvestmentInitialState = {
  investment: undefined,
  creditReserve: undefined,
  investmentRiskQuestions: undefined,
  investmentRiskAnswers: undefined,
  investmentRiskScore: undefined,
}

const useInvestmentStore = create<InvestmentState>()((set, getState) => ({
  ...initialState,
  /**
   * @error: Sample
   * {
   *    code: "400",
   *    data: {
   *      code: "U0022",
   *      message: "No drivewealth user associated with Moneylion user"
   *    },
   *    message: "Bad Request"
   * }
   */
  getInvestmentDetails: async () => {
    try {
      const plusDetails = await plusApi.getPlusAccount()

      let investment = null
      let creditReserve = null

      if (
        plusDetails?.data &&
        plusDetails?.data.status === 'PLUS_API_SUCCESS' &&
        plusDetails?.data.accounts
      ) {
        const { accounts } = plusDetails.data
        const investmentAccountDetails = accounts.find(
          (value) => value.accountInternalType === 'INVESTMENT'
        )

        if (investmentAccountDetails) {
          investment = {
            accountNo: investmentAccountDetails.accountNo || '',
            availableBalance: investmentAccountDetails.availableBalance || 0,
          }
        }

        const escrowAccountDetails = accounts.filter(
          (value) =>
            value.accountInternalType === 'ESCROW' &&
            value.accountStatus === 'OPEN' &&
            value.availableBalance > 0
        )

        if (escrowAccountDetails.length > 1) {
          const accountWithLatestCreatedAtDate = escrowAccountDetails.sort(
            (firstEscrowAccountDetail, secondEscrowAccountDetail) =>
              firstEscrowAccountDetail.accountCreatedOn +
              secondEscrowAccountDetail.accountCreatedOn
          )
          creditReserve = {
            accountNo: accountWithLatestCreatedAtDate[0].accountNo || '',
            availableBalance:
              accountWithLatestCreatedAtDate[0].availableBalance || 0,
          }
        }

        if (escrowAccountDetails.length === 1) {
          creditReserve = {
            accountNo: escrowAccountDetails[0].accountNo || '',
            availableBalance: escrowAccountDetails[0].availableBalance || 0,
          }
        }
      }

      set({ investment, creditReserve })

      return plusDetails?.data
    } catch (error: any) {
      if (error?.data?.code === 'U0022') {
        set({ investment: null, creditReserve: null })
      }

      return Promise.reject(error)
    }
  },
  getInvestmentRiskAnswers: () => {
    const state = getState()

    if (!state.investmentRiskAnswers) {
      const sessionStorageInvestmentRiskAnswers = sessionStorageApi.get(
        'investmentRiskAnswers'
      )

      if (sessionStorageInvestmentRiskAnswers) {
        const storedInvestmentRiskAnswers = JSON.parse(
          sessionStorageInvestmentRiskAnswers
        )

        set({
          investmentRiskAnswers: {
            ...storedInvestmentRiskAnswers,
          },
        })

        return storedInvestmentRiskAnswers as IInvestmentRiskAnswers
      }
    }

    return state.investmentRiskAnswers
  },
  getInvestmentRiskQuestions: async () => {
    try {
      const state = getState()

      if (!state.investmentRiskQuestions) {
        const { data } = await plusApi.getInvestmentRiskQuestions()
        set({ investmentRiskQuestions: data })

        return data as IInvestmentRiskQuestion[]
      }

      return state.investmentRiskQuestions
    } catch (error) {
      return Promise.reject(error)
    }
  },
  setInvestmentRiskAnswers: async (
    questionKey: TInvestmentRiskQuestionKey,
    answerId: string | boolean
  ) => {
    const state = getState()

    const newInvestmentRiskAnswers = {
      ...state.investmentRiskAnswers,
      [questionKey]: answerId,
    }

    sessionStorageApi.set(
      'investmentRiskAnswers',
      JSON.stringify(newInvestmentRiskAnswers)
    )

    set({
      investmentRiskAnswers: {
        ...newInvestmentRiskAnswers,
      },
    })
  },
  getInvestmentRiskScore: async () => {
    try {
      const state = getState()

      if (!state.investmentRiskScore) {
        const { data } = await plusApi.getInvestmentRiskScore()
        const { score, riskTolerance } = data
        if (score && riskTolerance) {
          set({
            investmentRiskScore: {
              score,
              riskTolerance,
            },
          })
        }

        return data as IInvestmentRiskScore
      }

      return state.investmentRiskScore
    } catch (error) {
      return Promise.reject(error)
    }
  },
  setInvestmentRiskScore: async (riskScore: IInvestmentRiskScore) => {
    set({
      investmentRiskScore: riskScore,
    })
  },
  reset: () => {
    set(initialState)
  },
}))

export { useInvestmentStore }
