import styled from 'styled-components'

export const HeaderButtonContainer = styled.div<{
  withBackgroundColor: boolean
}>`
  border-radius: 100%;
  background-color: ${({ theme, withBackgroundColor }) =>
    withBackgroundColor && theme.color?.BUTTON_SECONDARY_BACKGROUND};
  cursor: pointer;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: background-color 0.3s ease;

  :hover {
    background-color: ${({ theme, withBackgroundColor }) =>
      !withBackgroundColor && theme.color?.BUTTON_SECONDARY_BACKGROUND};
    opacity: 0.8;
  }
`
