import dayjs from 'dayjs'
import { UAParser } from 'ua-parser-js'

const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

const formatName = (firstName: string, lastName: string): string => {
  const formattedFirstName = capitalizeFirstLetter(firstName)
  const formattedLastName = capitalizeFirstLetter(lastName)

  return `${formattedFirstName} ${formattedLastName}`
}

const formatBalance = (
  balance: number | string,
  minimumFractionDigits: number,
  needNegative?: boolean
): string => {
  const balanceInNumber = Number(balance)
  const formattedBalance = balanceInNumber.toLocaleString('en', {
    minimumFractionDigits,
    maximumFractionDigits: 2,
  })
  if (balanceInNumber < 0) {
    return `-$${formattedBalance.replace('-', '')}`
  }
  if (parseFloat(formattedBalance) === 0 || !needNegative) {
    return `$${formattedBalance}`
  }
  return `-$${formattedBalance}`
}

const formatDate = (date: dayjs.Dayjs, format: string): string => {
  return date.format(format)
}

const formatDayOrdinalNumbers = (day: number): string => {
  if (day > 3 && day < 21) {
    return `${day}th`
  }

  switch (day % 10) {
    case 1:
      return `${day}st`

    case 2:
      return `${day}nd`

    case 3:
      return `${day}rd`

    default:
      return `${day}th`
  }
}

export const formatDisbursementMethodDuration = (duration: number): string => {
  const durationLabels = {
    withMinutes: 'within minutes',
    withinTwoDays: 'within 1-2 business days',
    withinThreeDays: 'within 2-3 business days',
  }

  if (duration === 0) {
    return durationLabels.withMinutes
  } else if (duration < 49) {
    return durationLabels.withinTwoDays
  }

  return durationLabels.withinThreeDays
}

const formatDOB = (dob: string): string => {
  return dayjs(dob, 'YYYY-MM-DD').format('MM-DD-YYYY')
}

const formatEmail = (email: string): string => {
  return email.replace(/\s+$/, '')
}

const formatExpiryDate = (expiryDate: string): string => {
  return `${expiryDate.substring(0, 2)}/${expiryDate.substring(2)}`
}

const formatLast4Digit = (cardNumber: string): string => {
  return cardNumber.slice(-4)
}

const formatOS = (): string | undefined => {
  const { userAgent } = navigator
  const uaResult = new UAParser(userAgent).getResult()

  const { browser } = uaResult
  const browserName = browser.name
  const browserMajorVersion = browser.version && browser.version.split('.')[0]

  const formattedOS = `${browserName} ${browserMajorVersion}`

  return formattedOS || undefined
}

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')
  return `${formattedMinutes}:${formattedSeconds}`
}

const formatWowSignUpDate = (date: Date | string): string => {
  return dayjs(date).format('MMM YYYY')
}

const formatPhoneNumber = (number: string): string => {
  return (
    '(' +
    number.substring(0, 3) +
    ') ' +
    number.substring(3, 6) +
    '-' +
    number.substring(6, 10)
  )
}

const removeWhiteSpace = (str: string): string => {
  return str.replace(/\D/g, '')
}

const formatSsn = (ssn: string): string => ssn.replace(/\D/g, '')

const formats = {
  balance: formatBalance,
  date: formatDate,
  dayOrdinalNumbers: formatDayOrdinalNumbers,
  disbursementMethodDuration: formatDisbursementMethodDuration,
  dob: formatDOB,
  email: formatEmail,
  expiryDate: formatExpiryDate,
  last4Digit: formatLast4Digit,
  name: formatName,
  OS: formatOS,
  time: formatTime,
  string: {
    capitalizeFirstLetter,
  },
  wowSignUpDate: formatWowSignUpDate,
  phoneNumber: formatPhoneNumber,
  removeWhiteSpace,
  ssn: formatSsn,
}

export { formats }
