import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'next/router'
import BankLinkingModal from '@root/modules/BankLinkingModal'
import { bankApi, sessionStorageApi } from '@root/api'
import IBank, { IBankSummary } from '@root/types/Bank'
import { useBankStore, useUIStore } from '@root/store'
import { BVLinking } from '@root/components/BVLinking'
import { BankSource } from '@root/api/bankApi'
import useFinicity from './useFinicity'
import usePlaid from './usePlaid'

type IUseBankVerificationProps<BVLinkingType = 'modal' | 'page'> =
  BVLinkingType extends 'modal'
    ? {
        type: 'modal'
        source: BankSource
        onSuccess: () => void
        onExit: () => void
      }
    : {
        type: 'page'
        source: BankSource
        onSuccess: () => void
        onExit?: undefined
      }

type IUseBankVerificationReturn = {
  launch: (bank?: IBank | IBankSummary) => Promise<void>
  isLoading: boolean
  isLinking: boolean
  // Make sure to place this at your component if you would like to use the BVModal
  BankVerificationModal: () => JSX.Element
  BankVerificationPageComponent: () => JSX.Element
}

const useBankVerification = ({
  type,
  source,
  onSuccess,
  onExit,
}: IUseBankVerificationProps): IUseBankVerificationReturn => {
  const router = useRouter()

  const setShowNotificationModal = useUIStore(
    (state) => state.setShowNotificationModal
  )
  const setShowLottieLoader = useUIStore((state) => state.setShowLottieLoader)
  const getBankAccounts = useBankStore((state) => state.getBankAccounts)
  const pollBankStatus = useBankStore((state) => state.pollBankStatus)
  const pollBankStatusWithoutBankId = useBankStore(
    (state) => state.pollBankStatusWithoutBankId
  )
  const selectedBank = useBankStore((state) => state.selectedBank)
  const setSelectedBank = useBankStore((state) => state.setSelectedBank)

  const [plaidLinkToken, setPlaidLinkToken] = useState<string>('')
  const [finicityConnectUrl, setFinicityConnectUrl] = useState<string>('')
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isLinking, setIsLinking] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [bvMainSessionId] = useState<string>(uuidv4())
  const plaidOAuthId = (router.query.oauth_state_id as string) || ''

  const finicity = useFinicity({
    source,
    onExit: () => handleExit(),
    onSuccess: (bankId) => handleSuccess(bankId),
    setIsLinking: (val) => setLinkingState(val),
  })

  const plaid = usePlaid({
    source,
    onExit: () => handleExit(),
    onSuccess: (bankId) => handleSuccess(bankId),
    setIsLinking: (val) => setLinkingState(val),
  })

  const setLinkingState = (value: boolean): void => {
    if (value) {
      setIsOpenModal(false)
    }

    setIsLinking(value)
  }

  useEffect(() => {
    if (!finicityConnectUrl) {
      finicity.removeFinicityConnect()
    }

    if (!plaidLinkToken) {
      plaid.removePlaid()
    }

    return () => {
      finicity.removeFinicityConnect()
      plaid.removePlaid()
    }
  }, [plaidLinkToken, finicityConnectUrl])

  useEffect(() => {
    // launch bv on mount if it is a redirection from plaid oAuth web
    if (plaidOAuthId) {
      launch()
    }
  }, [])

  const resetToken = (): void => {
    setPlaidLinkToken('') // Making it null to unmount plaid.
    setFinicityConnectUrl('') // Making it empty string to unmount finicity.
  }

  const launch = async (bank?: IBank | IBankSummary): Promise<void> => {
    if (!bank && !plaidOAuthId) {
      // Calling launch without bank will open BV Modal
      type === 'modal' && setIsOpenModal(true)
      return
    }

    try {
      setIsLoading(true)

      if (type === 'modal') {
        setShowLottieLoader(isOpenModal)
      }

      resetToken()

      const bvSubSessionId = uuidv4()
      let plaidAttribute
      let isRelink = false
      let finicityAttribute

      if (bank) {
        sessionStorageApi.remove('plaidBank')
        sessionStorageApi.remove('plaidToken')

        const resp = await bankApi.initiateLinking({
          source,
          bvMainSession: bvMainSessionId,
          bvSubSession: bvSubSessionId,
          institutionProviderId: bank.institutionProviderId,
          redirectUri: window.location.href, //TODO: Ask for whitelisting production uri before release
        })

        plaidAttribute = resp.data.plaidAttribute
        isRelink = resp.data.isRelink
        finicityAttribute = resp.data.finicityAttribute
      }

      const isPlaidOAuthRedirection = Boolean(plaidOAuthId && !bank)

      if (plaidAttribute || isPlaidOAuthRedirection) {
        let token = ''
        let plaidBank = bank

        if (isPlaidOAuthRedirection) {
          // Get token and bank from session storage
          token = sessionStorageApi.get('plaidToken') || ''
          const getPlaidBank = sessionStorageApi.get('plaidBank')

          if (getPlaidBank) {
            plaidBank = JSON.parse(getPlaidBank)
          }
        } else if (plaidAttribute) {
          token = plaidAttribute.linkToken || ''
          sessionStorageApi.set('plaidToken', plaidAttribute.linkToken || '')
          sessionStorageApi.set('plaidBank', JSON.stringify(bank))
        }

        setPlaidLinkToken(token)

        plaid.launchPlaid({
          bank: plaidBank as IBank,
          bvMainSession: bvMainSessionId,
          bvSubSession: bvSubSessionId,
          token,
          isRelinkBank: isRelink,
          oAuthId: plaidOAuthId,
        })
      } else if (finicityAttribute) {
        setFinicityConnectUrl(finicityAttribute.connectUrl)

        finicity.launchFinicity({
          bank: bank as IBank,
          bvMainSession: bvMainSessionId,
          bvSubSession: bvSubSessionId,
          connectUrl: finicityAttribute.connectUrl,
          isRelinkBank: isRelink,
        })
      }

      if (type === 'modal') {
        setShowLottieLoader(false)
      }
    } catch {
      resetToken()
      setIsLoading(false)

      if (type === 'modal') {
        setShowLottieLoader(false)
      }
    }
  }

  const handleSuccess = async (bankId: string): Promise<void> => {
    setIsLinking(true)

    resetToken()

    if (type === 'page') {
      await pollBankStatusWithoutBankId(source, onSuccess).catch((err) => {
        // TODO: Will handle polling status error properly in the future
        console.error(err)
      })
      return
    }

    setIsOpenModal(false)

    const { data } = await getBankAccounts()
    const findBank = data.banks.find((bank) => bank.id === bankId)
    const selectedBank = { ...findBank }

    if (findBank) {
      await pollBankStatus(findBank, source).catch((err) => {
        // TODO: Will handle polling status error properly in the future
        console.error(err)
      })
    }

    setShowNotificationModal(true, {
      text: `Syncing in progress for your “${selectedBank?.name}” bank account`,
      autoHideDuration: 2500,
      icon: 'clock',
    })
    setIsLinking(false)
    onSuccess()
  }

  const handleExit = (): void => {
    resetToken()
    setIsLinking(false)
    setIsLoading(false)

    if (type === 'modal') {
      setShowLottieLoader(false)
      onExit()
    }
  }

  const renderBankVerificationModal = (): JSX.Element => {
    return (
      <BankLinkingModal
        source={source}
        isVisible={isOpenModal}
        onClickClose={() => {
          setIsOpenModal(false)
        }}
        onClickBank={launch}
      />
    )
  }

  const renderBankVerificationPageComponent = (): JSX.Element => (
    <BVLinking
      type="page"
      source={source}
      onClickBank={(bank) => setSelectedBank(bank)}
      onClickButton={launch}
      selectedBank={selectedBank}
      isLoading={isLoading}
    />
  )

  return {
    launch,
    isLoading,
    isLinking,
    BankVerificationModal: renderBankVerificationModal,
    BankVerificationPageComponent: renderBankVerificationPageComponent,
  }
}

export default useBankVerification
