export const SsnIncorrectFormatMessage =
  'Please make sure your Social Security Number is in the correct format.'

export const confirmedSsnIncorrectFormatMessage = `Your Social Security Number doesn't match the one above. So, we can’t confirm it. Please try again`

const ssnRegExp = /^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/

export const validateSsn = (ssn?: string): boolean => {
  if (!ssn) return false
  const cleanedSsn = ssn.replace(/-/g, '')
  if (/^[0-9]{0,9}$/.test(cleanedSsn) && ssnRegExp.test(cleanedSsn)) {
    return true
  }
  return false
}

export const validateSsnAndPrintError = (ssn?: string): string | undefined => {
  if (!validateSsn(ssn)) {
    return SsnIncorrectFormatMessage
  }
  return undefined
}

export const ssnValidationRules = [
  (value: string) => {
    const cleanedValue = value.replace(/-/g, '')
    return /^[0-9]{0,9}$/.test(cleanedValue)
  },
  (value: string) => {
    const cleanedValue = value.replace(/-/g, '')
    return ssnRegExp.test(cleanedValue) || SsnIncorrectFormatMessage
  },
]

export const confirmedSsnValidationRules = (
  ssn?: string
): ((value: string) => boolean | string)[] => [
  (value: string) => {
    return value.replace(/\D/g, '') === ssn?.replace(/\D/g, '')
      ? true
      : confirmedSsnIncorrectFormatMessage
  },
]
