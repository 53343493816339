import { Title, Select } from '@moneylion/mlds-web'
import { useBackdoorStore } from '@root/store'
import labels from './labels'

const BackdoorRoarMoney = (): JSX.Element => {
  const roarmoneyModal = useBackdoorStore((state) => state.roarmoneyModal)
  const setRoarmoneyModal = useBackdoorStore((state) => state.setRoarmoneyModal)

  const onChangeSelectRoarmoneyModal = (value: string): void => {
    setRoarmoneyModal(value !== 'none' ? value : undefined)
  }

  return (
    <>
      <div className="mb-3">
        <Title size="medium">{labels.title}</Title>
      </div>
      <Select
        className="mb-3"
        defaultValue={roarmoneyModal || 'none'}
        label={labels.rmModal.label}
        onChange={(value) => onChangeSelectRoarmoneyModal(value)}
      >
        {labels.rmModal.options.map((option, index) => (
          <Select.Option
            key={`roarmoney-modal-options-${index + 1}`}
            value={option.value}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export { BackdoorRoarMoney }
