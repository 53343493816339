import { useEffect } from 'react'
import { Button, Card, List, Body, Title } from '@moneylion/mlds-web'
import styled, { css, useTheme } from 'styled-components'
import Link from 'next/link'
import { useLogout } from '@root/hooks'
import {
  useAuthStore,
  useReferralsStore,
  useSubscriptionStore,
} from '@root/store'
import { MobileFooter } from '@root/modules/MobileFooter'
import { formats } from '@root/utils'
import { ProfileImage } from './components/ProfileImage'
import { PersonalInfo } from './modules/PersonalInfo'
import labels from './labels'

const StyledProfile = styled.div`
  ${({ theme }) => css`
    gap: 16px;

    @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
      padding: 0 16px;
    }
  `}
`

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const StyledReferralTag = styled(Body)`
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.mobile}) {
    text-align: center;
  }
`

const StyledLogout = styled(Card)`
  padding: 0;

  .ml-listItem {
    padding: 8px 16px;
  }

  .ml-listBullet {
    background: rgba(0, 0, 0, 0.06);
    padding: 8px 16px;
    margin: 0;
  }

  // get the next sibling of the bullet and remove the padding
  .ml-listBullet + div {
    padding: 0;
  }

  .ml-listBullet i {
    transform: rotate(90deg);
  }
`

const ProfilePage: React.FunctionComponent = () => {
  const theme = useTheme()

  const user = useAuthStore((state) => state.user)
  const referrals = useReferralsStore((state) => state.referrals)
  const status = useSubscriptionStore((state) => state.status)
  const getSubscriptionStatus = useSubscriptionStore(
    (state) => state.getSubscriptionStatus
  )
  const getReferrals = useReferralsStore((state) => state.getReferrals)

  const isVerified =
    status?.subscriptionType === 'WOW' &&
    status?.subscriptionStatus === 'active'

  const wowSignUpdate = status?.wowEarliestSignUpDate
    ? `WOW member since ${formats.wowSignUpDate(status?.wowEarliestSignUpDate)}`
    : 'WOW member'

  const logout = useLogout()

  useEffect(() => {
    if (!status) {
      getSubscriptionStatus()
    }
    if (!referrals) {
      getReferrals()
    }
  }, [])

  return (
    <StyledProfile className="profile-page flex flex-col">
      <ProfileImageContainer className="profile-intro">
        <ProfileImage
          style={
            user?.profilePicturePreviewUrl
              ? { opacity: '1.0' }
              : { opacity: '0.4' }
          }
          image={user?.profilePicturePreviewUrl}
          hasVerifiedLabel={isVerified}
          wowEarlistSignUpDate={wowSignUpdate}
        />
        <div className="flex flex-col jc-center ml-3">
          <Title size="large" className="capitalize">
            {user?.firstName} {user?.lastName}
          </Title>
          {referrals?.roarTag && (
            <StyledReferralTag
              size="large"
              color={theme.color?.CONTENT_SECONDARY}
            >
              {referrals?.roarTag}
            </StyledReferralTag>
          )}
        </div>
      </ProfileImageContainer>
      <div>
        <Link href="/referrals">
          <Button fullWidth>{labels.inviteFriends}</Button>
        </Link>
      </div>

      <PersonalInfo user={user} roarTag={referrals?.roarTag} />
      <StyledLogout className="hidden-desktop">
        <List>
          <List.Item bullet={{ icon: 'share' }} onClick={logout}>
            <List.Label main={labels.logOut} />
          </List.Item>
        </List>
      </StyledLogout>
      <MobileFooter />
    </StyledProfile>
  )
}

export { ProfilePage }
