import { useRouter } from 'next/router'
import { useAuthStore, useUIStore } from '@root/store'
import { UNPROTECTED_PAGES_PATHS } from '@root/constants'

const useLogout = () => {
  const logout = useAuthStore((state) => state.logout)
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)
  const router = useRouter()

  const handleLogout = (closeModal: () => void) => {
    logout()
    router.push(UNPROTECTED_PAGES_PATHS.logout)
    closeModal()
  }

  return () => {
    setShowAppDialog(true, {
      title: 'Are you sure you want to log out?',
      buttonDirection: 'row',
      isDismissable: true,
      buttons: [
        {
          label: 'Cancel',
          color: 'primary',
        },
        {
          label: 'Log out',
          color: 'secondary',
          onClick: handleLogout,
        },
      ],
    })
  }
}

export default useLogout
