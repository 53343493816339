import { useContext, useEffect } from 'react'
import { OnboardingHeaderContext } from '@root/layouts'
import { OnboardingHeaderProps } from '@onboarding/modules/OnboardingHeader/OnboardingHeader'

const useOnboardingHeader = (options: OnboardingHeaderProps): void => {
  const { setHeaderOptions } = useContext(OnboardingHeaderContext)

  useEffect(() => {
    setHeaderOptions({
      ...options,
    })
  }, [options])
}

export default useOnboardingHeader
