import React from 'react'
import { IconButton, Chip, Label, Body } from '@moneylion/mlds-web'
import styled, { useTheme } from 'styled-components'
import labels from './labels'

interface ProfileDataFieldProps {
  name: string
  content: React.ReactNode
  isPrivate?: boolean
  isVerified?: boolean
  className?: string
  label?: string
  toggleDialog?: () => void
}

const StyledChip = styled(Chip)`
  border-radius: 5px;
  padding: 10px 5px;
  background: ${({ theme }) => theme.colors?.['background-positive']};
  height: 16px;
`

const StyledContent = styled.div`
  gap: 6px;
`

const StyledButton = styled(IconButton)`
  border: 1px solid ${({ theme }) => theme.color?.CONTENT_DISABLED};
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
`

const ProfileDataField = (props: ProfileDataFieldProps): JSX.Element => {
  const { name, content, isVerified, className, label, toggleDialog } = props
  const theme = useTheme()

  return (
    <div id="profile-data-field" className={`${className} py-3`}>
      <StyledContent className="flex jc-between">
        <div>
          <div className="flex mb-1" style={{ gap: '6px', width: '100%' }}>
            <Body size="medium" color="rgba(0, 0, 0, 0.4)">
              {name}
              {label && ` • ${label}`}
            </Body>
            {isVerified && (
              <>
                <StyledChip>
                  <Label
                    color="#006C1E"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                  >
                    {labels.verified}
                  </Label>
                </StyledChip>
              </>
            )}
          </div>
          <Body color={theme.color?.CONTENT_SECONDARY}>{content}</Body>
        </div>
        {toggleDialog && (
          <StyledButton
            style={{ background: 'transparent' }}
            onClick={toggleDialog}
            aria-label="Edit profile"
            name="edit"
          />
        )}
      </StyledContent>
    </div>
  )
}

export { ProfileDataField }
