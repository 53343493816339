import { Icon } from '@moneylion/mlds-web'
import { useRouter } from 'next/router'
import { HeaderButtonContainer } from '../HeaderButtonContainer.styles'

interface BackButtonProps {
  onClickBack?: () => void
  withBackgroundColor?: boolean
  size?: number
}

const BackButton = ({
  onClickBack = undefined,
  withBackgroundColor = true,
  size = 24,
}: BackButtonProps): JSX.Element => {
  const router = useRouter()

  const handleClickBack = (): void => {
    if (typeof onClickBack === 'function') {
      onClickBack()
    } else {
      router.back()
    }
  }

  return (
    <HeaderButtonContainer
      onClick={handleClickBack}
      role="button"
      aria-label="back button"
      withBackgroundColor={withBackgroundColor}
    >
      <Icon size={size} name="arrowLeft" />
    </HeaderButtonContainer>
  )
}

export { BackButton }
