import shajs from 'sha.js'

interface ICard {
  number: string
  expiryDate: string
  cvv: string
}

/**
 * @usage: For the Add Card Flow
 * @description: To generate a list of card expiry month options
 * @param {chosenYear}: The year input chosen by the user
 * @returns {string[]}: Returns a list of card expiry month options
 */
const getCardExpiryMonthList = (chosenYear: string): string[] => {
  let incrementMonth = 0

  if (chosenYear) {
    const currentYear = new Date().getFullYear().toString().slice(-2)

    if (Number(chosenYear) === Number(currentYear)) {
      incrementMonth = new Date().getMonth()
    }
  }

  return [...Array(12 - incrementMonth)].map((_, index) => {
    const month = index + 1 + incrementMonth

    return month < 10 ? `0${month}` : month.toString()
  })
}

/**
 * @usage: For the Add Card Flow
 * @description: To generate a list of card expiry year options
 * @param {chosenMonth}: The month input chosen by the user
 * @returns {string[]}: Returns a list of card expiry year options
 */
const getCardExpiryYearList = (chosenMonth: string): string[] => {
  let incrementYear = 0

  if (chosenMonth) {
    const currentMonth = new Date().getMonth() + 1

    if (Number(chosenMonth) < currentMonth) {
      incrementYear = 1
    }
  }

  const currentYear = Number(new Date().getFullYear().toString().slice(-2))

  return [...Array(20 - incrementYear)].map((_, index) =>
    (currentYear + index + incrementYear).toString()
  )
}

const getHashCard = (card: ICard): string => {
  const { number, expiryDate, cvv } = card

  const algorithm = 'sha256'
  const encoding = 'hex'
  const expiryMonth = expiryDate.slice(0, 2)
  const expiryYear = expiryDate.slice(-2)

  const salt = shajs(algorithm)
    .update(expiryMonth + expiryYear + cvv)
    .digest(encoding)

  const cardHash = shajs(algorithm)
    .update(number + salt)
    .digest(encoding)

  return cardHash
}

export { getCardExpiryMonthList, getCardExpiryYearList, getHashCard }
