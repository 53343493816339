import { Input } from '@moneylion/mlds-web'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { IInputAddress } from '@root/types/Address'
import StyledInputAddress from './InputAddress.styles'
import labels from './labels'
import { InputAddressAutocomplete } from '@onboarding/components'

interface InputAddressProps {
  address?: IInputAddress
  setAddress: Dispatch<SetStateAction<IInputAddress | undefined>>
  isAutoFocus?: boolean
  shouldPrefill?: boolean
  shouldShowFullAddress?: boolean
}

const InputAddress = ({
  address,
  setAddress,
  isAutoFocus,
  shouldShowFullAddress,
}: InputAddressProps): JSX.Element => {
  const [showFullAddress, setShowFullAddress] = useState<boolean>(false)

  const onChangeAddressAutocomplete = (address: IInputAddress): void => {
    setAddress(address)
    setShowFullAddress(true)
  }

  const onChangeAddress = (value: IInputAddress): void => {
    setAddress({ ...address, ...value })
  }

  return (
    <StyledInputAddress>
      {!(shouldShowFullAddress || showFullAddress) ? (
        <InputAddressAutocomplete
          isAutoFocus={isAutoFocus}
          onChange={onChangeAddressAutocomplete}
        />
      ) : (
        <>
          <Input
            className="mb-4"
            data-testid="input_homeAddress"
            label={labels.streetAddress}
            onChange={(event) =>
              onChangeAddress({
                streetAddress: event.target.value,
              })
            }
            value={address?.streetAddress}
          />

          <Input
            className="mb-4"
            data-testid="input_apt"
            label={labels.apt}
            onChange={(event) => onChangeAddress({ apt: event.target.value })}
            value={address?.apt}
          />

          <Input
            className="mb-4"
            data-testid="input_city"
            label={labels.city}
            value={address?.city}
            onChange={(event) => onChangeAddress({ city: event.target.value })}
          />

          <div className="input-container mb-4">
            <Input
              className="input-left"
              data-testid="input_state"
              label={labels.state}
              value={address?.state}
              onChange={(event) =>
                onChangeAddress({ state: event.target.value })
              }
            />
            <Input
              className="input-right"
              data-testid="input_zip"
              label={labels.zip}
              value={address?.zip}
              onChange={(event) => onChangeAddress({ zip: event.target.value })}
            />
          </div>
        </>
      )}
    </StyledInputAddress>
  )
}

export { InputAddress }
