import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Chip, Icon } from '@moneylion/mlds-web'
import Image from 'next/image'
import Link from 'next/link'
import { analytics as analyticsUtils } from '@root/utils'
import { setupChatbot, useAuth } from '@root/hooks'
import { BackButton } from '@root/components/BackButton'

interface HeaderProps {
  onClickBack?: () => void
  showBackButton?: boolean
  faqLink?: string
  className?: string
  ctaButton?: {
    label: string
    onClick: () => void
  }
  isChatbotReady?: boolean
}

interface StyledHeaderProps {
  isBetween?: boolean
}

const Container = styled.div<StyledHeaderProps>`
  background-color: #ffffff;
  display: flex;
  width: 100%;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  justify-content: ${({ isBetween }) =>
    isBetween ? 'space-between' : 'center'};
  height: 80px;

  .cta-container {
    display: flex;
    shrink: 0;
    align-content: center;
    .icon-wrapper {
      background-color: ${({ theme }) =>
        theme.color?.BUTTON_SECONDARY_BACKGROUND};
      padding: 5px;
      border-radius: 100%;
      margin-right: 10px;
    }
  }

  #header-logo svg {
    width: 170px;
    height: 51px;
  }
`

const Header = (props: HeaderProps): JSX.Element => {
  const [hasInitChat, setHasInitChat] = useState(false)
  const { user, token } = useAuth()

  const {
    isChatbotReady,
    onClickBack = undefined,
    showBackButton = false,
    faqLink = '',
    ctaButton,
    className = '',
  } = props
  const theme = useTheme()

  const handleChatToggle = async () => {
    if (isChatbotReady && user && token && !hasInitChat) {
      setHasInitChat(true)
      await setupChatbot(user, token)
    }
    analyticsUtils.trackWithOSPlatformPrefilledBot('mobile-header')
    return adaEmbed.toggle()
  }

  return (
    <Container
      id="mobile-header"
      className={`py-3 px-3 ${className}`}
      isBetween={showBackButton || faqLink !== '' || !!ctaButton}
    >
      {showBackButton && <BackButton onClickBack={onClickBack} />}

      <Link
        href="/"
        id="header-logo"
        style={{ display: 'inline-flex' }}
        aria-label="MoneyLion logo - go to homepage"
      >
        <Image
          src="/assets/svgs/ml-logo.svg"
          alt="MoneyLion logo"
          width={170}
          height={51}
          priority
        />
      </Link>

      {(showBackButton || faqLink !== '' || ctaButton) && (
        <div style={{ width: 'auto' }}>
          {faqLink && (
            <a
              className="faq-link"
              href={faqLink}
              target="_blank"
              rel="noreferrer"
              aria-label="FAQ link"
            >
              <Icon size={24} name="help" />
            </a>
          )}
          {ctaButton && (
            <div className="cta-container">
              <button
                className="icon-wrapper"
                type="button"
                onClick={handleChatToggle}
                disabled={!isChatbotReady}
                aria-label="Chat with us"
              >
                <Icon name="chat" dark />
              </button>
              <Chip
                className="cta-btn"
                style={{ background: theme.color?.CONTENT_ACCENT }}
                onClick={ctaButton.onClick}
              >
                {ctaButton.label}
              </Chip>
            </div>
          )}
        </div>
      )}
    </Container>
  )
}

export { Header }
