const labels = {
  label: 'RoarMoney℠ benefits',
  title:
    'On top of all the checking account features that RoarMoney is offering, you will:',
  checkList: [
    'Unlock up to $1,000 Instacash*',
    'Get paid up to 2 days early**',
    'Pay less for Instacash Turbo***',
  ],
  disclaimerList: [
    'MoneyLion is a financial technology company, not a bank. RoarMoney℠ demand deposit account provided by, and MoneyLion Debit Mastercard® issued by, Pathward, National Association, Member FDIC. RoarMoney is a service mark of MoneyLion. Mastercard and the circles design are registered trademarks of Mastercard International Incorporated. Funds are FDIC insured, subject to applicable limitations and restrictions, when we receive the funds deposited to your account.',
    '* With recurring direct deposit into RoarMoney account. Instacash is subject to additional terms and eligibility requirements.',
    '** With direct deposit. Faster access to funds is based on comparison of a paper check versus electronic Direct Deposit.',
    '*** With disbursement to RoarMoney account versus external debit card.',
  ],
}

export default labels
