import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

export const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color?.BACKGROUND_OVERLAY_DARK};
  display: flex;
  align-items: center;
  opacity: 1;
  animation: 0.2s ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} ease-out;

  .modal-container {
    width: 100%;
    max-width: 496px;
    margin: auto;
    height: 100%;
    max-height: 610px;
    overflow: scroll;

    @media only screen and (max-width: 496px) {
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }
  }
`
