import { Body, Checkbox } from '@moneylion/mlds-web'
import React, { ChangeEventHandler } from 'react'
import { useTheme } from 'styled-components'
import { BankingActiveDocument, IEsign } from '@root/types/Esign'
import { LegalAgreementDocument } from '../LegalAgreementDocument'
import { StyledLegalAgreementCheckbox } from './LegalAgreementCheckbox.styles'

interface LegalAgreementCheckboxProps {
  agreements: BankingActiveDocument[] & IEsign[]
  label: string
  className?: string
  dataTestId?: string
  texts?: string[]
  withContainer?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const LegalAgreementCheckbox = ({
  agreements,
  label,
  className,
  dataTestId,
  texts,
  withContainer,
  onChange,
}: LegalAgreementCheckboxProps): JSX.Element => {
  const theme = useTheme()

  return (
    <StyledLegalAgreementCheckbox
      className={className}
      withContainer={withContainer}
    >
      <Checkbox
        data-testid={dataTestId}
        className="mr-3"
        color={theme.color?.CONTENT_PRIMARY}
        onChange={onChange}
      />
      <div>
        <Body size="medium" color="secondary">
          {label}
        </Body>
        {agreements?.map(({ name, id, link }) => {
          return (
            <LegalAgreementDocument
              id={id}
              key={`${name}-${id + 1}`}
              link={link}
              name={name}
            />
          )
        })}
        {!!texts?.length &&
          texts.map((text, id) => (
            <Body
              key={`agreement-text-${id + 1}`}
              className="pt-3"
              size="medium"
              color="secondary"
            >
              {text}
            </Body>
          ))}
      </div>
    </StyledLegalAgreementCheckbox>
  )
}

export { LegalAgreementCheckbox }
