import { create } from 'zustand'
import {
  INSTACASH_BVLINKING_NON_QUALIFIED_TEST_VALUES,
  INSTACASH_BVLINKING_QUALIFIED_TEST_VALUES,
  INSTACASH_BVLINKING_TRIAL_TIER_TEST_VALUES,
  CBPLUS_BVLINKING_TEST_VALUES,
} from '@root/constants/testValues'
import {
  TAddCardErrorLabelType,
  TOnboardStateRoarmoneyIfsType,
} from '@root/types/Error'

export interface CBPlusTestValues {
  bypassCheckUniqueLoanAndUserSsnCountByAccountNumberRule: boolean
  clientCountForBankAccount: number
  forceBvAuthFailure: boolean
  forceBvIdentityToThrowException: boolean
  forceExecuteLasLoTulScore: boolean
  forceInfoIdentityCheckSuccess: boolean
  highestAccountBalance: number
  highestEstimatedIncome: number
  isReturningCBPlusCustomer: boolean
  lasLoTulScore: number
  loanAppBadLoanCount: number
  loanAppUniqueSsnCount: number
  verificationIdDocumentStatus: string
}

export interface RoarMoneyTestValues {
  bvAccountDaysSinceRefresh: number
  clientCountForBankAccount: number
  loanAppBadLoanCount: number
  loanAppUniqueSsnCount: number
}

export type InstacashTestValuesType = 'qualified' | 'trialTier' | 'nonQualified'

export interface InstacashTestValues {
  accountAge: number
  authAccountNumber: string
  authRoutingNumber: string
  bvIndex5Score: string
  clientCountForBankAccount: number
  forceBvAuthFailure: boolean
  forceBvAuthSuccess: boolean
  forceInfoIdentityCheckSuccess: boolean
  forceWalletInfoIdentityCheckSuccess: boolean
  forceHasGiactReport: boolean
  giactAccountResponseCode: string
  lasic2Score: number
  loanAppBadLoanCount?: number
  loanAppUniqueSsnCount: number
  modelVariant: number
  pcd4IncomeActive: boolean
  scoreType: string
}

interface BackdoorInitialStore {
  shouldPrefill: boolean
  onboardStateRoarmoneyIfs?: TOnboardStateRoarmoneyIfsType
  isCbplusBvLinkingBackdoorEnabled: boolean
  cbplusBvLinkingTestValues: {
    testValues: CBPlusTestValues
    roarMoneyTestValues: RoarMoneyTestValues
  }
  isInstacashBvLinkingBackdoorEnabled: boolean
  instacashBvLinkingTestValuesType: InstacashTestValuesType
  instacashBvLinkingTestValues: {
    [key in InstacashTestValuesType]: InstacashTestValues
  }
  instacashAddCardModal?: TAddCardErrorLabelType
  roarmoneyModal?: string
}

interface BackdoorStore extends BackdoorInitialStore {
  setShouldPrefill: (shouldPrefill: boolean) => void
  setOnboardStateRoarmoneyIfs: (
    onboardStateRoarmoneyIfs?: TOnboardStateRoarmoneyIfsType
  ) => void
  setIsCbplusBvLinkingBackdoorEnabled: (isEnabled: boolean) => void
  setIsInstacashBvLinkingBackdoorEnabled: (isEnabled: boolean) => void
  setInstacashBvLinkingTestValuesType: (type: InstacashTestValuesType) => void
  setInstacashAddCardModal: (
    instacashAddCardModal?: TAddCardErrorLabelType
  ) => void
  setRoarmoneyModal: (roarmoneyModal?: string) => void
  reset: () => void
}

const initialState: BackdoorInitialStore = {
  shouldPrefill: false,
  onboardStateRoarmoneyIfs: undefined,
  isCbplusBvLinkingBackdoorEnabled: false,
  cbplusBvLinkingTestValues: CBPLUS_BVLINKING_TEST_VALUES,
  isInstacashBvLinkingBackdoorEnabled: false,
  instacashBvLinkingTestValuesType: 'qualified',
  instacashBvLinkingTestValues: {
    qualified: INSTACASH_BVLINKING_QUALIFIED_TEST_VALUES,
    trialTier: INSTACASH_BVLINKING_TRIAL_TIER_TEST_VALUES,
    nonQualified: INSTACASH_BVLINKING_NON_QUALIFIED_TEST_VALUES,
  },
  instacashAddCardModal: undefined,
  roarmoneyModal: undefined,
}

const useBackdoorStore = create<BackdoorStore>()((set) => ({
  ...initialState,
  setShouldPrefill: (shouldPrefill) => {
    set({ shouldPrefill })
  },
  setOnboardStateRoarmoneyIfs: (onboardStateRoarmoneyIfs) => {
    set({ onboardStateRoarmoneyIfs })
  },
  setIsCbplusBvLinkingBackdoorEnabled: (isEnabled) => {
    set({
      isCbplusBvLinkingBackdoorEnabled: isEnabled,
    })
  },
  setIsInstacashBvLinkingBackdoorEnabled: (isEnabled) => {
    set({
      isInstacashBvLinkingBackdoorEnabled: isEnabled,
    })
  },
  setInstacashBvLinkingTestValuesType: (type) => {
    set({
      instacashBvLinkingTestValuesType: type,
    })
  },
  setInstacashAddCardModal: (instacashAddCardModal) => {
    set({ instacashAddCardModal })
  },
  setRoarmoneyModal: (roarmoneyModal) => {
    set({ roarmoneyModal })
  },
  reset: () => {
    set(initialState)
  },
}))

export { useBackdoorStore }
