import { useEffect } from 'react'

const generateUniqueDeviceId = async (): Promise<string> => {
  let fingerprintId = ''
  if (fingerprintId) {
    return fingerprintId
  }

  const ClientJS = await import('clientjs').then((mod) => mod.ClientJS)

  const client = new ClientJS()

  fingerprintId = client
    .getCustomFingerprint(
      client.getUserAgent(),
      client.getPlugins(),
      client.getFonts(),
      String(client.isLocalStorage()),
      String(client.isSessionStorage()),
      client.getTimeZone(),
      client.getLanguage(),
      client.getSystemLanguage(),
      String(client.isCookie()),
      client.getCanvasPrint()
    )
    .toString()

  return fingerprintId
}

const screenWidth = typeof window !== 'undefined' ? window.screen.width : 0
const isDesktopScreen = screenWidth > 1024
const isTabletScreen = screenWidth > 480 && screenWidth < 1024

const tabCloseEventTrigger = (controller: AbortController) => {
  if (typeof window === 'undefined') {
    return
  }
  return window.addEventListener('pagehide', (event) => {
    event.preventDefault()
    controller.abort('User closed the page')
  })
}

const createAbortController = () => {
  const controller = new AbortController()
  const { signal } = controller
  return { signal, controller }
}

function windowAnalyticsObjectExists(): boolean {
  return !(typeof window === 'undefined' || !window.analytics)
}

function useCloseModalEventListener(callback: () => void): void {
  const eventListenerHandler = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      event.preventDefault()
      return callback()
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', (event) => eventListenerHandler(event))
    }
    return () => window.removeEventListener('keydown', () => null)
  })
}
export {
  generateUniqueDeviceId,
  isDesktopScreen,
  isTabletScreen,
  tabCloseEventTrigger,
  createAbortController,
  windowAnalyticsObjectExists,
  useCloseModalEventListener,
}
