import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface OnboardingInitialState {
  isIntentFlow?: boolean
}

export interface OnboardingState extends OnboardingInitialState {
  setIsIntentFlow: (isIntentFlow: boolean) => void
  reset: () => void
}

const initialState: OnboardingInitialState = {
  isIntentFlow: undefined,
}

const useOnboardingStore = create<OnboardingState>()(
  persist(
    (set) => ({
      ...initialState,
      setIsIntentFlow: (isIntentFlow) => {
        set({ isIntentFlow })
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        isIntentFlow: state.isIntentFlow,
      }),
      name: 'onboardingStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useOnboardingStore }
