import { List } from '@moneylion/mlds-web'
import { useTheme } from 'styled-components'
import { WhiteRoundedContainer } from '@onboarding/components'

interface NumberListProps {
  list: string[]
  divider?: 'full' | 'none'
}

const NumberList = ({ list, divider }: NumberListProps): JSX.Element => {
  const theme = useTheme()
  return (
    <WhiteRoundedContainer id="number-list" className="mb-3">
      <List>
        {list.map((item, index) => (
          <List.Item
            key={`number-list-item-${item}`}
            divider={divider}
            disabledEffects={['hover', 'active', 'focus', 'visited']}
            bullet={{
              size: 'large',
              text: String(index + 1),
              color: theme.color?.BACKGROUND_CONTENT_ACCENT,
            }}
          >
            <List.Label main={item} />
          </List.Item>
        ))}
      </List>
    </WhiteRoundedContainer>
  )
}

export { NumberList }
