import { create } from 'zustand'
import {
  appsFlyerApi,
  lifecycleApi,
  segmentApi,
  subscriptionApi,
} from '@root/api'
import { VerifyIDVRes } from '@root/types'
import ISubscriptionStatus, {
  InstacashFlowType,
} from '@root/types/Subscription'
import { useAuthStore } from './authStore'
import { useEsignStore } from './esignStore'
import { useInstacashStore } from './instacashStore'
import { useOnboardingStore } from './onboardingStore'
import { useUserStore } from './userStore'

interface SubscribeInstacashPayload {
  icTrialTier: boolean
  flow: InstacashFlowType
  shouldPassMandatoryEsign: boolean
}

interface SubscriptionInitialState {
  status: ISubscriptionStatus | null
  loaded: boolean
}

interface SubscriptionState extends SubscriptionInitialState {
  getSubscriptionStatus: () => Promise<ISubscriptionStatus | undefined>
  createLiteSubscription: (abortSignal?: AbortSignal) => Promise<boolean>
  verifyIdv: () => Promise<VerifyIDVRes['data']>
  subscribeInstacash: (payload: SubscribeInstacashPayload) => Promise<boolean>
  reset: () => void
}

const initialState: SubscriptionInitialState = {
  status: null,
  loaded: false,
}

const useSubscriptionStore = create<SubscriptionState>()((set) => ({
  ...initialState,
  getSubscriptionStatus: async () => {
    const status = await subscriptionApi.getSubscriptionStatus()
    set({ status })
    return status
  },
  createLiteSubscription: async (abortSignal) => {
    const { data } = await subscriptionApi.createMLLite(abortSignal)
    return data.status === 'SUCCESS'
  },
  verifyIdv: async () => {
    const user = useAuthStore.getState().user

    if (
      !user ||
      !user.addresses ||
      !user.addresses.length ||
      !user.addresses[0] ||
      !user.addresses[0].id
    ) {
      throw new Error('INVALID ADDRESS ID')
    }

    const addressId = user.addresses[0].id

    const { data } = await lifecycleApi.verifyIdv({ addressId })

    if (data && data.idvStatus) {
      segmentApi.trackWithOSPlatform('Identity Verification Checked', {
        Result: data.idvStatus,
      })
    }

    return data
  },
  subscribeInstacash: async ({
    icTrialTier,
    flow,
    shouldPassMandatoryEsign,
  }: SubscribeInstacashPayload) => {
    const isIntentFlow = useOnboardingStore.getState().isIntentFlow
    const tier = icTrialTier ? 'TRIAL' : 'ICwB'
    const referrer = isIntentFlow ? 'intent' : 'organic'

    try {
      const leadGenTransactionId = useUserStore.getState().leadGenTransactionId

      const underwritingTransactionId =
        useInstacashStore.getState().underwritingTransactionId

      const subscriptionEsignDocuments =
        useEsignStore.getState().subscriptionEsignDocuments

      const subscriptionOptionalEsignDocuments =
        useEsignStore.getState().subscriptionOptionalEsignDocuments

      const hasSignOptionalEsignDocuments =
        useEsignStore.getState().hasSignOptionalEsignDocuments

      const signBackupAuthDocumentIds = hasSignOptionalEsignDocuments
        ? subscriptionOptionalEsignDocuments?.map((each) => each.id)
        : []

      const signDocumentIds = subscriptionEsignDocuments?.map((each) => each.id)

      if (!underwritingTransactionId) throw new Error('INVALID UNDERWRITING ID')

      const { data } = await subscriptionApi.subscribeInstacash({
        flow,
        leadGenTransactionId,
        underwritingTransactionId,
        signBackupAuthDocumentIds,
        signDocumentIds: shouldPassMandatoryEsign ? signDocumentIds : undefined,
        icTrialTier,
      })

      if (!data) return false

      const isSuccess = [
        'ADDON_SUCCESS',
        'ACTIVE_SUSPENDED_ADDON_FOUND',
      ].includes(data.status)

      if (isSuccess) {
        segmentApi.trackWithOSPlatform('ca_account_create_submit', {
          status: 'success',
          tier,
          referrer,
        })
      }

      if (isSuccess && !icTrialTier) {
        segmentApi.trackWithOSPlatform('IC Membership Created')
        appsFlyerApi.event('IC Membership Created')
      }

      return isSuccess
    } catch (error: any) {
      if (error?.message === 'TIMEOUT') {
        segmentApi.trackWithOSPlatform('ca_account_create_submit', {
          status: 'timeout',
          tier,
          referrer,
        })
      }

      segmentApi.trackWithOSPlatform('ca_account_create_submit', {
        status: 'fail',
        tier,
        fail_reason: !Number.isInteger(error?.data?.status)
          ? error.data.status
          : error?.data?.message, // INSTA_CASH_DOCUMENT_NOT_SIGNED, ACTIVE_SUSPENDED_ADDON_FOUND
        referrer,
      })

      return Promise.reject(error)
    }
  },
  reset: () => {
    set(initialState)
  },
}))

export { useSubscriptionStore }
