import { useRef } from 'react'
import { IconButton } from '@moneylion/mlds-web'
import { useTheme } from 'styled-components'
import Image from 'next/image'
import { useOnClickOutside } from 'usehooks-ts'
import { Overlay } from '@root/modules/BankLinkingModal/BankLinkingModal.styles'
import { useCloseModalEventListener } from '@root/helpers'
import {
  AppDownloadWrapper,
  DownloadAppContent,
  DownloadAppDescription,
  DownloadAppTitle,
  StyledDescription,
  StyledDialog,
  StyledDialogTitle,
} from './DownloadAppModal.styles'
import { labels } from './labels'

export interface DownloadAppModalProps {
  isOpen: boolean
  toggle: () => void
}

const DownloadAppModal = (props: DownloadAppModalProps): JSX.Element | null => {
  const { isOpen, toggle } = props
  const theme = useTheme()
  const dialogRef = useRef(null)
  useCloseModalEventListener(toggle)

  const handleClickOutside = (): void => {
    toggle()
  }

  useOnClickOutside(dialogRef, handleClickOutside)

  if (!isOpen) return null

  return (
    <Overlay isVisible={isOpen} style={{ padding: '0 12px' }}>
      <StyledDialog ref={dialogRef}>
        <>
          <div style={{ position: 'absolute', right: 16, top: 16 }}>
            <IconButton
              name="close"
              onClick={toggle}
              style={{
                backgroundColor: theme.colors?.['background-primary'],
              }}
            />
          </div>
          <StyledDialogTitle text={labels.title} />
          <StyledDescription
            size="medium"
            color={theme.color?.CONTENT_SECONDARY}
          >
            {labels.securityInfo}
          </StyledDescription>
          <DownloadAppContent>
            <Image
              src="/assets/svgs/edit_profile_qr_code.svg"
              height={120}
              width={120}
              alt="QR code"
            />
            <div className="flex flex-col jc-center">
              <DownloadAppTitle color={theme.color?.CONTENT_PRIMARY}>
                {labels.downloadTheApp}
              </DownloadAppTitle>
              <DownloadAppDescription color={theme.color?.CONTENT_SECONDARY}>
                {labels.scanCode}
              </DownloadAppDescription>
            </div>
          </DownloadAppContent>
          <AppDownloadWrapper id="app-downloads">
            <a
              href="https://itunes.apple.com/us/app/moneylion-personal-finance-credit-score-loans/id1064677082?mt=8"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                alt="MoneyLion on App Store"
                src="/assets/svgs/app-store.svg"
                width={120}
                height={40}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.moneylion"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                alt="MoneyLion on Play Store"
                src="/assets/svgs/play-store.svg"
                width={120}
                height={40}
              />
            </a>
          </AppDownloadWrapper>
        </>
      </StyledDialog>
    </Overlay>
  )
}

export { DownloadAppModal }
