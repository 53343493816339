import styled, { css } from 'styled-components'

const headerHeight = 76
const layoutMarginBottomDesktop = 80
const layoutMarginTopDesktop = 32
const layoutMarginBottomMobile = 40
const layoutMarginTopMobile = 20
const layoutMarginXMobile = 16
const stepperHeightMobile = 48

export const OnboardingBody = styled.div<{ maxWidth: string }>`
  margin: ${layoutMarginTopDesktop}px auto ${layoutMarginBottomDesktop}px;
  max-width: ${({ maxWidth }) => maxWidth};
  min-height: calc(
    100vh -
      ${headerHeight + layoutMarginTopDesktop + layoutMarginBottomDesktop}px
  );
  width: 100%;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
      min-height: calc(
        100vh -
          ${headerHeight +
          stepperHeightMobile +
          layoutMarginTopDesktop +
          layoutMarginBottomDesktop}px
      );
    }
  `}

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
      margin: ${layoutMarginTopMobile}px ${layoutMarginXMobile}px
        ${layoutMarginBottomMobile}px;
      min-height: calc(
        100vh -
          ${headerHeight +
          stepperHeightMobile +
          layoutMarginTopMobile +
          layoutMarginBottomMobile}px
      );
      width: calc(100% - ${layoutMarginXMobile * 2}px);
    }
  `}
`
