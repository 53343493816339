import styled from 'styled-components'

const StyledInputAddress = styled.div`
  .input-container {
    display: flex;

    .input-left {
      margin-right: 12px;
      width: 100%;
    }

    .input-right {
      width: 100%;
    }
  }
`

export default StyledInputAddress
