import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { cashAdvanceApi, subscriptionApi } from '@root/api'
import {
  IDisbursementDetail,
  IDisbursementMethod,
  TCashAdvanceAgreements,
} from '@root/types/CashAdvance'
import CashAdvanceInfo from '@root/types/CashAdvanceInfo'
import { InstacashFlowType } from '@root/types/Subscription'
import { useBackdoorStore } from './backdoorStore'

interface IInstacash {
  eligible: boolean
  payableAmount: number
  expectedPaymentAmount: number
  eligibleAvailableAmount: number
}

interface IApplicationIdAndEsign {
  applicationId?: string
  esign?: TCashAdvanceAgreements[]
}

interface InstacashInitialState {
  instacash?: IInstacash | null
  applicationId?: string
  instacashEsign?: TCashAdvanceAgreements[]
  disbursementDetail: IDisbursementDetail
  disbursementMethods?: IDisbursementMethod[]
  underwritingTransactionId?: string
  isInstacashRoarMoneyUser?: boolean
}

export interface InstacashState extends InstacashInitialState {
  getInstacashDetails: () => Promise<CashAdvanceInfo | undefined>
  getInstacashApplicationIdAndEsign: () => Promise<IApplicationIdAndEsign>
  setDisbursementDetail: (disbursementDetail: IDisbursementDetail) => void
  setDisbursementMethods: (disbursementMethods: IDisbursementMethod[]) => void
  checkInstacashEligibility: (
    flow: InstacashFlowType,
    isIntent: boolean
  ) => Promise<string>
  setIsInstacashRoarMoneyUser: (isInstacashRoarMoneyUser: boolean) => void
  reset: () => void
}

const initialState: InstacashInitialState = {
  instacash: undefined,
  applicationId: undefined,
  instacashEsign: undefined,
  disbursementDetail: {
    amount: undefined,
    method: undefined,
    tip: undefined,
  },
  disbursementMethods: undefined,
  underwritingTransactionId: undefined,
  isInstacashRoarMoneyUser: undefined,
}

const useInstacashStore = create<InstacashState>()(
  persist(
    (set, getState) => ({
      ...initialState,
      getInstacashDetails: async () => {
        const resp = await cashAdvanceApi.getCashAdvanceInfo()

        if (resp.data) {
          set({
            instacash: {
              eligible: resp?.data.eligible || false,
              expectedPaymentAmount: resp?.data.expectedPaymentAmount || 0,
              payableAmount: resp?.data.payableAmount || 0,
              eligibleAvailableAmount: resp?.data.eligibleAmount.available || 0,
            },
          })
        }

        return resp.data
      },
      getInstacashApplicationIdAndEsign: async () => {
        const state = getState()

        if (!state.applicationId || !state.instacashEsign) {
          const { data } = await cashAdvanceApi.postCreateAppAndLinkEsign()

          set({
            applicationId: data.id,
            instacashEsign: data.esignDocuments,
          })

          return {
            application: data.id,
            instacashEsign: data.esignDocuments,
          } as IApplicationIdAndEsign
        }

        return {
          application: state.applicationId,
          instacashEsign: state.instacashEsign,
        } as IApplicationIdAndEsign
      },
      setDisbursementDetail: (disbursementDetails) => {
        const state = getState()

        set({
          disbursementDetail: {
            ...state.disbursementDetail,
            ...disbursementDetails,
          },
        })
      },
      setDisbursementMethods: (disbursementMethods) => {
        set({ disbursementMethods })
      },
      checkInstacashEligibility: async (
        flow: InstacashFlowType,
        isIntent: boolean
      ) => {
        const {
          isInstacashBvLinkingBackdoorEnabled,
          instacashBvLinkingTestValuesType,
          instacashBvLinkingTestValues,
        } = useBackdoorStore.getState()

        const payload = {
          testValues: isInstacashBvLinkingBackdoorEnabled
            ? instacashBvLinkingTestValues[instacashBvLinkingTestValuesType]
            : undefined,
          flow,
        }

        let res

        if (isIntent) {
          res = await subscriptionApi.postInstacashIntentEligibleBv(payload)
        } else {
          res = await subscriptionApi.postInstacashEligibleBv(payload)
        }

        const { status, underwritingTransactionId } = res.data

        set({
          underwritingTransactionId: underwritingTransactionId,
        })

        return status
      },
      setIsInstacashRoarMoneyUser: (isInstacashRoarMoneyUser) => {
        set({ isInstacashRoarMoneyUser })
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        applicationId: state.applicationId,
        instacashEsign: state.instacashEsign,
        disbursementDetail: state.disbursementDetail,
        underwritingTransactionId: state.underwritingTransactionId,
      }),
      name: 'instacashStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useInstacashStore }
