import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { Card } from '@root/types/Wallet'

interface WalletInitialState {
  card?: Card
}

export interface WalletState extends WalletInitialState {
  setCard: (card: Card) => void
  resetCard: () => void
  reset: () => void
}

const initialState: WalletInitialState = {
  card: undefined,
}

const useWalletStore = create<WalletState>()(
  persist(
    (set, getState) => ({
      ...initialState,
      setCard: (newCard: Card) => {
        const state = getState()
        set({
          card: {
            ...state.card,
            ...newCard,
          },
        })
      },
      resetCard: () => {
        set({ card: undefined })
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        card: state.card,
      }),
      name: 'walletStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useWalletStore }
