import { useState } from 'react'
import { useRouter } from 'next/router'
import { cashAdvanceApi, lifecycleApi } from '@root/api'
import { PROTECTED_PAGES_PATHS } from '@root/constants'
import { useInstacashStore, useUIStore } from '@root/store'
import { goToDownloadApp } from '@root/utils'

type InstacashFlowType = 'addCard' | 'instacashDisbursement'

const useInstacashDisbursement = (): {
  canEnterAddCardFlow: boolean
  canEnterDisbursementFlow: boolean
  checkAddCardRequirements: () => Promise<void>
  checkDisbursementRequirements: () => Promise<void>
} => {
  const router = useRouter()
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)
  const isInstacashRoarMoneyUser = useInstacashStore(
    (state) => state.isInstacashRoarMoneyUser
  )

  const [canEnterAddCardFlow, setCanEnterAddCardFlow] = useState<boolean>(false)
  const [canEnterDisbursementFlow, setCanEnterDisbursementFlow] =
    useState<boolean>(false)

  const checkRecentCashAdvance = async (
    type: InstacashFlowType
  ): Promise<void> => {
    try {
      const { data } = await cashAdvanceApi.getRecentCashAdvance()

      if (data?.transactions?.length !== 0) {
        if (type === 'addCard') {
          setCanEnterAddCardFlow(false)
          getApp()
          return Promise.reject('ADD_CARD_UNAUTHORIZE')
        }

        setCanEnterDisbursementFlow(false)
        getApp()
        return Promise.reject('DISBURSEMENT_UNAUTHORIZE')
      }
    } catch {
      if (type === 'addCard') {
        setCanEnterAddCardFlow(false)
        setShowAppDialog(true)
        return Promise.reject('ADD_CARD_UNAUTHORIZE')
      }

      setCanEnterDisbursementFlow(false)
      setShowAppDialog(true)
      return Promise.reject('DISBURSEMENT_UNAUTHORIZE')
    }
  }

  const checkRepaymentMethod = async (
    type: InstacashFlowType
  ): Promise<void> => {
    try {
      const { data } = await cashAdvanceApi.getRepaymentMethods()
      const filteredCards = data.filter((card) => card.type === 'DEBIT_CARD')

      if (type === 'addCard' && filteredCards.length !== 0) {
        setCanEnterAddCardFlow(false)

        router.push(PROTECTED_PAGES_PATHS.instacashDisbursementActivation)
        return Promise.reject('ADD_CARD_UNAUTHORIZE')
      } else if (
        type === 'instacashDisbursement' &&
        filteredCards.length === 0
      ) {
        setCanEnterDisbursementFlow(false)

        router.push(PROTECTED_PAGES_PATHS.instacashAddCardStart)
        return Promise.reject('DISBURSEMENT_UNAUTHORIZE')
      }
    } catch {
      if (type === 'addCard') {
        setCanEnterAddCardFlow(false)
        setShowAppDialog(true)
        return Promise.reject('ADD_CARD_UNAUTHORIZE')
      }

      setCanEnterDisbursementFlow(false)
      setShowAppDialog(true)
      return Promise.reject('DISBURSEMENT_UNAUTHORIZE')
    }
  }

  const checkOnboardState = async (): Promise<void> => {
    try {
      const { data } = await lifecycleApi.checkOnboardState({
        productName: 'INSTACASH',
      })

      if (data?.stateName !== 'ONBOARDED') {
        setCanEnterAddCardFlow(false)

        router.push(PROTECTED_PAGES_PATHS.instacashBVPreroll)
        return Promise.reject('ADD_CARD_UNAUTHORIZE')
      } else if (data?.prerequisite.length !== 0) {
        setCanEnterAddCardFlow(false)

        router.push(PROTECTED_PAGES_PATHS.instacashDobAddress)
        return Promise.reject('ADD_CARD_UNAUTHORIZE')
      }
    } catch {
      setCanEnterAddCardFlow(false)
      setShowAppDialog(true)
      return Promise.reject('ADD_CARD_UNAUTHORIZE')
    }
  }

  const checkCashAdvanceEligible = async (): Promise<void> => {
    try {
      const { data } = await cashAdvanceApi.getCashAdvanceInfo()

      if (data?.eligible && data?.eligibleAmount?.available > 0) {
        if (!data?.eligibilityBreakdown?.IS_DIRECT_DEPOSIT_CURRENTLY_LINKED) {
          setCanEnterAddCardFlow(true)
        } else {
          setCanEnterAddCardFlow(false)

          router.push(PROTECTED_PAGES_PATHS.instacashDisbursementActivation)
          return Promise.reject('ADD_CARD_UNAUTHORIZE')
        }
      } else {
        setCanEnterAddCardFlow(false)
        getApp()
        return Promise.reject('ADD_CARD_UNAUTHORIZE')
      }
    } catch {
      setCanEnterAddCardFlow(false)
      setShowAppDialog(true)
      return Promise.reject('ADD_CARD_UNAUTHORIZE')
    }
  }

  const getApp = (): void => {
    if (isInstacashRoarMoneyUser) {
      goToDownloadApp('instacashRoarmoney')
    } else {
      goToDownloadApp('instacash')
    }
  }

  const checkAddCardRequirements = async (): Promise<void> => {
    try {
      const type = 'addCard'

      await checkRecentCashAdvance(type)
      await checkRepaymentMethod(type)
      await checkOnboardState()
      await checkCashAdvanceEligible()
    } catch {
      setCanEnterAddCardFlow(false)
    }
  }

  const checkDisbursementRequirements = async (): Promise<void> => {
    try {
      const type = 'instacashDisbursement'

      await checkRecentCashAdvance(type)
      await checkRepaymentMethod(type)
      setCanEnterDisbursementFlow(true)
    } catch {
      setCanEnterDisbursementFlow(false)
    }
  }

  return {
    canEnterAddCardFlow,
    canEnterDisbursementFlow,
    checkAddCardRequirements,
    checkDisbursementRequirements,
  }
}

export { useInstacashDisbursement }
