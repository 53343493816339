import { segmentApi } from '@root/api'
import IContent from '@root/types/Content'
import { CardsType } from '@root/types/Marketplace'
import { productNames as PRODUCT_NAME } from './constants'

export const trackingConfigObject = {
  element_key: 'n/a',
  element: 'webdash-offer-tile',
  location: 'webdash-offers',
  product: 'n/a',
  category: 'n/a',
  topic: 'n/a',
}

const trackWithOSPlatformPrefilledView = (
  eventName: string,
  content: IContent | CardsType
) => {
  segmentApi.trackWithOSPlatform(eventName, {
    ...trackingConfigObject,
    index: content.id,
    title: content.title,
    copy: content.copy,
    partner: content.title,
    interaction: 'viewed',
  })
}

const trackWithOSPlatformPrefilledClicked = (
  eventName: string,
  content: IContent | CardsType
) => {
  segmentApi.trackWithOSPlatform(eventName, {
    ...trackingConfigObject,
    index: content.id,
    title: content.title,
    copy: content.copy,
    partner: content.title,
    interaction: 'engaged',
  })
}

// used with accounts page product card
const trackWithOSPlatformPrefilledAccounts = (product: string) => {
  segmentApi.trackWithOSPlatform('pfm_accounts_account_click', {
    product: PRODUCT_NAME[product],
  })
}

// used with accounts page product card
const trackWithOSPlatformPrefilledProfile = (element: string) => {
  segmentApi.trackWithOSPlatform('webdash_modal_open', {
    content: 'profile-edit-app-download',
    element,
  })
}

// used with mobile header component
const trackWithOSPlatformPrefilledMobile = () => {
  segmentApi.trackWithOSPlatform('webdash_getapp_click', {
    element: 'mobile-header',
  })
}

// used with mobile chat-bot button component
const trackWithOSPlatformPrefilledBot = (element: string) => {
  segmentApi.trackWithOSPlatform('webdash_chatbot_click', {
    element,
  })
}

const trackWithOSPlatformPrefilledReferrals = () => {
  segmentApi.trackWithOSPlatform('ref_main_webdash_referralskit_engage', {})
}

// used with advertiser disclouser modal in the offer's page
const trackWithOSPlatformPrefilledAdvertiserDisclosure = () => {
  segmentApi.trackWithOSPlatform('webdash_modal_open', {
    content: 'advertiser-disclosure',
    element: 'offers-footer',
  })
}

const analytics = {
  trackWithOSPlatformPrefilledClicked,
  trackWithOSPlatformPrefilledView,
  trackWithOSPlatformPrefilledAccounts,
  trackWithOSPlatformPrefilledProfile,
  trackWithOSPlatformPrefilledMobile,
  trackWithOSPlatformPrefilledBot,
  trackWithOSPlatformPrefilledReferrals,
  trackWithOSPlatformPrefilledAdvertiserDisclosure,
}

export { analytics }
