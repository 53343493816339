import { Title, Body, Select, Switch } from '@moneylion/mlds-web'
import { ADD_CARD_FAILED_LABEL } from '@root/constants'
import { useBackdoorStore, InstacashTestValuesType } from '@root/store'
import { TAddCardModalBackdoorLabelType } from '@root/types/Error'
import labels from './labels'

const instacashTestValuesTypes: InstacashTestValuesType[] = [
  'qualified',
  'trialTier',
  'nonQualified',
]

const BackdoorInstacash = (): JSX.Element => {
  const isInstacashBvLinkingBackdoorEnabled = useBackdoorStore(
    (state) => state.isInstacashBvLinkingBackdoorEnabled
  )
  const setIsInstacashBvLinkingBackdoorEnabled = useBackdoorStore(
    (state) => state.setIsInstacashBvLinkingBackdoorEnabled
  )
  const instacashBvLinkingTestValuesType = useBackdoorStore(
    (state) => state.instacashBvLinkingTestValuesType
  )
  const setInstacashBvLinkingTestValuesType = useBackdoorStore(
    (state) => state.setInstacashBvLinkingTestValuesType
  )
  const instacashAddCardModal = useBackdoorStore(
    (state) => state.instacashAddCardModal
  )
  const setInstacashAddCardModal = useBackdoorStore(
    (state) => state.setInstacashAddCardModal
  )

  const onChangeSelectIcAddCardModal = (
    value: TAddCardModalBackdoorLabelType
  ): void => {
    setInstacashAddCardModal(value !== 'none' ? value : undefined)
  }

  return (
    <>
      <div className="mb-3">
        <Title size="medium">{labels.title}</Title>
      </div>

      <div className="flex ai-center mb-3">
        <Switch
          value={isInstacashBvLinkingBackdoorEnabled}
          onChange={(e) => {
            setIsInstacashBvLinkingBackdoorEnabled(e.target.checked)
          }}
        />
        <Body size="medium">
          {labels.icBvLinking}{' '}
          {isInstacashBvLinkingBackdoorEnabled ? '(On)' : '(Off)'}
        </Body>
      </div>
      {isInstacashBvLinkingBackdoorEnabled && (
        <div className="ml-7 mb-8">
          <Select
            label="IC Flow type"
            defaultValue={instacashBvLinkingTestValuesType}
            onChange={(value) =>
              setInstacashBvLinkingTestValuesType(
                value as InstacashTestValuesType
              )
            }
          >
            {instacashTestValuesTypes.map(
              (item: InstacashTestValuesType, index) => (
                <Select.Option
                  key={`backdoor-ic-bvlinking-${index}`}
                  value={item}
                >
                  {item}
                </Select.Option>
              )
            )}
          </Select>
        </div>
      )}

      <Select
        className="mb-3"
        defaultValue={instacashAddCardModal || 'none'}
        label={labels.icAddCardModal}
        onChange={(value) =>
          onChangeSelectIcAddCardModal(value as TAddCardModalBackdoorLabelType)
        }
      >
        {['none', ...Object.keys(ADD_CARD_FAILED_LABEL)].map(
          (option, index) => (
            <Select.Option
              key={`instacash-add-card-modal-options-${index + 1}`}
              value={option}
            >
              {option}
            </Select.Option>
          )
        )}
      </Select>
    </>
  )
}

export { BackdoorInstacash }
