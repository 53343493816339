const labels = {
  title: 'MoneyLion works best on mobile',
  securityInfo:
    'For enhanced security, this feature is only available on the MoneyLion app.',
  downloadTheApp: 'Download the app',
  scanCode:
    "Scan this code with your phone's camera and follow the instructions.",
}

export { labels }
