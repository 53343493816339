import { https } from '@root/utils'

interface LeadGetUserInfoReq {
  leadUuid: string
  offerId: string
  utmSource: string
}

interface LeadGetUserInfoRes {
  data: {
    personalInformation?: {
      address1?: string
      address2?: string
      city?: string
      dateOfBirth?: string
      email?: string
      firstName?: string
      lastName?: string
      primaryPhone?: string
      ssn?: string
      state?: string
      zipcode?: string
    }
  }
}

const baseUrl = '/bidding'

const leadGetUserInfo = async (
  payload: LeadGetUserInfoReq,
  signal?: AbortSignal
): Promise<LeadGetUserInfoRes> => {
  const response = await (
    await https({ signal })
  ).post(`${baseUrl}/v1/leadGetUserInfo`, payload)
  return response.data
}

const biddingApi = {
  leadGetUserInfo,
}

export default biddingApi
