import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import IBank, { IBankSummary } from '@root/types/Bank'
import bankApi, { BankSource, IFetchBankAccountsResp } from '@root/api/bankApi'
import { useUIStore } from './uiStore'

interface LinkedBank {
  bankName: string
  last4DigitAccountNumber: string
}

interface BankAction {
  setSelectedBank: (bank: IBank | IBankSummary) => void
  getBankAccounts: () => Promise<IFetchBankAccountsResp>
  removeBank: (bankId: string, source: BankSource) => Promise<void>
  pollBankStatusWithoutBankId: (
    source: BankSource,
    onSuccess: () => void
  ) => Promise<void>
  pollBankStatus: (bank: IBankSummary, source: BankSource) => Promise<void>
  setLinkedBank: (bank: LinkedBank) => void
  reset: () => void
}

interface BankState {
  banks?: IBankSummary[]
  selectedBank?: IBank | IBankSummary
  linkedBank?: LinkedBank
}

const initialState: BankState = {
  banks: undefined,
  selectedBank: undefined,
  linkedBank: undefined,
}

const useBankStore = create<BankAction & BankState>()(
  persist(
    (set, getState) => ({
      ...initialState,
      reset: () => {
        set(initialState)
      },
      setSelectedBank: (bank: IBank | IBankSummary) => {
        set({ selectedBank: bank })
      },
      getBankAccounts: async () => {
        const resp = await bankApi.fetchBankAccounts()
        set({ banks: [...resp.data.banks] })
        return resp
      },
      removeBank: async (bankId: string, source: BankSource) => {
        const { setShowNotificationModal } = useUIStore.getState()
        const { banks } = getState()
        try {
          const resp = await bankApi.deleteBankAccount(bankId, source)
          if (resp.data && banks) {
            const index = banks.findIndex((each) => each.id === bankId)
            banks.splice(index, 1)
            set({ banks: [...banks] })
          }
        } catch {
          setShowNotificationModal(true, {
            text: `Unable to remove your account. Please try again.`,
            autoHideDuration: 3000,
          })
        }
      },
      pollBankStatusWithoutBankId: async (source, onSuccess) => {
        const { pollBankStatusWithoutBankId } = getState()

        const { data } = await bankApi.fetchBankAccountStatus(source)

        const isCompleted = !!data.banks.filter(
          ({ status }) => status === 'COMPLETE'
        ).length

        if (!isCompleted) {
          setTimeout(() => {
            pollBankStatusWithoutBankId(source, onSuccess)
          }, 3000)
          return
        }

        onSuccess()
      },
      pollBankStatus: async (bank, source) => {
        const { getBankAccounts, pollBankStatus } = getState()
        const { setShowNotificationModal } = useUIStore.getState()
        const bankId = bank.id

        try {
          const resp = await bankApi.fetchBankAccountStatus(source, bankId)
          const { banks: respBanks } = resp.data
          const findBank = respBanks.find((each) => each.id === bankId)

          if (findBank === undefined) return

          if (findBank.status !== 'COMPLETE') {
            setTimeout(() => {
              pollBankStatus(bank, source)
            }, 3000)
            return
          }

          await getBankAccounts()

          setShowNotificationModal(true, {
            text: `You’ve successfully linked your “${bank.name}” bank account`,
            autoHideDuration: 2500,
            icon: 'clock',
          })
        } catch {
          setShowNotificationModal(true, {
            text: `“${bank.name}” syncing failed!`,
            autoHideDuration: 2500,
            icon: 'alert',
          })
        }
      },
      setLinkedBank: (bank) => {
        set({ linkedBank: bank })
      },
    }),
    {
      partialize: (state) => ({
        selectedBank: state.selectedBank,
      }),
      name: 'selectedBank',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useBankStore }
