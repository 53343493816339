import {
  IRecentCashAdvanceData,
  IRepaymentMethod,
  ICreateAppAndLinkEsignData,
  IRepaymentFundOptionsData,
  IDisbursementAmountsData,
  IGetTipRequest,
  ITipsData,
  IGetDisbursementMethodRequest,
  IDisbursementMethodData,
  IPostApplyInstacashDisbursementRequest,
  IApplyInstacashDisbursementData,
} from '@root/types/CashAdvance'
import ICashAdvanceInfo from '@root/types/CashAdvanceInfo'
import { https } from '@root/utils'

interface IGetCashAdvanceInfoResponse {
  data: ICashAdvanceInfo
}

interface IGetRecentCashAdvanceResponse {
  code: string
  data: IRecentCashAdvanceData
  message: string
}

interface IGetRepaymentMethodResponse {
  code: string
  data: IRepaymentMethod[]
  message: string
}

interface ICreateAppAndLinkEsignResponse {
  code: string
  data: ICreateAppAndLinkEsignData
  message: string
}

interface IGetRepaymentFundOptionsResponse {
  code: string
  data: IRepaymentFundOptionsData
  message: string
}

interface IGetDisbursementAmountsResponse {
  code: string
  data: IDisbursementAmountsData
  message: string
}

interface IGetTipsResponse {
  code: string
  data: ITipsData
  message: string
}

interface IGetDisbursementMethodResponse {
  code: string
  data: IDisbursementMethodData
  message: string
}

interface IPostApplyInstacashDisbursementResponse {
  code: string
  data: IApplyInstacashDisbursementData
  message: string
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: To get the recent cash advance transactions. If no transactions found, means there is no Instacash disbursement.
 * @returns {IRecentCashAdvanceData}: Returns the recent cash advance transactions
 */
const getRecentCashAdvance =
  async (): Promise<IGetRecentCashAdvanceResponse> => {
    const response = await (await https()).get(`/cash-advance/recent`)
    return response.data
  }

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: To get a list of Debit Cards that are linked to Instacash Repayment. If no cards found, means there is no debit card link to Instacash Repayment.
 * @returns {IRepaymentMethod[]}: Returns the recent cash advance transactions
 */
const getRepaymentMethods = async (): Promise<IGetRepaymentMethodResponse> => {
  const response = await (
    await https()
  ).get(`/cash-advance/repayment/repayment-methods`)
  return response.data
}

/**
 * @usage:
 *  1) For displaying the Instacash info
 *  2) For the Instacash Flow
 *  3) For the Instacash Disbursement Flow
 * @description:
 *  1) To get the Cash Advance info
 *  2) To check if the user is eligible to enter a flow
 * @returns {ICashAdvanceInfo}: Returns the recent cash advance transactions
 */
const getCashAdvanceInfo = async (): Promise<IGetCashAdvanceInfoResponse> => {
  const response = await (await https()).get('/cash-advance/info')
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Create Instacash application and link the esign documents
 * @returns {ICreateAppAndLinkEsignData}: Returns the application id and esign documents
 */
const postCreateAppAndLinkEsign =
  async (): Promise<ICreateAppAndLinkEsignResponse> => {
    const response = await (await https()).post('/cash-advance/createAppEsign')
    return response.data
  }

/**
 * @usage: For the Add Card Flow
 * @description: Step 2 - Get repayment fund options
 * @returns {IGetRepaymentFundOptionsData}: Returns the repayment fund options
 */
const getRepaymentFundOptions = async (
  types?: string
): Promise<IGetRepaymentFundOptionsResponse> => {
  const urlParams = types ? `?types=${types}` : ''

  const response = await (
    await https()
  ).get(`/cash-advance/repayment/fundOptions${urlParams}`)
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Get a list of disbursement amounts
 * @returns {IDisbursementAmountsData}: Returns a list of disbursement amounts
 */
const getDisbursementAmounts =
  async (): Promise<IGetDisbursementAmountsResponse> => {
    const response = await (
      await https()
    ).get(`/cash-advance/disbursement/amounts`)
    return response.data
  }

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Get tips
 * @param {request}: IGetTipRequest
 * @returns {ITipsData}: Returns tips
 */
const getTips = async (request: IGetTipRequest): Promise<IGetTipsResponse> => {
  const response = await (
    await https()
  ).get(
    `/cash-advance/tips?cashAdvanceAmount=${request.cashAdvanceAmount}&tipSource=${request.tipSource}`
  )
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Get a list of disbursement methods
 * @param {request}: IGetDisbursementMethodRequest
 * @returns {IDisbursementMethodData}: Returns a list of disbursement methods
 */
const getDisbursementMethod = async (
  request: IGetDisbursementMethodRequest
): Promise<IGetDisbursementMethodResponse> => {
  const response = await (
    await https()
  ).get(
    `/cash-advance/disbursement/v3/disbursement-method?applicationAmount=${request.amount}&applicationSource=${request.applicationSource}&applicationId=${request.applicationId}`
  )
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Apply for Instacash disbursement
 * @returns {IApplyInstacashDisbursementData}: Returns the disbursement application data
 */
const postApplyInstacashDisbursement = async (
  request: IPostApplyInstacashDisbursementRequest
): Promise<IPostApplyInstacashDisbursementResponse> => {
  const response = await (
    await https({ expectErrorData: true })
  ).post(`/cash-advance/v5/apply`, request)
  return response.data
}

const instacashApi = {
  getCashAdvanceInfo,
  getRecentCashAdvance,
  getRepaymentMethods,
  postCreateAppAndLinkEsign,
  getRepaymentFundOptions,
  getDisbursementAmounts,
  getTips,
  getDisbursementMethod,
  postApplyInstacashDisbursement,
}

export default instacashApi
