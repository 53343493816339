import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Icon, Label, List, Body } from '@moneylion/mlds-web'
import styled, { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import { useOnClickOutside } from 'usehooks-ts'
import { useRemoteConfig, useLogout } from '@root/hooks'
import { ENV } from '@root/config'
import { useAuthStore, useReferralsStore } from '@root/store'
import { ProfileImage } from '@root/experiences/Profile'
import { REMOTE_CONFIG_FEATURE_FLAG } from '@root/constants/featureFlagNames'
import { labels } from './labels'

const StyledMenu = styled(Card)`
  position: absolute;
  padding: 0;
  bottom: calc(100% + 24px);
  left: -16px;
  width: calc(100% + 32px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: left;
  cursor: default;

  .ml-listItem:nth-of-type(2) i {
    transform: rotate(90deg);
  }

  .ml-listBullet {
    margin: 10px;
    margin-right: 0;
  }
`

const ProfileButton = styled(Button)<{ isActive: boolean }>`
  background: ${({ isActive, theme }) =>
    isActive ? theme.color?.BACKGROUND_PAGE : 'transparent'};
  border-radius: 32px;
  justify-content: start;
  height: auto;
  min-height: 56px;

  > span {
    max-width: 100%;
    gap: 8px 16px;
  }

  .profile-button-image {
    flex-shrink: 0;
  }

  .profile-button-text-wrapper {
    overflow: hidden;

    .username,
    .user-roartag {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  .legal-links {
    border-top: solid 1px rgba(0, 0, 0, 0.08);
    gap: 12px;

    p {
      color: ${({ theme }) => theme.color?.CONTENT_SECONDARY};
      white-space: nowrap;
    }
  }

  .social-channels {
    gap: 20px;
    background-color: #eee;
    padding-top: 10px;
    padding-bottom: 10px;

    a {
      display: inline-block;
      height: 20px;
      font-size: 20px;

      i:before {
        height: 20px;
        line-height: 0.3;
      }
    }
  }
`

const ProfileMenu = (): JSX.Element => {
  const theme = useTheme()
  const [showProfileMenu, setShowProfileMenu] = useState(false)
  const profileMenuRef = useRef<HTMLDivElement | null>(null)
  const user = useAuthStore((state) => state.user)
  const referrals = useReferralsStore((state) => state.referrals)
  const getReferrals = useReferralsStore((state) => state.getReferrals)
  const logout = useLogout()
  const router = useRouter()
  const { treatment, status } = useRemoteConfig(
    REMOTE_CONFIG_FEATURE_FLAG.WEB.cpcEnabled
  )
  const shouldEnableCPC = treatment?.asBoolean()

  const handleClickOutside = () => {
    setShowProfileMenu(!showProfileMenu)
  }
  const handleClickInside = () => {
    setShowProfileMenu(!showProfileMenu)
  }

  useOnClickOutside(profileMenuRef, handleClickOutside)

  useEffect(() => {
    if (!referrals) {
      getReferrals()
    }
  }, [])
  return (
    <ProfileButton
      className="flex"
      type="button"
      onClick={handleClickInside}
      isActive={router.pathname === '/profile'}
      fullWidth
      color="secondary"
    >
      <ProfileImage
        className="profile-button-image"
        image={user?.profilePicturePreviewUrl}
        size="Small"
        style={
          user?.profilePicturePreviewUrl
            ? { opacity: '1.0' }
            : { opacity: '0.4' }
        }
      />

      <div className="flex flex-col ai-start profile-button-text-wrapper">
        <Label size="large" className="capitalize username">
          {`Hi${user?.firstName ? `, ${user?.firstName}` : ''}`}
        </Label>
        {referrals && (
          <Body
            size="medium"
            color={theme.color?.CONTENT_SECONDARY}
            className="user-roartag"
          >
            {referrals.roarTag}
          </Body>
        )}
      </div>
      {showProfileMenu && (
        <StyledMenu className="profile-menu">
          <div ref={profileMenuRef}>
            <List divider="none">
              <List.Item
                bullet={{ icon: 'user' }}
                onClick={() => router.push('/profile')}
              >
                <Body size="large">{labels.profile}</Body>
              </List.Item>
              <List.Item bullet={{ icon: 'share' }} onClick={logout}>
                <Body size="large">{labels.logOut}</Body>
              </List.Item>
            </List>
            <div className="legal-links flex flex-col px-4 py-3">
              {status === 'success' && shouldEnableCPC && (
                <Body size="medium">
                  <a
                    href={`${ENV.COMMUNICATION_PREFERENCES_URL}?email=${user?.email}`}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Communication Preference Link"
                  >
                    {labels.communicationPreferences}
                  </a>
                </Body>
              )}
              <Body size="medium">
                <a
                  href={`${ENV.MONEYLION_HOMEPAGE_URL}/privacy-notice`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Privacy Policy Link"
                >
                  {labels.privacyPolicy}
                </a>
              </Body>
              <Body size="medium">
                <a
                  href={`${ENV.MONEYLION_HOMEPAGE_URL}/terms-and-conditions`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Terms & Conditions Link"
                >
                  {labels.termsAndConditions}
                </a>
              </Body>
              <Body size="medium">
                <a
                  href={`${ENV.MONEYLION_HOMEPAGE_URL}/terms-and-conditions#advertiser`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Advertiser Disclosure Link"
                >
                  {labels.advertiserDisclosure}
                </a>
              </Body>
              <Body size="medium">
                <a
                  href={ENV.MONEYLION_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Support link"
                >
                  {labels.support}
                </a>
              </Body>
            </div>
            <div className="social-channels flex px-4">
              <a
                href={ENV.MONEYLION_SOCIAL_LINKS.facebook}
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook link"
              >
                <Icon name="facebook" />
              </a>
              <a
                href={ENV.MONEYLION_SOCIAL_LINKS.twitter}
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter link"
              >
                <Icon name="twitter" />
              </a>
              <a
                href={ENV.MONEYLION_SOCIAL_LINKS.linkedin}
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn link"
              >
                <Icon name="linkedin" />
              </a>
            </div>
          </div>
        </StyledMenu>
      )}
    </ProfileButton>
  )
}

export { ProfileMenu }
