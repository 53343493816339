import {
  IGetWalletBillingAddressData,
  IGetGenerateEsignDocumentRequest,
  IGetGenerateEsignDocumentData,
  TWalletFundProduct,
  IGetWalletFundProductData,
  IWalletAddCardRequest,
  IWalletAddCardData,
  IWalletLinkProductRequest,
} from '@root/types/Wallet'
import { https } from '@root/utils'

interface IGetWalletBillingAddressResponse {
  code: string
  data: IGetWalletBillingAddressData
  message: string
}

interface IGetGenerateEsignDocumentResponse {
  code: string
  data: IGetGenerateEsignDocumentData
  message: string
}

interface IGetWalletFundProductResponse {
  code: string
  data: IGetWalletFundProductData
  message: string
}

interface IWalletAddCardResponse {
  code: string
  data: IWalletAddCardData
  message: string
}

interface IWalletLinkProductResponse {
  code: string
  message: string
}

/**
 * @usage: For the Add Card Flow
 * @description: Get the list of billing addresses (from User DB and Wallet DB) and check whether the user is eligible to a new add address
 * @returns {IGetWalletBillingAddressData}: Returns a list of billing address
 */
const getWalletBillingAddress =
  async (): Promise<IGetWalletBillingAddressResponse> => {
    const response = await (await https()).get(`/wallet/billingAddress`)
    return response.data
  }

/**
 * @usage: For the Add Card Flow
 * @description: Generate and retrieve the backup esign documents
 * @returns {IGetGenerateEsignDocumentData}: Returns a list of backup esign documents
 */
const getGenerateEsignDocuments = async (
  request: IGetGenerateEsignDocumentRequest
): Promise<IGetGenerateEsignDocumentResponse> => {
  const response = await (
    await https()
  ).post(`/wallet/generateEsignDoc`, request)
  return response.data
}

/**
 * @usage: For the CB Plus Flow
 * @description: Get wallet fund product
 * @param {product}: TWalletFundProduct
 * @returns {IGetWalletFundProductData}: Returns the bank details
 */
const getWalletFundProduct = async (
  product: TWalletFundProduct
): Promise<IGetWalletFundProductResponse> => {
  const response = await (
    await https()
  ).get(`/wallet/product?product=${product}`)
  return response.data
}

/**
 * @usage: For the Add Card Flow
 * @description: Step 1 - Add Card
 * @param {request}: IPostWalletAddCardRequest
 * @returns {IWalletAddCardData}: Returns the fundId
 */
const postWalletAddCard = async (
  request: IWalletAddCardRequest
): Promise<IWalletAddCardResponse> => {
  const response = await (await https()).post(`/wallet/card`, request)
  return response.data
}

/**
 * @usage: For the Instacash Add Card Flow
 * @description: Step 3 - Link to a product
 * @param {request}: IPostWalletLinkProductRequest
 * @returns {IWalletLinkProductResponse}: Returns response status
 */
const postWalletLinkProduct = async (
  request: IWalletLinkProductRequest
): Promise<IWalletLinkProductResponse> => {
  const response = await (await https()).post(`/wallet/v2/product`, request)
  return response.data
}

const walletApi = {
  getWalletBillingAddress,
  getGenerateEsignDocuments,
  getWalletFundProduct,
  postWalletAddCard,
  postWalletLinkProduct,
}

export default walletApi
