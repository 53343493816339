import IUser from '@root/types/User'
import IReferrals, {
  IReferralSummary,
  SubmitReferralReq,
  SubmitReferralRes,
} from '@root/types/Referrals'
import { https } from '@root/utils'

const fetchReferrals = async (user: IUser | null): Promise<IReferrals> => {
  const query = `userId=${user?.id}`
  const referralDataRes = await (
    await https()
  ).get(`/referral/referral-code?${query}`)

  if (referralDataRes.status === 200) {
    return referralDataRes?.data?.data?.data
  }
  return {}
}

const fetchReferralSummary = async (): Promise<IReferralSummary | null> => {
  const referralDataRes = await (
    await https()
  ).get(`/referral/referral-summary`)

  if (referralDataRes.status === 200) {
    return referralDataRes?.data?.data?.data
  }
  throw new Error(`Error: There has been an issue. ${referralDataRes.status}`)
}

const submitReferral = async (
  payload: SubmitReferralReq
): Promise<SubmitReferralRes> => {
  const response = await (
    await https({ expectErrorData: true })
  ).post(`/referral/userReferral`, payload)
  return response.data
}

const referralApi = {
  fetchReferrals,
  fetchReferralSummary,
  submitReferral,
}

export default referralApi
