import { create, useStore } from 'zustand'
import Cookies from 'js-cookie'
import { datadogRum } from '@datadog/browser-rum'
import smartlookClient from 'smartlook-client'
import { Auth } from '@moneylion/auth-js'
import jwt_decode from 'jwt-decode'
import { TemporalState, temporal } from 'zundo'
import { SessionTokenExchangeResponse } from '@moneylion/auth-js/dist/service/tokenService'
import { userApi, segmentApi, appsFlyerApi, sessionStorageApi } from '@root/api'
import type {
  IChallengeMFAIdResponse,
  ILoginUserPayload,
  ILoginUserResponse,
} from '@root/types/User'
import IUser from '@root/types/User'
import { useReferralsStore } from '@root/store/referralsStore'
import { useCBPStore } from '@root/store/cbpStore'
import { useCryptoStore } from '@root/store/cryptoStore'
import { useInstacashStore } from '@root/store/instacashStore'
import { useInvestmentStore } from '@root/store/investmentStore'
import { useRoarMoneyStore } from '@root/store/roarMoneyStore'
import { useBankStore } from '@root/store/bankStore'
import { useUserStore } from '@root/store/userStore'
import { useMPLStore } from '@root/store/marketplaceStore'
import { refreshAccessToken, validateToken } from '@root/utils'
import { AuthStatus, Token } from '@root/types'

interface AuthInitialState {
  user: IUser | null
  status: AuthStatus
}

interface LegacyAuthState extends AuthInitialState {
  /**
   * @deprecated auth-js will handle the access token
   */
  setAccessToken: (token: string) => void

  /**
   * @deprecated use signinWithRedirect instead
   */
  login: (
    body: ILoginUserPayload,
    signal: AbortSignal,
    userIdTreatment?: boolean
  ) => Promise<ILoginUserResponse>

  /**
   * @deprecated auth web react will handle mfa
   */
  confirmMfa: (
    mfaId: string,
    verificationCode: string,
    signal: AbortSignal,
    userIdTreatment?: boolean
  ) => Promise<IChallengeMFAIdResponse>

  /**
   * @deprecated auth web react will handle recovery flow in the future
   */
  sendForgotPasswordEmail: (
    email: string,
    signal: AbortSignal
  ) => Promise<{ token: string }>

  /**
   * @deprecated auth web react will handle recovery flow in the future
   */
  resetPassword: (
    newPassword: string,
    resetPasswordToken: string,
    signal: AbortSignal
  ) => Promise<object>

  /**
   * @deprecated there will be no need to differentiate the token issuer in the future
   */
  getTokenIssuer: () => string
}

interface AuthState extends AuthInitialState {
  /**
   * Auth-js instance
   */
  auth: Auth | null

  /**
   * Redirects to the auth web react's login page
   */
  signInWithRedirect: () => void

  /**
   * Handles the callback from the auth web react's login page
   */
  handleRedirectCallback: () => Promise<void>

  /**
   * Returns the access token, prioritizing the one from common auth
   */
  getAccessToken: () => string

  /**
   * removes the access token from common auth and from cookies
   */
  removeAccessToken: () => void

  /**
   * Reset all the stores, remove the access token and call logout endpoint for common auth
   */
  logout: () => void

  /**
   * set the authroization status, can be one of 'loading', 'authorized', 'loggedOut' and 'unauthorized'
   */
  setStatus: (status: AuthStatus) => void

  /**
   * Returns the authenticated user, if the user is not authenticated, it will return undefined
   */
  getAuthenticatedUser: (
    withSsn?: boolean,
    userIdTreatment?: boolean
  ) => Promise<IUser | undefined>

  /**
   * reset the auth state to the initial state
   */
  reset: () => void

  /**
   * refresh the auth web react access token
   */
  refreshAccessToken: () => Promise<void>

  /**
   * exchange temp token for dashboard issued access token
   */
  sessionTokenExchange: (
    token: string
  ) => Promise<SessionTokenExchangeResponse | undefined>

  /**
   * set the user data during onboarding
   */
  setUserPartialData: (userPartialData: Partial<IUser>) => void
}

const initialState: AuthInitialState = {
  user: null,
  status: 'loading',
}

// TODO: Move user data from AuthStore to UserStore
const useAuthStore = create<AuthState & LegacyAuthState>()(
  temporal(
    (set, getState) => ({
      ...initialState,

      auth: null,

      getAccessToken: () => {
        const token = getState().auth?.getAccessToken()
        const legacyToken = Cookies.get('token')
        return token || legacyToken || ''
      },

      setAccessToken: (token) => {
        Cookies.set('token', token)
      },

      removeAccessToken: () => {
        Cookies.remove('token')
        getState().auth?.removeTokensAndSessionId()
      },

      /**
       * @error: Sample
       *  {
       *    code: "DA004",
       *    message: "User email and password combination not found"
       *  }
       */
      login: async (body, signal, userIdTreatment) => {
        try {
          Cookies.remove('token')
          const state = getState()
          const resp = await userApi.loginUser(body, signal)
          segmentApi.identify(body.username)

          if (!validateToken(resp.token)) {
            return Promise.reject(new Error('INVALID_TOKEN'))
          }

          state.setAccessToken(resp.token)
          refreshAccessToken(resp.token)

          if (!resp.mfaRequired) {
            await state.getAuthenticatedUser(false, userIdTreatment)
            set({ status: 'authorized' })
          } else {
            if (state.status === 'loggedOut') set({ status: 'unauthorized' })
          }

          return Promise.resolve(resp)
        } catch (error) {
          set({ status: 'unauthorized' })
          return Promise.reject(error)
        }
      },

      signInWithRedirect: () => getState().auth?.signInWithRedirect(),

      handleRedirectCallback: async () => {
        try {
          await getState().auth?.handleRedirectCallback()
          const state = getState()
          await state.getAuthenticatedUser()
        } catch (error) {
          set({ status: 'unauthorized' })
          throw error
        }
      },

      logout: () => {
        const state = getState()

        const resetUser = useUserStore.getState().reset
        const resetCreditBuilderPlus = useCBPStore.getState().reset
        const resetCrypto = useCryptoStore.getState().reset
        const resetInstacash = useInstacashStore.getState().reset
        const resetInvestment = useInvestmentStore.getState().reset
        const resetReferral = useReferralsStore.getState().reset
        const resetRoarMoney = useRoarMoneyStore.getState().reset
        const resetBank = useBankStore.getState().reset
        const resetMarketplace = useMPLStore.getState().reset

        resetCreditBuilderPlus()
        resetCrypto()
        resetInstacash()
        resetInvestment()
        resetReferral()
        resetRoarMoney()
        resetBank()
        resetUser()
        resetMarketplace()
        state.reset()
        localStorage.removeItem('userIdTreatment')

        datadogRum.clearUser()

        sessionStorageApi.clear()
        state.removeAccessToken()
        state.auth?.signOut()
        set({ status: 'loggedOut' })

        const isAdaEmbeded = document.getElementById('ada-entry')
        if (isAdaEmbeded) adaEmbed.stop()
      },

      setStatus: (status: AuthState['status']) => {
        set({ status })
      },

      getAuthenticatedUser: async (withSsn = false, userIdTreatment) => {
        try {
          const { data: user } = await userApi.getUserProfile(withSsn)
          const { id, email } = user
          const state = getState()

          set({ user })
          if (state.status === 'loading') set({ status: 'authorized' })

          appsFlyerApi.setCustomerUserId(user.id)
          segmentApi.identify(
            cachedUserTreatment(userIdTreatment) ? id : email,
            {
              email,
              ml_user_id: id,
            }
          )
          datadogRum.setUser({
            id,
          })
          smartlookClient.identify(id, {})

          return Promise.resolve(user)
        } catch (error) {
          const state = getState()
          if (state.status === 'loading') set({ status: 'unauthorized' })
          return Promise.reject(error)
        }
      },

      /**
       * @error: Sample
       *  {
       *    code: "DA_AU_PR_011",
       *    message: "MFA Session is expired"
       *  }
       */
      confirmMfa: async (
        mfaId,
        verificationCode,
        signal: AbortSignal,
        userIdTreatment
      ) => {
        try {
          const resp = await userApi.challengeMFAId(
            { mfaId, verificationCode },
            signal
          )
          const state = getState()

          if (!validateToken(resp.token)) {
            return Promise.reject(new Error('INVALID_TOKEN'))
          }

          state.setAccessToken(resp.token)
          refreshAccessToken(resp.token)

          // clear`mfaOptions` in sessionStorage
          sessionStorage.removeItem('mfaOptions')
          await state.getAuthenticatedUser(false, userIdTreatment)
          if (state.status === 'unauthorized') set({ status: 'authorized' })

          return Promise.resolve(resp)
        } catch (error) {
          return Promise.reject(error)
        }
      },

      sendForgotPasswordEmail: async (email, signal) => {
        try {
          return await userApi.sendForgetPasswordEmail({ email }, signal)
        } catch (error) {
          return Promise.reject(error)
        }
      },

      /**
       * @error: Sample
       *  {
       *    code: "PCF0003"
       *  }
       */
      resetPassword: async (newPassword, resetPasswordToken, signal) => {
        try {
          return await userApi.resetPassword(
            {
              newPassword,
              resetPasswordToken,
            },
            signal
          )
        } catch (error) {
          return Promise.reject(error)
        }
      },

      reset: () => {
        set(initialState)
      },

      getTokenIssuer: () => {
        try {
          const accessToken = getState().getAccessToken()
          const parsed: Token = jwt_decode(accessToken)
          return parsed.iss || ''
        } catch {
          return ''
        }
      },

      refreshAccessToken: async () => {
        const state = getState()
        await state.auth?.refreshAccessToken()
      },

      sessionTokenExchange: async (token) => {
        return await getState().auth?.sessionTokenExchange(token)
      },

      setUserPartialData: (userPartialData: Partial<IUser>) => {
        const user = getState().user
        if (user) {
          set({ user: { ...user, ...userPartialData } })
        }
      },
    }),
    {
      /**
       * @NOTE - We want to reduce the noise by comparing only the `status` property
       * of the state. Without this, you'll see a lot of same state changes in the
       * devtools.
       *
       * @NOTE 2 - I don't prefer define the `equality` function here since it might not scale
       * well if we have a lot of properties to cover in the future. But for now, this
       * is the best solution.
       */
      equality(currentState, pastState) {
        return currentState.status !== pastState.status
      },
    }
  )
)

/**
 * @NOTE - We use `zundo` to be able to track the state history of the store.
 * This is one the implementation to convert to React hook.
 * https://github.com/charkour/zundo#convert-to-react-store
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAuthTemporalStore = <T>(
  selector: (state: TemporalState<AuthState>) => T,
  equality?: (a: T, b: T) => boolean
) => useStore(useAuthStore.temporal, selector, equality)

function cachedUserTreatment(arg: boolean | undefined): boolean | null {
  if (arg === undefined)
    return localStorage.getItem('userIdTreatment') === 'true'

  localStorage.setItem('userIdTreatment', String(arg))

  return arg
}

export { useAuthStore, useAuthTemporalStore }
