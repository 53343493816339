import { Icon } from '@moneylion/mlds-web'
import { HeaderButtonContainer } from '@root/components/HeaderButtonContainer.styles'

interface FaqButtonProps {
  faqLink?: string
  withBackgroundColor?: boolean
  size?: number
}

const FaqButton = ({
  faqLink = '',
  withBackgroundColor = true,
  size = 24,
}: FaqButtonProps): JSX.Element => {
  const handleClickBack = (): void => {
    if (!faqLink) return
    window.open(faqLink, '_blank')
  }

  return (
    <HeaderButtonContainer
      onClick={handleClickBack}
      role="button"
      aria-label="faq button"
      withBackgroundColor={withBackgroundColor}
    >
      <Icon size={size} name="help" />
    </HeaderButtonContainer>
  )
}

export { FaqButton }
