import { https } from '@root/utils'
import { ENV } from '@root/config'
import {
  OnboardStateRequest,
  OnboardStateResponse,
  RetrievePiiRes,
  VerifyIDVBody,
  VerifyIDVRes,
} from '@root/types'
import { CoreOnboardAddress } from '@root/types/User'

const baseUrl = `${ENV.API_URL}/lifecycle`

const updateAddress = async (payload: {
  coreOnboardAddress: CoreOnboardAddress
}): Promise<RetrievePiiRes> => {
  const response = await (
    await https({ expectErrorData: true })
  ).post(`${baseUrl}/update/address`, payload)
  return response.data
}

const retrievePii = async (signal?: AbortSignal): Promise<RetrievePiiRes> => {
  const response = await (
    await https({ signal })
  ).get(`${baseUrl}/retrieve/pii`)
  return response.data
}

const checkOnboardState = async (
  request: OnboardStateRequest,
  signal?: AbortSignal
): Promise<OnboardStateResponse> => {
  const response = await (
    await https({ signal })
  ).post(`${baseUrl}/onboard/state`, request)
  return response.data
}

const verifyIdv = async (body: VerifyIDVBody): Promise<VerifyIDVRes> => {
  const response = await (
    await https({ expectErrorData: true })
  ).post(`${baseUrl}/verify/v3/idv`, body)
  return response.data
}

const lifecycleApi = {
  checkOnboardState,
  updateAddress,
  retrievePii,
  verifyIdv,
}

export default lifecycleApi
