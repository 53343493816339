import { memo } from 'react'
import { ProgressList } from '@moneylion/mlds-web'
import { useRouter } from 'next/router'
import { getOnboardingFlow, mapStepperProgress } from '@root/utils'
import { useOnboardingStore } from '@root/store'
import { labels } from './labels'

interface StepperProps {
  type: 'desktop' | 'mobile'
}

const Stepper_ = ({ type }: StepperProps): JSX.Element => {
  const { pathname } = useRouter()
  const flow = getOnboardingFlow(pathname)
  const isIntentFlow = useOnboardingStore((state) => state.isIntentFlow)
  const typeOfFlow = isIntentFlow === true ? flow : 'organic'

  return (
    <ProgressList
      activeStep={mapStepperProgress(pathname, isIntentFlow)}
      minimal
      horizontal={type === 'mobile'}
    >
      {labels[typeOfFlow].map((label, index) => (
        <ProgressList.Step key={label + index}>
          <ProgressList.Label>{type === 'desktop' && label}</ProgressList.Label>
        </ProgressList.Step>
      ))}
    </ProgressList>
  )
}

const Stepper = memo(Stepper_)

export { Stepper }
