import { ADDRESS_ERROR_DIALOG_LABEL } from '@root/constants'
import { segmentApi } from '@root/api'
import { IInputAddress } from '@root/types/Address'
import { TOnboardingFlow } from './getOnboardingFlow'

export const isAddressValid = (address?: IInputAddress): boolean => {
  return !!(
    address &&
    address.streetAddress &&
    address.city &&
    address.state &&
    address.zip &&
    address.zip.length === 5
  )
}

export type AddressErrorDialogType = keyof typeof ADDRESS_ERROR_DIALOG_LABEL

/**
 * @description: Error codes which can bypass the Address flow
 */
const bypassErrorCodeList = ['AS16', 'AS17', 'AS24']

const commonPOBoxErrorCodeList = ['AS10', 'AS11', 'AS20']

export const mapAddressErrorCodeToDialogType = (
  codes: string[]
): AddressErrorDialogType => {
  const errorCodesList = [...bypassErrorCodeList, 'AS01']

  // If there exist an error code which is not AS16, AS17, AS24 and AS01
  const hasNonBypassErrorCodes = codes.some(
    (code) => !errorCodesList.includes(code)
  )

  if (!hasNonBypassErrorCodes) {
    return 'BYPASS_ALLOWED'
  }

  const hasPOBoxErrorCodes = codes.some((code) =>
    commonPOBoxErrorCodeList.includes(code)
  )

  if (hasPOBoxErrorCodes) {
    return 'INVALID_PO_BOX_V2'
  }

  return 'INVALID_V2'
}

export const mapAddressErrorCodeToMessage = (
  errorCode: string
): string | undefined => {
  switch (errorCode) {
    case 'AE01':
      return 'We had some trouble validating your ZIP code. Please double check it, and try again.'
    case 'AE02':
      return `We're not able to verify the street name you entered. Please double check it, and try again.`
    case 'AE03':
      return 'We had some trouble finding your address. Please double check the cardinal directions (N, E, SW, etc) or the street suffix (Ave., St., Blvd., etc.)'
    case 'AE04':
      return 'The address you entered is not a deliverable address. Please update to a current mailing address.'
    case 'AE05':
      return 'The address you entered is not a deliverable address. Please double check your entry and update to a current mailing address.'
    case 'AE06':
      return `It looks like the USPS can't deliver to this address yet. Please update with a current mailing address.`
    case 'AE07':
    case 'AE08':
    case 'AE09':
    case 'AE10':
    case 'AE11':
      return 'Please add the missing house or building number to continue.'
    case 'AE12':
      return 'The PO(Post Office Box), RR(Rural Route), or HC(Highway Contract) Box number is invalid. Please update to continue.'
    case 'AE13':
      return 'The PO(Post Office Box), RR(Rural Route), or HC(Highway Contract) Box number is missing. Please update to continue.'
    case 'AE14':
      return 'The address is identified as a Commercial Mail Receiving Agency(CMRA). Please add the Private Mail Box(PMB or #) to continue.'
    case 'AS10':
    case 'AS11':
    case 'AS20':
      return `Unfortunately, we don't support P.O. Boxes. Please update with your physical home address.`
    case 'AS16':
    case 'AS17':
    case 'AS24':
      return 'Unfortunately, we cannot deliver to this address. Please update with your physical home address.'
    default:
      return undefined
  }
}

const getSegmentPropertyEntryPoint = (flow: TOnboardingFlow): string => {
  switch (flow) {
    case 'cbplus':
      return 'CBPLUS'
    case 'crypto':
      return 'CRYPTO'
    case 'instacash':
      return 'IC'
    case 'investment':
      return 'INVESTMENT'
    case 'roarmoney':
      return 'DDA'
    default:
      return 'ORGANIC'
  }
}

export const segmentTrackAccCoreAddressValidateSubmit = (props: {
  flow: TOnboardingFlow
  status: string
  codes?: string[]
}): void => {
  const { flow, status, codes } = props
  segmentApi.trackWithOSPlatform('acc_core_address_validate_submit', {
    entry_point: getSegmentPropertyEntryPoint(flow),
    validation: 'allow AS16, AS17 & AS24',
    status: status,
    codes: codes,
  })
}
