import { Input, List } from '@moneylion/mlds-web'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { googleMapsApi } from '@root/api'
import { useUIStore } from '@root/store'
import { IInputAddress, IGoogleAddressAutocomplete } from '@root/types/Address'
import labels from './labels'
import { ListContainer } from '@onboarding/components'

interface InputAddressAutocompleteProps {
  isAutoFocus?: boolean
  onChange: (address: IInputAddress) => void
}

const InputAddressAutocomplete = ({
  isAutoFocus,
  onChange,
}: InputAddressAutocompleteProps): JSX.Element => {
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)

  const [streetAddress, setStreetAddress] = useState<string>()
  const [addressAutocompleteSuggestion, setAddressAutocompleteSuggestion] =
    useState<IGoogleAddressAutocomplete[]>([])
  const [selectedAddress, setSelectedAddress] = useState<string>('')
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const debouncedSearchText = useDebounce(streetAddress, 500)

  useEffect(() => {
    if (debouncedSearchText) {
      generateAddressAutocompleteSuggestion()
    }
  }, [debouncedSearchText])

  const onChangeStreetAddress = (streetAddress: string): void => {
    setStreetAddress(streetAddress)
  }

  const generateAddressAutocompleteSuggestion = async (): Promise<void> => {
    try {
      if (streetAddress) {
        setIsSearching(true)

        setTimeout(async () => {
          const { data } = await googleMapsApi.getAddressAutocomplete(
            streetAddress
          )

          setAddressAutocompleteSuggestion(data)
          setIsSearching(false)
        }, 500)
      } else {
        setAddressAutocompleteSuggestion([])
        setIsSearching(false)
      }
    } catch {
      setShowAppDialog(true)
    }
  }

  const onClickSelect = async (placeId: string): Promise<void> => {
    try {
      if (!placeId) {
        throw new Error('ADDRESS_PLACE_ID_NOT_FOUND')
      }

      setSelectedAddress(placeId)

      const { data } = await googleMapsApi.getAddressDetail(placeId)

      const { city, postalCode, state, street } = data

      setAddressAutocompleteSuggestion([])
      onChange({
        city,
        state,
        streetAddress: street,
        zip: postalCode,
      })
    } catch {
      setShowAppDialog(true)
    }
  }

  return (
    <div className="mb-4">
      <Input
        autoFocus={isAutoFocus}
        data-testid="input_homeAddressSearchBar"
        label={labels.streetAddress}
        loading={isSearching}
        onChange={(event) => onChangeStreetAddress(event.target.value)}
        value={streetAddress}
      />

      {addressAutocompleteSuggestion.length > 0 && (
        <ListContainer>
          <List>
            {addressAutocompleteSuggestion.map((address, index) => (
              <List.Item
                data-testid={`listItem_homeAddressSuggestion${index + 1}`}
                key={`address-list-item-${index + 1}`}
                bullet={{
                  icon: 'location',
                  iconColor:
                    selectedAddress === (address && address.placeId)
                      ? 'primary'
                      : 'tertiary',
                }}
                onClick={() => onClickSelect(address.placeId)}
              >
                <List.Label main={address.mainText} sub={address.subText} />
              </List.Item>
            ))}
          </List>
        </ListContainer>
      )}
    </div>
  )
}

export { InputAddressAutocomplete }
