import ICheckingAccount from '@root/types/CheckingAccount'
import { https } from '@root/utils'

interface IFetchCheckingAccount {
  data: ICheckingAccount
}

const fetchCheckingAccount = async (): Promise<IFetchCheckingAccount> => {
  const response = await (await https()).get('/checking-account/info')
  return response.data
}

const roarMoneyApi = {
  fetchCheckingAccount,
}

export default roarMoneyApi
