import dayjs from 'dayjs'
import { loansApi } from '@root/api'
import { TPayFrequency, IPayDate } from '@root/types/PaymentSchedule'
import { formats } from '@root/utils'

/**
 * @usage: For 'Weekly' or 'Bi-Weekly' pay frequency
 * @description: To calculate the pay dates for 'Weekly' or 'Bi-Weekly' pay frequency
 * @param {payFrequency}: The pay frequency
 * @param {payFrequencyType}: The pay frequency type
 * @param {lastPayCheckDate}: The last pay date for the user
 */
const calculatePayDatesWeeklyOrBiWeekly = async (
  payFrequency: TPayFrequency,
  lastPayCheckDate: string
): Promise<IPayDate> => {
  const splitLastPayCheckDate = lastPayCheckDate.replace(/\,/g, '').split(' ')

  const lastPayCheckDateDay = splitLastPayCheckDate[2]
  const lastPayCheckDateMonth = new Date(lastPayCheckDate).getMonth()
  const lastPayCheckDateYear =
    lastPayCheckDateMonth === 11 ? dayjs().year() - 1 : dayjs().year()

  const lastPayDate = dayjs(
    new Date(
      lastPayCheckDateYear,
      lastPayCheckDateMonth,
      parseInt(lastPayCheckDateDay, 10)
    )
  )

  const scale = payFrequency === 'Bi-Weekly' ? 2 : 1

  const finalPayDates = await getFinalPayDates({
    lastPayDate,
    nextPayDate: lastPayDate.clone().add(1 * scale, 'week'),
    secondPayDate: lastPayDate.clone().add(2 * scale, 'week'),
  })

  return finalPayDates
}

/**
 * @usage: For 'Semi-Monthly (Twice a Month)' pay frequency
 * @description: To calculate the pay dates for 'Semi-Monthly (Twice a Month)' pay frequency, where first date is specific and second date is last
 * @param {firstPayCheckDate}: The first pay date for the user
 */
const calculatePayDatesFirstSpecificSecondLast = async (
  firstPayCheckDate: number
): Promise<IPayDate> => {
  const todayDate = dayjs().date()
  const lastPayDate = dayjs().date(firstPayCheckDate)
  let payDates

  if (todayDate >= firstPayCheckDate) {
    payDates = {
      lastPayDate,
      nextPayDate: lastPayDate.date(lastPayDate.daysInMonth()),
      secondPayDate: lastPayDate.add(1, 'M'),
    }
  } else {
    payDates = {
      lastPayDate: lastPayDate.subtract(1, 'M').endOf('month'),
      nextPayDate: lastPayDate,
      secondPayDate: lastPayDate.endOf('month'),
    }
  }

  const finalPayDates = await getFinalPayDates(payDates)

  return finalPayDates
}

/**
 * @usage: For 'Semi-Monthly (Twice a Month)' pay frequency
 * @description: To calculate the pay dates for 'Semi-Monthly (Twice a Month)' pay frequency, where first and second date is specific
 * @param {firstPayCheckDate}: The first pay date for the user
 * @param {secondPayCheckDate}: The second pay date for the user
 */
const calculatePayDatesFirstSpecificSecondSpecific = async (
  firstPayCheckDate: number,
  secondPayCheckDate: number
): Promise<IPayDate> => {
  const todayDate = dayjs().date()
  let payDates

  if (todayDate >= firstPayCheckDate && todayDate < secondPayCheckDate) {
    payDates = {
      lastPayDate: dayjs().date(firstPayCheckDate),
      nextPayDate: dayjs().date(secondPayCheckDate),
      secondPayDate: dayjs().add(1, 'M').date(firstPayCheckDate),
    }
  } else if (todayDate < firstPayCheckDate) {
    payDates = {
      lastPayDate: dayjs().subtract(1, 'M').date(secondPayCheckDate),
      nextPayDate: dayjs().date(firstPayCheckDate),
      secondPayDate: dayjs().date(secondPayCheckDate),
    }
  } else {
    payDates = {
      lastPayDate: dayjs().date(secondPayCheckDate),
      nextPayDate: dayjs().add(1, 'M').date(firstPayCheckDate),
      secondPayDate: dayjs().add(1, 'M').date(secondPayCheckDate),
    }
  }

  const finalPayDates = await getFinalPayDates(payDates)

  return finalPayDates
}

const generateWeekDayOfMonth = (
  date: dayjs.Dayjs,
  week: number,
  day: number
): dayjs.Dayjs => {
  if (week === -1) {
    date.endOf('month')

    if (date.day() < day || date.day() === 0) {
      date.subtract(1, 'w')
    }
  } else {
    date.startOf('month')

    if (date.day() > day) {
      date.add(week + 1, 'w')
    } else {
      date.add(week, 'w')
    }
  }

  return date.day(day)
}

/**
 * @usage: For 'Monthly' pay frequency
 * @description: To calculate the pay dates for 'Monthly'(Twice a Month)' pay frequency, where the pay day is the same week of the month
 * @param {week}: The week of the month. Example: First Week, Fourth Week, and so on...
 * @param {dayOfWeek}: The day of the week. Example: Monday, Tuesday and so on...
 */
const calculatePayDatesSameWeekOfMonth = async (
  week: string,
  dayOfWeek: string
): Promise<IPayDate> => {
  const weeks = [
    'First week',
    'Second week',
    'Third week',
    'Fourth week',
    'Last week',
  ]
  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const todayDate = dayjs().date()
  const weekNum = weeks.indexOf(week) !== 4 ? weeks.indexOf(week) : -1
  const day = weekDays.indexOf(dayOfWeek)
  const payCheckDate = generateWeekDayOfMonth(dayjs(), weekNum, day)
  let payDates

  if (todayDate >= payCheckDate.date()) {
    payDates = {
      lastPayDate: payCheckDate,
      nextPayDate: generateWeekDayOfMonth(dayjs().add(1, 'M'), weekNum, day),
      secondPayDate: generateWeekDayOfMonth(dayjs().add(2, 'M'), weekNum, day),
    }
  } else {
    payDates = {
      lastPayDate: generateWeekDayOfMonth(
        dayjs().subtract(1, 'M'),
        weekNum,
        day
      ),
      nextPayDate: payCheckDate,
      secondPayDate: generateWeekDayOfMonth(dayjs().add(1, 'M'), weekNum, day),
    }
  }

  const finalPayDates = await getFinalPayDates(payDates)

  return finalPayDates
}

/**
 * @usage: For 'Monthly' pay frequency
 * @description: To calculate the pay dates for 'Monthly' pay frequency, where the pay day is the same date of the month
 * @param {dateOfMonth}: The date of the month
 */
const calculatePayDatesSameDateOfMonth = async (
  dateOfMonth: number
): Promise<IPayDate> => {
  const todayDate = dayjs().date()
  const payCheckDate = dayjs().date(dateOfMonth)
  let payDates

  if (todayDate >= dateOfMonth) {
    payDates = {
      lastPayDate: payCheckDate,
      nextPayDate: payCheckDate.add(1, 'M'),
      secondPayDate: payCheckDate.add(2, 'M'),
    }
  } else {
    payDates = {
      lastPayDate: payCheckDate.subtract(1, 'M'),
      nextPayDate: payCheckDate,
      secondPayDate: payCheckDate.add(1, 'M'),
    }
  }

  const finalPayDates = await getFinalPayDates(payDates)

  return finalPayDates
}

/**
 * @usage: For 'Monthly' pay frequency
 * @description: To calculate the pay dates for 'Monthly' pay frequency, where the pay day is the last day of the month
 */
const calculatePayDatesLastDayOfMonth = async (): Promise<IPayDate> => {
  const todayDate = dayjs().date()
  const endOfTheMonth = dayjs().endOf('month')
  let payDates

  if (todayDate === endOfTheMonth.date()) {
    payDates = {
      lastPayDate: endOfTheMonth,
      nextPayDate: endOfTheMonth.add(1, 'M').endOf('month'),
      secondPayDate: endOfTheMonth.add(2, 'M').endOf('month'),
    }
  } else {
    payDates = {
      lastPayDate: endOfTheMonth.subtract(1, 'M').endOf('month'),
      nextPayDate: endOfTheMonth,
      secondPayDate: endOfTheMonth.add(1, 'M').endOf('month'),
    }
  }

  const finalPayDates = await getFinalPayDates(payDates)

  return finalPayDates
}

/**
 * @description: To get the final pay dates after checking the holidays
 * @param {temporaryPayDates}: The initial pay dates before checking the holidays
 * @returns {finalPayDates}: Returns the final pay dates after checking the holidays
 */
const getFinalPayDates = async (
  temporaryPayDates: IPayDate
): Promise<IPayDate> => {
  try {
    const from = formats.date(dayjs().subtract(1, 'M'), 'YYYY-MM-DD')
    const to = formats.date(dayjs().add(4, 'M'), 'YYYY-MM-DD')

    const { data } = await loansApi.getHoliday(from, to)

    if (data?.holidays.length > 0) {
      const { holidays } = data
      const finalPayDates: IPayDate = {
        lastPayDate: undefined,
        nextPayDate: undefined,
        secondPayDate: undefined,
      }

      Object.keys(temporaryPayDates).map((key) => {
        const initialPayDateKey = key as keyof IPayDate
        let finalPayDate = temporaryPayDates[initialPayDateKey]
        if (finalPayDate) {
          let dayNumber = finalPayDate.day()
          let formattedFinalPayDate = formats.date(finalPayDate, 'YYYY-MM-DD')

          while (
            dayNumber === 0 ||
            dayNumber === 6 ||
            holidays.indexOf(formattedFinalPayDate) >= 0
          ) {
            finalPayDate = finalPayDate.subtract(1, 'd')
            dayNumber = finalPayDate.day()
            formattedFinalPayDate = formats.date(finalPayDate, 'YYYY-MM-DD')
          }

          finalPayDates[initialPayDateKey] = finalPayDate
        }
      })

      return finalPayDates
    } else {
      return Promise.reject(new Error('HOLIDAY_NOT_FOUND'))
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export {
  calculatePayDatesWeeklyOrBiWeekly,
  calculatePayDatesFirstSpecificSecondLast,
  calculatePayDatesFirstSpecificSecondSpecific,
  calculatePayDatesSameWeekOfMonth,
  calculatePayDatesSameDateOfMonth,
  calculatePayDatesLastDayOfMonth,
}
