import { Body, Dialog, List, Title } from '@moneylion/mlds-web'
import { useEffect } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import { useUIStore } from '@root/store'
import labels from './labels'

const ImageContainer = styled.div`
  background-color: ${({ theme }) => theme.color?.BACKGROUND_PAGE_ACCENT};
  border-radius: 16px;
  text-align: center;
  width: 100%;
`

const CheckListContainer = styled.div`
  background-color: ${({ theme }) => theme.color?.BACKGROUND_PAGE};
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
`

const RoarMoneyBenefitDialog = (): JSX.Element | null => {
  const showRoarMoneyBenefitDialog = useUIStore(
    (state) => state.showRoarMoneyBenefitDialog
  )
  const setShowRoarMoneyBenefitDialog = useUIStore(
    (state) => state.setShowRoarMoneyBenefitDialog
  )

  useEffect(() => {
    if (showRoarMoneyBenefitDialog) {
      showDialog()
      setShowRoarMoneyBenefitDialog(false)
    }
  }, [showRoarMoneyBenefitDialog])

  const showDialog = (): void => {
    return window.showDialogs(
      <Dialog width={448}>
        {({ closeDialog }) => (
          <>
            <Title size="medium">{labels.label}</Title>
            <ImageContainer>
              <Image
                src="/assets/svgs/roarmoney.svg"
                alt="RoarMoney Benefits"
                width={115}
                height={133}
              />
            </ImageContainer>
            <Title className="text-center" size="small">
              {labels.title}
            </Title>
            <CheckListContainer className="mb-3">
              <List>
                {labels.checkList.map((checkItem, index) => (
                  <List.Item
                    bullet={{ icon: 'checkCircle' }}
                    disabledEffects={['hover', 'focus', 'active', 'visited']}
                    divider="none"
                    key={`roarmoney-benefit-check-item-${index + 1}`}
                  >
                    <List.Label main={checkItem} />
                  </List.Item>
                ))}
              </List>
            </CheckListContainer>
            {labels.disclaimerList.map((disclaimer, index) => (
              <Body
                className="mb-3"
                color="secondary"
                key={`roarmoney-benefit-disclaimer-${index + 1}`}
                size="small"
                style={{ width: '100%' }}
              >
                {disclaimer}
              </Body>
            ))}
            <Dialog.Button.Container direction="column">
              <Dialog.Button onClick={closeDialog}>Close</Dialog.Button>
            </Dialog.Button.Container>
          </>
        )}
      </Dialog>
    )
  }

  return null
}

export { RoarMoneyBenefitDialog }
