import { create } from 'zustand'
import { cryptoApi } from '@root/api'
import Crypto from '@root/types/Crypto'

interface ICrypto {
  providerId: string
  totalAssetInFiat: number
}

interface CryptoInitialState {
  crypto?: ICrypto | null
}
export interface CryptoStore extends CryptoInitialState {
  getCryptoDetails: () => Promise<Crypto | undefined>
  reset: () => void
}

const initialState: CryptoInitialState = {
  crypto: undefined,
}

const useCryptoStore = create<CryptoStore>()((set) => ({
  ...initialState,
  getCryptoDetails: async () => {
    const cryptoAccount = await cryptoApi.fetchCrypto()

    if (cryptoAccount.data && cryptoAccount.data.providerId) {
      set({
        crypto: {
          providerId: cryptoAccount?.data.providerId,
          totalAssetInFiat: cryptoAccount?.data.totalAssetInFiat,
        },
      })
    } else {
      set({ crypto: null })
    }

    return cryptoAccount.data
  },
  reset: () => {
    set(initialState)
  },
}))

export { useCryptoStore }
