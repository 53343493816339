export type TOnboardingFlow =
  | 'instacash'
  | 'roarmoney'
  | 'crypto'
  | 'investment'
  | 'cbplus'
  | 'organic'

const getOnboardingFlow = (pathname: string): TOnboardingFlow => {
  const splitPathname = pathname.split('/')
  const flowType = splitPathname[2]

  if (flowType.includes('instacash')) return 'instacash'
  if (flowType.includes('crypto')) return 'crypto'
  if (flowType.includes('roarmoney')) return 'roarmoney'
  if (flowType.includes('investment')) return 'investment'
  if (flowType.includes('cbplus')) return 'cbplus'
  return 'organic'
}

export { getOnboardingFlow }
