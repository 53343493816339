import { TOnboardingFlow } from '@root/utils'

const organicLabels = [
  'Get started with MoneyLion',
  'Personal information',
  'Complete account creation',
  'Welcome to MoneyLion!',
]

const roarmoneyLabels = [
  'Basic information',
  'Personal information',
  'Open RoarMoney account',
  'Download app',
]

const instacashLabels = [
  'Basic information',
  'Link your bank account',
  'Personal information',
  'Add a repayment method',
  'Get up to $500 in your bank account',
]

const cbplusLabels = [
  'Basic information',
  'Personal information',
  'Open Credit Builder Plus account',
  'Download app',
]

const investmentLabels = [
  'Basic information',
  'Personal information',
  'Open Investment account',
  'Download app',
]

export const labels: Record<TOnboardingFlow, string[]> = {
  organic: organicLabels,
  instacash: instacashLabels,
  roarmoney: roarmoneyLabels,
  cbplus: cbplusLabels,
  crypto: roarmoneyLabels,
  investment: investmentLabels,
}
