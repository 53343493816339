/**
 * Test Environment Variables is the same as staging
 */
import rcDefaultsStaging from '../constants/remote_config_defaults_stag.json'
import rcDefaultsProduction from '../constants/remote_config_defaults_prod.json'

const API_URL = {
  production: 'https://dashboard-api.moneylion.com',
  development: 'https://staging-dashboard-api.moneylion.io',
  test: 'https://staging-dashboard-api.moneylion.io',
}

const AUTH_API_URL = {
  production: 'https://dash-auth.moneylion.com',
  development: 'https://staging-dash-auth.moneylion.io',
  test: 'https://staging-dash-auth.moneylion.io',
}

const RECAPTCHA_KEY = {
  production: '6LdfBpAbAAAAACsU3gHYWSagHNyMayE0bnYMh3Hl',
  development: '6LdfBpAbAAAAACsU3gHYWSagHNyMayE0bnYMh3Hl',
  test: '6LdfBpAbAAAAACsU3gHYWSagHNyMayE0bnYMh3Hl',
}

const SEGMENT_KEY = {
  production: 'BnDDScXkY4Va6zb4c52xcrsOGUsZnYqw',
  development: '6HtlXWlr2rreppnfcexweHbBn7lxGDOv',
  test: '6HtlXWlr2rreppnfcexweHbBn7lxGDOv',
}

const MONEYLION_HOMEPAGE_URL = {
  production: 'https://moneylion.com',
  development: 'https://staging-www.moneylion.com',
  test: 'https://staging-www.moneylion.com',
}

const MONEYLION_DASHBOARD_URL = {
  production: 'https://web.moneylion.com',
  development: 'https://staging-web.moneylion.io',
  test: 'https://staging-web.moneylion.io',
}

const MONEYLION_MARKETPLACE_URL = {
  production: 'https://marketplace-web.moneylion.com',
  development: 'https://staging-marketplace-web.moneylion.io',
  test: 'https://staging-marketplace-web.moneylion.io',
}

const ONBOARDING_WEB_URL = {
  production: 'https://signup.moneylion.com',
  development: 'https://staging-signup.moneylion.io',
  test: 'https://staging-signup.moneylion.io',
}

const APPS_FLYER_PBA_KEY = {
  production: '61d6e892-0996-4cfd-a22f-750fe6590225',
  development: '5be8ccfe-a94e-4352-bd9e-e955304f6d69',
  test: '5be8ccfe-a94e-4352-bd9e-e955304f6d69',
}

const APPS_FLYER_WEB_KEY = {
  production: '5aeb8345-7c77-4a09-81ee-5aaefc79c857',
  development: 'fc9ec3fb-a35d-40e2-94b7-e2ee247b15e8',
  test: 'fc9ec3fb-a35d-40e2-94b7-e2ee247b15e8',
}

const LOAN_WIDGET_SCRIPT_URL = {
  production: 'https://prospects-widgets.moneylion.com/v2/personal_loan.js',
  development:
    'https://preview-prospects-widgets.moneylion.io/v2/personal_loan.js',
  test: 'https://preview-prospects-widgets.moneylion.io/v2/personal_loan.js',
}

const DATADOG = {
  production: {
    appId: '176722cd-2fe0-45c1-8bad-e542014640b4',
    clientToken: 'pubf0581e3a97e8a0b44ac62464bb6fff78',
    service: 'new-web-dashboard-production',
  },
  development: {
    appId: 'e946cab0-b2ec-429a-9db8-f5139b8a3524',
    clientToken: 'pub8a0f9722043c5375e856c39f489b46a6',
    service: 'new-web-dashboard-staging',
  },
  test: {
    appId: 'e946cab0-b2ec-429a-9db8-f5139b8a3524',
    clientToken: 'pub8a0f9722043c5375e856c39f489b46a6',
    service: 'new-web-dashboard-staging',
  },
}

const SPLIT_IO_KEY = {
  production: 'k797qnsvobkb55gu0724rpqjmpprpqf56ap8',
  development: '2qrfrdta1h3er2nm00kt7qf6b65kfh0kkuho',
  test: '2qrfrdta1h3er2nm00kt7qf6b65kfh0kkuho',
}

const ONE_LINKS_URL = 'https://moneylion.onelink.me/3v37/'

const ONE_LINKS = {
  default: `${ONE_LINKS_URL}49eef5b6`,
  roarmoney: `${ONE_LINKS_URL}ccf26ca7`,
  instacash: `${ONE_LINKS_URL}ff40a050`,
  cbplus: `${ONE_LINKS_URL}c52610a2`,
  investment: `${ONE_LINKS_URL}d42531d4`,
  crypto: `${ONE_LINKS_URL}28dc8ea6`,
}

const ONE_LINKS_GENERIC = {
  instacash: `${ONE_LINKS_URL}m1dcpm6j`,
  roarmoney: `${ONE_LINKS_URL}vpfu9b96`,
  cbplus: `${ONE_LINKS_URL}azdi303l`,
  investment: `${ONE_LINKS_URL}shhqgof6`,
  crypto: `${ONE_LINKS_URL}dhfu98dt`,
  rewards: `${ONE_LINKS_URL}4xqd48o6`,
  activeInvestment: `${ONE_LINKS_URL}wdoernze`,
}

const ONE_LINKS_ONBOARDING = {
  generic: {
    default: `${ONE_LINKS_URL}o79ol5ih`,
    cbplus: `${ONE_LINKS_URL}c52610a2`,
    crypto: `${ONE_LINKS_URL}28dc8ea6`,
    instacash: `${ONE_LINKS_URL}cu5rx6yw?variant=2`,
    instacashRoarmoney: `${ONE_LINKS_URL}s16ysmhx?variant=5`,
    investment: `${ONE_LINKS_URL}d42531d4`,
    roarmoney: `${ONE_LINKS_URL}lswlpjdx`,
  },
  webToAppModal: {
    default: `${ONE_LINKS_URL}67f0cnsm`,
    cbplus: `${ONE_LINKS_URL}9jgufl8v`,
    crypto: `${ONE_LINKS_URL}ifedr9bk`,
    instacash: `${ONE_LINKS_URL}vfplcnmh`,
    investment: `${ONE_LINKS_URL}f2qdfgpv`,
    roarmoney: `${ONE_LINKS_URL}wrw5ktxf`,
  },
}

const MONEYLION_SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/moneylion',
  twitter: 'https://twitter.com/moneylion',
  linkedin: 'https://www.linkedin.com/company/moneylion',
}

const MONEYLION_REFERRAL_URL = {
  production: 'https://mlion.us',
  development: 'https://staging-www.moneylion.com/u',
  test: 'https://staging-www.moneylion.com/u',
}

const SMARTLOOK = {
  production: 'f77776daefd43a4ad1e41d01e77dda45e2f5abda',
  development: '43046260adb2eb0fc8b3391876ec9d933a32b51d',
  test: '43046260adb2eb0fc8b3391876ec9d933a32b51d',
}

const MINIMUM_FETCH_INTERVAL = {
  production: 1200000,
  development: 60000,
  test: 60000,
}

const FIREBASE_CONFIG = {
  development: {
    apiKey: 'AIzaSyAS4oQ-fE93xOWz-3MOhx6VnesoJOkpStQ',
    authDomain: 'web-app-staging-4aa34.firebaseapp.com',
    projectId: 'web-app-staging-4aa34',
    storageBucket: 'web-app-staging-4aa34.appspot.com',
    messagingSenderId: '453005526016',
    appId: '1:453005526016:web:9869087824d52dc4cd04e5',
    measurementId: 'G-LGD60F6SC3',
  },
  test: {
    apiKey: 'AIzaSyAS4oQ-fE93xOWz-3MOhx6VnesoJOkpStQ',
    authDomain: 'web-app-staging-4aa34.firebaseapp.com',
    projectId: 'web-app-staging-4aa34',
    storageBucket: 'web-app-staging-4aa34.appspot.com',
    messagingSenderId: '453005526016',
    appId: '1:453005526016:web:9869087824d52dc4cd04e5',
    measurementId: 'G-LGD60F6SC3',
  },
  production: {
    apiKey: 'AIzaSyCon_PuvdXQ9NvEfXNKC3fqDjz_IE2EcFE',
    authDomain: 'web-app-production-cdfb9.firebaseapp.com',
    projectId: 'web-app-production-cdfb9',
    storageBucket: 'web-app-production-cdfb9.appspot.com',
    messagingSenderId: '123602647470',
    appId: '1:123602647470:web:2a7fb54915241a932d703f',
    measurementId: 'G-DH096ZE8GZ',
  },
}

const COMMON_AUTH_CALLBACK_URL = {
  production: 'https://web.moneylion.com/auth/callback',
  development: 'https://staging-web.moneylion.io/auth/callback',
  test: 'https://staging-web.moneylion.io/auth/callback',
}

const COMMON_AUTH_DOMAIN = {
  production: 'https://accounts.moneylion.com',
  development: 'https://accounts.moneylion.dev',
  test: 'https://accounts.moneylion.dev',
}

const COMMON_AUTH_CLIENT_ID = {
  production: '',
  development: 'd7d1d0cb-e7d7-4ebb-b5e9-5eeb9934d9cd',
  test: 'd7d1d0cb-e7d7-4ebb-b5e9-5eeb9934d9cd',
}

const COMMON_AUTH_CLIENT_SECRET = {
  production: '',
  development:
    'gRAGIsM6z7JRK7eZVZEwcZBC1zQ2LiVaI83d6AwhvkMKgP8rf5EkcVvOtuQxo9A3',
  test: 'gRAGIsM6z7JRK7eZVZEwcZBC1zQ2LiVaI83d6AwhvkMKgP8rf5EkcVvOtuQxo9A3',
}

const COMMON_AUTH_SCOPE = {
  production: '',
  development: 'profile',
  test: 'profile',
}

const FIREBASE_DEFAULT_CONFFIG = {
  development: rcDefaultsStaging,
  test: rcDefaultsStaging,
  production: rcDefaultsProduction,
}

const SPLIT_IO_KEY_ONBOARDING = {
  production: 'a4df9ppvaj9u8cfvjb5es4b8fhherkcsbglg',
  development: 'j0j3d8r974q7gvqp29p2m7nhehnbgar2hc1g',
  test: 'j0j3d8r974q7gvqp29p2m7nhehnbgar2hc1g',
}

const APP_ENV = (process.env.NEXT_PUBLIC_APP_ENV || process.env.NODE_ENV) as
  | 'production'
  | 'development'
  | 'test'

const APP_VERSION = process.env.APP_VERSION as string
const MONEYLION_HELP_LINK = 'https://help.moneylion.com'
const COMMUNICATION_PREFERENCES_URL =
  'https://preferences.moneylion.com/moneylion/preferences'
const MONEYLION_WEB_CDN = 'https://cdn-web.moneylion.com'
const MONEYLION_DOWNLOAD_APP_ROARMONEY = 'https://app.moneylion.com/roarmoney'

const ENV = {
  APP_ENV,
  APP_VERSION,
  API_URL: API_URL[APP_ENV],
  AUTH_API_URL: AUTH_API_URL[APP_ENV],
  COMMON_AUTH_CALLBACK_URL: COMMON_AUTH_CALLBACK_URL[APP_ENV],
  COMMON_AUTH_DOMAIN: COMMON_AUTH_DOMAIN[APP_ENV],
  COMMON_AUTH_CLIENT_ID: COMMON_AUTH_CLIENT_ID[APP_ENV],
  COMMON_AUTH_CLIENT_SECRET: COMMON_AUTH_CLIENT_SECRET[APP_ENV],
  COMMON_AUTH_SCOPE: COMMON_AUTH_SCOPE[APP_ENV],
  RECAPTCHA_KEY: RECAPTCHA_KEY[APP_ENV],
  SEGMENT_KEY: SEGMENT_KEY[APP_ENV],
  MONEYLION_HOMEPAGE_URL: MONEYLION_HOMEPAGE_URL[APP_ENV],
  MONEYLION_DASHBOARD_URL: MONEYLION_DASHBOARD_URL[APP_ENV],
  ONBOARDING_WEB_URL: ONBOARDING_WEB_URL[APP_ENV],
  MONEYLION_MARKETPLACE_URL: MONEYLION_MARKETPLACE_URL[APP_ENV],
  APPS_FLYER_PBA_KEY: APPS_FLYER_PBA_KEY[APP_ENV],
  APPS_FLYER_WEB_KEY: APPS_FLYER_WEB_KEY[APP_ENV],
  LOAN_WIDGET_SCRIPT_URL: LOAN_WIDGET_SCRIPT_URL[APP_ENV],
  DATADOG: DATADOG[APP_ENV],
  SPLIT_IO_KEY: SPLIT_IO_KEY[APP_ENV],
  ONE_LINKS,
  ONE_LINKS_GENERIC,
  ONE_LINKS_ONBOARDING,
  MONEYLION_WEB_CDN,
  MONEYLION_DOWNLOAD_APP_ROARMONEY,
  MONEYLION_SOCIAL_LINKS,
  MONEYLION_HELP_LINK,
  COMMUNICATION_PREFERENCES_URL,
  MONEYLION_REFERRAL_URL: MONEYLION_REFERRAL_URL[APP_ENV],
  SMARTLOOK: SMARTLOOK[APP_ENV],
  MINIMUM_FETCH_INTERVAL_MILLIS: MINIMUM_FETCH_INTERVAL[APP_ENV],
  FIREBASE_CONFIG: FIREBASE_CONFIG[APP_ENV],
  FIREBASE_DEFAULT_CONFFIG: FIREBASE_DEFAULT_CONFFIG[APP_ENV],
  SPLIT_IO_KEY_ONBOARDING: SPLIT_IO_KEY_ONBOARDING[APP_ENV],
}

export default ENV
