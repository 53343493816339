import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { bankApi } from '@root/api'
import { useUIStore } from '@root/store'
import IBank from '@root/types/Bank'
import { BankSource } from '@root/api/bankApi'

type FinicityEventType =
  | 'onError'
  | 'onCancel'
  | 'onRoute'
  | 'onUser'
  | 'onLoad'

interface InitializeFinicityProps {
  source: BankSource
  onSuccess?: (bankId: string) => void
  onExit?: () => void
  setIsLinking?: (value: boolean) => void
}

interface LaunchFinicityPayload {
  connectUrl: string
  bvMainSession: string
  bvSubSession: string
  bank: IBank
  isRelinkBank: boolean
}

const useFinicity = ({
  source,
  onSuccess = () => void 0,
  onExit = () => void 0,
  setIsLinking,
}: InitializeFinicityProps) => {
  const theme = useTheme()
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)

  const [bank, setBank] = useState<IBank>({} as IBank)
  const [isRelinkBank, setIsRelinkBank] = useState<boolean>(false)
  const [bvMainSession, setBvMainSession] = useState<string>('')
  const [bvSubSession, setBvSubSession] = useState<string>('')
  const [connectUrl, setConnectUrl] = useState<string>('')

  useEffect(() => {
    if (window.finicityConnect && connectUrl) {
      window.finicityConnect.launch(connectUrl, {
        overlay: theme.color?.BACKGROUND_OVERLAY_DARK,
        success: handleSuccess,
        cancel: (event: any) => handleFinicityEvent('onCancel', event?.data),
        error: (event: any) => handleFinicityEvent('onError', event),
        loaded: (event: any) => handleFinicityEvent('onLoad', event?.data),
        route: (event: any) => handleFinicityEvent('onRoute', event?.data),
        user: (event: any) => handleFinicityEvent('onUser', event?.data),
      })
    }
  }, [connectUrl])

  const launchFinicity = (payload: LaunchFinicityPayload) => {
    setIsRelinkBank(payload.isRelinkBank)
    setBvMainSession(payload.bvMainSession)
    setBvSubSession(payload.bvSubSession)
    setBank(payload.bank)
    setConnectUrl(payload.connectUrl)
  }

  const removeFinicityConnect = () => {
    setConnectUrl('')

    if (window.finicityConnect) {
      window.finicityConnect.destroy()
    }
  }

  const handleSuccess = async () => {
    try {
      setIsLinking && setIsLinking(true)
      const resp = await bankApi.finicityLinkSuccess({
        bvMainSession,
        bvSubSession,
        institutionProviderId: bank.institutionProviderId,
        source,
      })

      onSuccess(resp.data.bankId)
    } catch {
      setIsLinking && setIsLinking(false)
      setShowAppDialog(true)
    }
  }

  const handleFinicityEvent = (type: FinicityEventType, metadata: any) => {
    if (type === 'onError') {
      if (metadata?.code === 201) {
        handleSuccess()
      } else {
        onExit()
      }
    } else if (type === 'onCancel') {
      onExit()
    }

    bankApi.finicityConnectEvent({
      bvMainSession,
      bvSubSession,
      institutionProviderId: bank.institutionProviderId,
      metadata,
      relink: isRelinkBank,
      type,
      source,
    })
  }

  return {
    launchFinicity,
    removeFinicityConnect,
  }
}

export default useFinicity
