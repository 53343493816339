import React, { useContext, useEffect } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { OnboardingHeaderContext } from '@root/layouts'
import { BackButton } from '@root/components/BackButton'
import { PROTECTED_PAGES_PATHS } from '@root/constants'
import {
  OnboardingHeaderCol as HeaderCol,
  OnboardingHeaderContainer as HeaderContainer,
  OnboardingStepperContainer as StepperContainer,
} from './OnboardingHeader.styles'
import { CloseButton, FaqButton, Stepper } from '@onboarding/components'
import headerLogo from 'public/assets/svgs/ml-logo-dark.svg'

export interface OnboardingHeaderProps {
  hideBackButton?: boolean
  onClickBack?: () => void
  faqLink?: string
  showCloseButton?: boolean
  onClickClose?: () => void
  showRightCloseButton?: boolean
}

const OnboardingHeader: React.FunctionComponent<OnboardingHeaderProps> = ({
  hideBackButton = false,
  onClickBack,
  faqLink = '',
  showCloseButton = false,
  onClickClose = () => window.open(PROTECTED_PAGES_PATHS.offers, '_self'),
  showRightCloseButton = false,
}) => {
  const { setHeaderOptions } = useContext(OnboardingHeaderContext)
  const router = useRouter()

  useEffect(() => {
    setHeaderOptions({
      hideBackButton: false,
      onClickBack: undefined,
      faqLink: '',
      showCloseButton: false,
      onClickClose: undefined,
      showRightCloseButton: false,
    })
  }, [router.pathname])

  const renderBackButton = (): JSX.Element | false =>
    !hideBackButton && (
      <BackButton
        onClickBack={onClickBack}
        withBackgroundColor={false}
        size={28}
      />
    )

  const renderCloseButton = (): JSX.Element | false =>
    showCloseButton && (
      <CloseButton
        onClickClose={onClickClose}
        withBackgroundColor={false}
        size={28}
      />
    )

  const renderFaqButton = (): JSX.Element | false =>
    faqLink !== '' && (
      <FaqButton faqLink={faqLink} withBackgroundColor={false} size={28} />
    )

  const renderRightCloseButton = (): JSX.Element | false =>
    showRightCloseButton && (
      <CloseButton
        onClickClose={onClickClose}
        withBackgroundColor={false}
        size={28}
      />
    )

  return (
    <>
      <HeaderContainer className="row">
        <HeaderCol>{renderBackButton() || renderCloseButton()}</HeaderCol>
        <HeaderCol>
          <div className="logo hidden-desktop">
            <Image
              src={headerLogo}
              alt="MoneyLion Logo"
              width={180}
              height={54}
              priority
            />
          </div>
        </HeaderCol>
        <HeaderCol className="right-col">
          {renderRightCloseButton() || renderFaqButton()}
        </HeaderCol>
      </HeaderContainer>
      <StepperContainer className="hidden-desktop">
        <Stepper type="mobile" />
      </StepperContainer>
    </>
  )
}

export { OnboardingHeader }
