import { NextRouter } from 'next/router'
import { IAppDialogOptions, TModalButton } from '@root/store/uiStore'
import labels from '@root/modules/AppDialog/labels'

const gotItButtonOptions: TModalButton = {
  label: labels.gotItButton,
  onClick: (closeDialog: () => void) => closeDialog(),
  color: 'primary',
}

const defaultErrorOptions: IAppDialogOptions = {
  title: labels.title,
  text: [labels.text],
  buttons: [gotItButtonOptions],
  buttonDirection: 'row',
}

const getResetPasswordErrorMessageOptions = (
  code: string,
  router: NextRouter
): IAppDialogOptions => {
  switch (code) {
    case 'PCF0001':
      return {
        title: 'Oops...',
        text: [
          'Your previous password reset request has expired. Please request a new one to continue with password reset.',
        ],
        buttons: [
          {
            ...gotItButtonOptions,
            onClick: (close) => {
              router.push('/login/forgotpassword')
              close()
            },
          },
        ],
        buttonDirection: 'row',
      }
    case 'PCF0003':
      return {
        ...defaultErrorOptions,
        text: [
          'You have used this password previously, please set another one which has not been used before.',
        ],
      }
    case 'PCF0006':
      return { ...defaultErrorOptions, text: ['Sorry. Password is invalid.'] }
    case 'PCF0011':
      return {
        title: 'Oops...',
        text: [
          'You have reach the maximum number of password change allowed daily.',
        ],
        buttons: [
          {
            ...gotItButtonOptions,
            onClick: (close) => {
              router.push('/login/forgotpassword')
              close()
            },
          },
        ],
        buttonDirection: 'row',
      }
    case 'PCF0012':
      return {
        ...defaultErrorOptions,
        text: ['Sorry. Password length must be between 8 and 128.'],
      }
    case 'PCF0013':
      return {
        ...defaultErrorOptions,
        text: ['Please fill in all the fields.'],
      }
    default:
      return defaultErrorOptions
  }
}

export { getResetPasswordErrorMessageOptions }
