import { TColor } from '@moneylion/mlds-web'
import { useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { useTheme } from 'styled-components'

type ConfettiProps = {
  colors?: TColor[]
  width?: number
}

const ConfettiAnimation = ({ colors, width }: ConfettiProps): JSX.Element => {
  const theme = useTheme()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const draw = (ctx: CanvasRenderingContext2D): void => {
    ctx.beginPath()
    ctx.lineWidth = 8
    ctx.lineJoin = 'round'
    ctx.moveTo(0, 0)
    ctx.lineTo(1, 10)
    ctx.lineTo(2, 1)
    ctx.closePath()
    ctx.stroke()
    ctx.fill()
  }

  return (
    <Confetti
      canvasRef={canvasRef}
      colors={colors ? colors : theme.color && [theme.color?.CONTENT_ACCENT]}
      drawShape={(ctx) => draw(ctx)}
      initialVelocityY={{ min: 1, max: 5 }}
      numberOfPieces={120}
      recycle={false}
      style={{ zIndex: 1000 }}
      width={width}
    />
  )
}

const Conf = ({ colors, width }: ConfettiProps): JSX.Element => {
  const [confetti, setConfetti] = useState<JSX.Element | null>(null)

  useEffect(() => {
    setConfetti(<ConfettiAnimation colors={colors} width={width} />)
  }, [])

  return <>{confetti}</>
}

export { Conf as Confetti }
