import { Dispatch, SetStateAction, useEffect } from 'react'
import { useAuthStore, useUserStore } from '@root/store'
import { isAddressValid } from '@root/utils/validateAddress'
import { IInputAddress } from '@root/types/Address'
import { validateSsn } from '@root/utils/validateSsn'
import { useAbortController } from './useAbortController'

type UsePrefillPiiFromLeadInfoProps = AddressProps | SsnProps

interface AddressProps {
  type: 'address'
  setAddress: Dispatch<SetStateAction<IInputAddress | undefined>>
}

interface SsnProps {
  type: 'ssn'
  setSsn: Dispatch<SetStateAction<string | undefined>>
}

const usePrefillPiiFromLeadInfo = (
  props: UsePrefillPiiFromLeadInfoProps
): void => {
  const user = useAuthStore((state) => state.user)

  const leadUuid = useUserStore((state) => state.leadUuid)

  const prefillPiiFromLeadInfo = useUserStore(
    (state) => state.prefillPiiFromLeadInfo
  )

  const { abortSignal } = useAbortController()

  useEffect(() => {
    if (
      leadUuid &&
      (!user?.ssn || !user?.addresses || !isAddressValid(user.addresses[0]))
    ) {
      getLeadInfoAndPrefillPii()
    }
  }, [])

  const getLeadInfoAndPrefillPii = async (): Promise<void> => {
    try {
      const res = await prefillPiiFromLeadInfo(abortSignal)

      switch (props.type) {
        case 'address':
          if (res && res.address && isAddressValid(res.address)) {
            props.setAddress(res.address)
          }
          return
        case 'ssn':
          if (res && res.ssn && validateSsn(res.ssn)) {
            props.setSsn(res.ssn)
          }
          return
        default:
          return
      }
    } catch {
      // to shut eslint up
    }
  }
}

export { usePrefillPiiFromLeadInfo }
