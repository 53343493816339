import { Button, Select, Title } from '@moneylion/mlds-web'
import { useBackdoorStore } from '@root/store'
import { TOnboardStateRoarmoneyIfsBackdoorLabelType } from '@root/types/Error'
import labels from './labels'

const BackdoorGeneric = (): JSX.Element => {
  const onboardStateRoarmoneyIfs = useBackdoorStore(
    (state) => state.onboardStateRoarmoneyIfs
  )
  const setOnboardStateRoarmoneyIfs = useBackdoorStore(
    (state) => state.setOnboardStateRoarmoneyIfs
  )

  const setShouldPrefill = useBackdoorStore((state) => state.setShouldPrefill)

  const onChangeSelectOnboardStateRoarmoneyIfs = (
    value: TOnboardStateRoarmoneyIfsBackdoorLabelType
  ): void => {
    setOnboardStateRoarmoneyIfs(value !== 'none' ? value : undefined)
  }

  return (
    <>
      <div className="mb-3">
        <Title size="medium">{labels.title}</Title>
      </div>

      <Button
        className="mb-3"
        color="primary"
        onClick={() => setShouldPrefill(true)}
      >
        {labels.prefill}
      </Button>

      <Select
        className="mb-3"
        defaultValue={onboardStateRoarmoneyIfs || 'none'}
        label={labels.onboardStateRmIfs.label}
        onChange={(value) =>
          onChangeSelectOnboardStateRoarmoneyIfs(
            value as TOnboardStateRoarmoneyIfsBackdoorLabelType
          )
        }
      >
        {labels.onboardStateRmIfs.options.map((option, index) => (
          <Select.Option
            key={`onboard-state-roarmoney-ifs-options-${index + 1}`}
            value={option}
          >
            {option}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export { BackdoorGeneric }
