interface IValidations {
  [key: string]: ((v: string) => boolean | string)[]
}

interface IBody {
  [key: string]: any
}

const validate = (body: IBody, validations: IValidations) => {
  let isValid = true

  Object.keys(body).forEach((key) => {
    const keyToValidate = key as keyof typeof validations

    if (validations[keyToValidate]) {
      const rules = validations[keyToValidate]

      rules.forEach((rule: any) => {
        const error = rule(body[keyToValidate])

        if (!error) {
          isValid = false
        }

        if (error && typeof error === 'string' && error.trim() !== '') {
          isValid = false
        }
      })
    }
  })

  return isValid
}

export { validate }
