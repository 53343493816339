const labels = {
  title: 'RoarMoney Backdoor',
  rmModal: {
    label: 'RM Modal',
    options: [
      {
        label: 'none',
        value: 'none',
      },
      {
        label: 'NOT_TRUSTED (Hard reject)',
        value: 'NOT_TRUSTED',
      },
      {
        label: 'await completed document scan (Soft reject)',
        value: 'await completed document scan',
      },
      {
        label: 'IDV_FAIL_HARD (IDV failed)',
        value: 'IDV_FAIL_HARD',
      },
      {
        label: 'IDV_FAIL_SOFT (IDV failed)',
        value: 'IDV_FAIL_SOFT',
      },
      {
        label: 'TIMEOUT',
        value: 'TIMEOUT',
      },
      {
        label: 'Already created',
        value: 'Already created',
      },
      {
        label: 'other error',
        value: 'other error',
      },
    ],
  },
}

export default labels
