/**
[product name from cards component]: [Analytics marketing name]
 Instacash: 'Instacash',
 */

import { ENV } from '@root/config'

const productNames: any = {
  Instacash: 'Instacash',
  'Credit Builder Plus': 'Credit Builder Plus',
  RoarMoney: 'RoarMoney',
  'Managed Investing': 'Investment',
  Crypto: 'Crypto',
  'BV Link': 'BV Link',
  'Credit Reserve': 'Credit Reserve',
  'Active Investing': 'Active Investing',
  Rewards: 'Rewards',
}

const onelinkVariant = {
  default: 'generic_install',
  roarmoney: 'roarmoney_install',
  instacash: 'instacash_install',
  instacashRoarmoney: 'instacash_install',
  cbplus: 'cbplus_install',
  investment: 'wealth_install',
  crypto: 'crypto_install',
}

const kustomerLink = (articleId: string): string =>
  `https://help.moneylion.com/${articleId}`

const marketplacePersonalLoanLink = (
  trafficSrc: string,
  medium: string
): string => {
  return `${ENV.MONEYLION_MARKETPLACE_URL}/personal-loan?traffic_src=${trafficSrc}&medium=${medium}`
}

export {
  productNames,
  onelinkVariant,
  kustomerLink,
  marketplacePersonalLoanLink,
}
