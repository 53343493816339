import styled, { css } from 'styled-components'

export const OnboardingHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 76px;
  padding: 16px 24px 0 24px;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
      background-color: ${({ theme }) => theme.color?.BACKGROUND_CONTENT};
      padding: 0px 16px;
    }
  `}

  .logo {
    display: flex;
    justify-content: center;
  }
`

export const OnboardingHeaderCol = styled.div.attrs((props) => ({
  isRightCol: props.className === 'right-col',
}))`
  display: block;
  flex-basis: calc(100% / 3);
  max-width: calc(100% / 3);

  ${({ isRightCol }) =>
    isRightCol
      ? css`
          display: flex;
          align-items: center;
          justify-content: end;
        `
      : ''};
`

export const OnboardingStepperContainer = styled.div`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
      background-color: ${({ theme }) => theme.color?.BACKGROUND_CONTENT};
    }
  `}
`
