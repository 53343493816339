import { useRouter } from 'next/router'
import { segmentApi } from '@root/api'
import {
  ADDRESS_ERROR_DIALOG_LABEL,
  ADD_ADDRESS_ELIGIBILITY_FAILED_LABEL,
  ADD_ADDRESS_GET_BILLING_ADDRESS_FAILED_LABEL,
  ADD_CARD_FAILED_LABEL,
  CBPLUS_MEMBERSHIP_FEE_LABEL,
  CBPLUS_QUALIFIED_CLOSE_LABEL,
  IC_RM_SUCCESS_LABEL,
  INSTACASH_HARD_REJECT_LABEL,
  INSTACASH_SOFT_REJECT_LABEL,
  IDV_BLACKLISTED_LABEL,
  INVALID_PHONE_NUMBER_LABEL,
  PHONE_NUMBER_CLAIMED_LABEL,
  PROTECTED_PAGES_PATHS,
  RM_IDT_FAILED_LABEL,
  RM_IDV_FAILED_LABEL,
  RM_INELIGIBLE_FAILED_LABEL,
  SSN_ASSOCIATED_LABEL,
  INVALID_REFERRAL_CODE_FAILED_LABEL,
  INVALID_ROARTAG_FAILED_LABEL,
  REFERRAL_RELATION_EXISTED_FAILED_LABEL,
  REFERRAL_RELATION_NOT_ALLOWED_FAILED_LABEL,
  WEB_TO_APP_LABEL,
} from '@root/constants'
import { useInstacashStore, useUIStore, useWalletStore } from '@root/store'
import { IErrorModalLabel } from '@root/types/Error'
import {
  TOnboardingFlow,
  formats,
  getOnboardingFlow,
  goToDownloadApp,
} from '@root/utils'
import { kustomerLink } from '@root/utils/constants'
import { AddressErrorDialogType } from '@root/utils/validateAddress'
import { ENV } from '@root/config'

interface AddressErrorDialogProps {
  addressErrorDialogType: AddressErrorDialogType
  addressErrorCodeList?: string[]
  addressErrorMessageList?: string[]
  onByPassAllowed?: () => void
}

interface WebToAppProps {
  firstName: string
  flow: TOnboardingFlow
  onClickDownloadApp: (cta: string) => void
  onClickContinue: (cta: string) => void
}

interface OnboardingDialogReturn {
  showIdtHardRejectDialog: () => void
  showIdvFailedDialog: () => void
  showCBPlusMembershipDialog: (apr: number) => void
  showIcRmSuccessDialog: (nextPagePath: string) => void
  showAddCardGetBillingAddressFailedDialog: () => void
  showAddCardAddressEligibilityFailedDialog: () => void
  showAddCardGenerateEsignFailedDialog: () => void
  showAddCardMissingFundIdFailedDialog: (applicationId: string) => void
  showAddCardUnrecognizedFundTypeFailedDialog: () => void
  showCustomAddCardLinkDebitCardFailedDialog: (label: IErrorModalLabel) => void
  showCustomAddCardFailedDialog: (label: IErrorModalLabel) => void
  showInstacashHardRejectDialog: () => void
  showInstacashSoftRejectDialog: (name: string) => void
  showCBPlusQualifiedCloseDialog: () => void
  showAddressErrorDialog: (props: AddressErrorDialogProps) => void
  showInvalidPhoneNumberDialog: () => void
  showPhoneNumberClaimedDialog: (
    phoneNumber: string,
    restart: () => void
  ) => void
  showSsnAssociatedDialog: () => void
  showRmIneligibleFailedDialog: () => void
  showIdvBlacklistedDialog: () => void
  showInvalidReferralCodeDialog: () => void
  showInvalidRoarTagDialog: () => void
  showReferralRelationExistedDialog: () => void
  showReferralRelationNotAllowedDialog: () => void
  showWebToAppDialog: (props: WebToAppProps) => void
}

const useOnboardingDialog = (): OnboardingDialogReturn => {
  const router = useRouter()
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)
  const isInstacashRoarMoneyUser = useInstacashStore(
    (state) => state.isInstacashRoarMoneyUser
  )
  const resetCard = useWalletStore((state) => state.resetCard)

  const onClickErrorDialogButton = (
    closeDialog?: () => void,
    additionalEvent?: () => void
  ): void => {
    additionalEvent && additionalEvent()
    closeDialog && closeDialog()
  }

  const showIdtHardRejectDialog = (): void => {
    setShowAppDialog(true, {
      title: RM_IDT_FAILED_LABEL.title,
      text: [RM_IDT_FAILED_LABEL.body_01, RM_IDT_FAILED_LABEL.body_02],
      textAlign: 'text-left',
      buttonDirection: 'column',
      buttons: [
        {
          label: RM_IDT_FAILED_LABEL.button,
          color: 'primary',
          onClick: (closeDialog) =>
            onClickErrorDialogButton(closeDialog, () => {
              const currentFlow = getOnboardingFlow(router.pathname)

              if (currentFlow === 'instacash') {
                router.push(PROTECTED_PAGES_PATHS.instacashAddCardStart)
                return
              }

              router.push(PROTECTED_PAGES_PATHS.offers)
            }),
        },
      ],
    })
  }

  const showIdvFailedDialog = (): void => {
    setShowAppDialog(true, {
      title: RM_IDV_FAILED_LABEL.title,
      text: [RM_IDV_FAILED_LABEL.body],
      buttonDirection: 'column',
      buttons: [
        {
          label: RM_IDV_FAILED_LABEL.button_01,
          color: 'secondary',
          onClick: (closeDialog) =>
            onClickErrorDialogButton(closeDialog, () =>
              window.open(kustomerLink('S1n50GRzF'))
            ),
        },
        {
          label: RM_IDV_FAILED_LABEL.button_02,
          color: 'primary',
          onClick: (closeDialog) =>
            onClickErrorDialogButton(closeDialog, () =>
              router.push(PROTECTED_PAGES_PATHS.offers)
            ),
        },
      ],
    })
  }

  const showCBPlusMembershipDialog = (apr: number): void => {
    setShowAppDialog(true, {
      dialogSize: 'Large',
      textAlign: 'text-left',
      title: CBPLUS_MEMBERSHIP_FEE_LABEL.title,
      text: [
        CBPLUS_MEMBERSHIP_FEE_LABEL.body_01,
        CBPLUS_MEMBERSHIP_FEE_LABEL.body_02(apr),
        CBPLUS_MEMBERSHIP_FEE_LABEL.body_03,
      ],
      buttonDirection: 'column',
      buttons: [
        {
          label: CBPLUS_MEMBERSHIP_FEE_LABEL.button,
          color: 'primary',
          onClick: (closeDialog) => closeDialog(),
        },
      ],
    })
  }

  const showIcRmSuccessDialog = (nextPagePath: string): void => {
    const { imageSrc, title, body, button } = IC_RM_SUCCESS_LABEL

    setShowAppDialog(true, {
      imageSrc,
      title,
      text: [body],
      buttonDirection: 'column',
      buttons: [
        {
          label: button,
          color: 'primary',
          onClick: (closeDialog) => {
            router.push(nextPagePath)
            closeDialog()
          },
        },
      ],
    })
  }

  const showAddCardGetBillingAddressFailedDialog = (): void => {
    const label = ADD_ADDRESS_GET_BILLING_ADDRESS_FAILED_LABEL

    setShowAppDialog(true, {
      title: label.title,
      text: [label.body],
      buttonDirection: 'column',
      buttons: [
        {
          label: label.button1,
          color: 'primary',
          onClick: (closeDialog) => {
            router.push(PROTECTED_PAGES_PATHS.instacashAddCard)
            closeDialog()
          },
        },
        {
          label: label.button2,
          color: 'secondary',
          onClick: (closeDialog) => {
            router.push(PROTECTED_PAGES_PATHS.instacashAddCardStart)
            closeDialog()
          },
        },
      ],
    })
  }

  const showAddCardAddressEligibilityFailedDialog = (): void => {
    setShowAppDialog(true, {
      title: ADD_ADDRESS_ELIGIBILITY_FAILED_LABEL.title,
      text: [ADD_ADDRESS_ELIGIBILITY_FAILED_LABEL.body],
      buttonDirection: 'column',
      buttons: [
        {
          label: ADD_ADDRESS_ELIGIBILITY_FAILED_LABEL.button,
          color: 'primary',
          onClick: (closeDialog) => {
            router.push(PROTECTED_PAGES_PATHS.instacashAddCard)
            closeDialog()
          },
        },
      ],
    })
  }

  const showAddCardGenerateEsignFailedDialog = (): void => {
    const label = ADD_CARD_FAILED_LABEL.GENERATE_ESIGN_GENERIC_ERROR

    setShowAppDialog(true, {
      title: label.title,
      text: [label.body1],
      buttonDirection: 'column',
      buttons: [
        {
          label: label.button1,
          color: 'primary',
          onClick: (closeDialog: () => void) => {
            resetCard()
            router.push(PROTECTED_PAGES_PATHS.instacashAddCard)
            closeDialog()
          },
        },
        {
          label: label.button2,
          color: 'secondary',
          onClick: (closeDialog: () => void) => {
            resetCard()
            router.push(PROTECTED_PAGES_PATHS.instacashAddCardStart)
            closeDialog()
          },
        },
      ],
    })
  }

  const segmentTrackIcIneligibleCardErrorModal = (
    action: string,
    applicationId: string
  ): void => {
    segmentApi.trackWithOSPlatform('ca_ineligible_card_error_modal', {
      action,
      application_id: applicationId,
    })
  }

  const showAddCardMissingFundIdFailedDialog = (
    applicationId: string
  ): void => {
    const label = ADD_CARD_FAILED_LABEL.MISSING_FUND_ID_ERROR

    setShowAppDialog(true, {
      title: label.title,
      text: [label.body1],
      buttonDirection: 'column',
      buttons: [
        {
          label: label.button1,
          color: 'primary',
          onClick: (closeDialog: () => void) => {
            segmentTrackIcIneligibleCardErrorModal(
              'add_debit_card',
              applicationId
            )
            resetCard()
            router.push(PROTECTED_PAGES_PATHS.instacashAddCard)
            closeDialog()
          },
        },
        {
          label: label.button2 || '',
          color: 'secondary',
          onClick: (closeDialog: () => void) => {
            segmentTrackIcIneligibleCardErrorModal('cancel', applicationId)
            resetCard()
            router.push(PROTECTED_PAGES_PATHS.instacashAddCardStart)
            closeDialog()
          },
        },
      ],
    })
  }

  const showAddCardUnrecognizedFundTypeFailedDialog = (): void => {
    const label = ADD_CARD_FAILED_LABEL.WAL4001

    setShowAppDialog(true, {
      title: label.title,
      text: [label.body1],
      buttonDirection: 'column',
      buttons: [
        {
          label: label.button1,
          color: 'primary',
          onClick: (closeDialog: () => void) => {
            router.push(
              PROTECTED_PAGES_PATHS.instacashAddCardPickBillingAddress
            )
            closeDialog()
          },
        },
        {
          label: label.button2 || '',
          color: 'secondary',
          onClick: (closeDialog: () => void) => {
            resetCard()
            router.push(PROTECTED_PAGES_PATHS.instacashAddCard)
            closeDialog()
          },
        },
      ],
    })
  }

  const showCustomAddCardLinkDebitCardFailedDialog = (
    label: IErrorModalLabel
  ): void => {
    setShowAppDialog(true, {
      title: label.title,
      text: [label.body1],
      buttonDirection: 'column',
      buttons: [
        {
          label: label.button1,
          color: 'primary',
          onClick: (closeDialog: () => void) => {
            resetCard()
            router.push(PROTECTED_PAGES_PATHS.instacashAddCard)
            closeDialog()
          },
        },
      ],
    })
  }

  const showCustomAddCardFailedDialog = (label: IErrorModalLabel): void => {
    setShowAppDialog(true, {
      title: label.title,
      text: [label.body1],
      buttonDirection: 'column',
      buttons: [
        {
          label: label.button1,
          color: 'primary',
          onClick: (closeDialog: () => void) => {
            resetCard()
            router.push(PROTECTED_PAGES_PATHS.instacashAddCard)
            closeDialog()
          },
        },
        {
          label: label.button2 || '',
          color: 'secondary',
          onClick: (closeDialog: () => void) => {
            resetCard()
            router.push(PROTECTED_PAGES_PATHS.instacashAddCardStart)
            closeDialog()
          },
        },
      ],
    })
  }

  const showInstacashHardRejectDialog = (): void => {
    const { title, body, button1, button2 } = INSTACASH_HARD_REJECT_LABEL

    setShowAppDialog(true, {
      title,
      text: [body],
      buttonDirection: 'column',
      buttons: [
        {
          label: button1,
          color: 'primary',
          onClick: (closeDialog: () => void) => {
            window.open(
              kustomerLink('S1l56f0GK'),
              '_blank',
              'noopener,noreferrer'
            )
            closeDialog()
          },
        },
        {
          label: button2,
          color: 'secondary',
          onClick: (closeDialog: () => void) => {
            router.push(PROTECTED_PAGES_PATHS.offers)
            closeDialog()
          },
        },
      ],
    })
  }

  const showInstacashSoftRejectDialog = (name: string): void => {
    const { imageSrc, title, body, button } = INSTACASH_SOFT_REJECT_LABEL

    setShowAppDialog(true, {
      imageSrc,
      title,
      text: [body(name)],
      buttonDirection: 'column',
      buttons: [
        {
          label: button,
          color: 'primary',
          onClick: (closeDialog: () => void) => {
            if (isInstacashRoarMoneyUser) {
              goToDownloadApp('instacashRoarmoney')
            } else {
              goToDownloadApp('instacash')
            }

            closeDialog()
          },
        },
      ],
    })
  }

  const showCBPlusQualifiedCloseDialog = (): void => {
    setShowAppDialog(true, {
      title: CBPLUS_QUALIFIED_CLOSE_LABEL.title,
      text: [CBPLUS_QUALIFIED_CLOSE_LABEL.body],
      buttonDirection: 'column',
      textAlign: 'text-left',
      buttons: [
        {
          label: CBPLUS_QUALIFIED_CLOSE_LABEL.button_01,
          color: 'primary',
          onClick: (closeDialog) => closeDialog(),
        },
        {
          label: CBPLUS_QUALIFIED_CLOSE_LABEL.button_02,
          color: 'secondary',
          onClick: (closeDialog) => {
            router.push(PROTECTED_PAGES_PATHS.offers)
            closeDialog()
          },
        },
      ],
    })
  }

  const showAddressErrorDialog = ({
    addressErrorDialogType,
    addressErrorCodeList,
    addressErrorMessageList,
    onByPassAllowed,
  }: AddressErrorDialogProps): void => {
    if (addressErrorDialogType === 'BYPASS_ALLOWED') {
      segmentApi.trackWithOSPlatform('acc_core_address_modal_interact', {
        addressErrorCodeList,
        interaction: 'view',
      })
    }

    const onClickPrimaryCTA = (closeDialog: () => void): void => {
      if (addressErrorDialogType === 'BYPASS_ALLOWED') {
        onByPassAllowed && onByPassAllowed()

        segmentApi.trackWithOSPlatform('acc_core_address_modal_interact', {
          addressErrorCodeList,
          interaction: 'click',
          cta: 'Yes',
        })
      }
      closeDialog()
    }

    const onClickSecondaryCTA = (closeDialog: () => void): void => {
      if (addressErrorDialogType === 'BYPASS_ALLOWED') {
        segmentApi.trackWithOSPlatform('acc_core_address_modal_interact', {
          addressErrorCodeList,
          interaction: 'click',
          cta: 'No, edit address',
        })
      }
      closeDialog()
    }

    const dialogLabels: {
      title: string
      body: string[]
      button_01: string
      button_02?: string
    } = ADDRESS_ERROR_DIALOG_LABEL[addressErrorDialogType]

    setShowAppDialog(true, {
      title: dialogLabels.title,
      text: addressErrorMessageList
        ? [...dialogLabels.body, ...addressErrorMessageList]
        : [...dialogLabels.body],
      buttonDirection: 'column',
      textAlign: 'text-left',
      buttons: dialogLabels.button_02
        ? [
            {
              label: dialogLabels.button_01,
              color: 'primary',
              onClick: (closeDialog: any) => onClickPrimaryCTA(closeDialog),
            },
            {
              label: dialogLabels.button_02,
              color: 'secondary',
              onClick: (closeDialog: any) => onClickSecondaryCTA(closeDialog),
            },
          ]
        : [
            {
              label: dialogLabels.button_01,
              color: 'primary',
              onClick: (closeDialog: any) => onClickPrimaryCTA(closeDialog),
            },
          ],
    })
  }

  const showInvalidPhoneNumberDialog = (): void => {
    setShowAppDialog(true, {
      title: INVALID_PHONE_NUMBER_LABEL.title,
      text: INVALID_PHONE_NUMBER_LABEL.body,
      buttonDirection: 'column',
      buttons: [
        {
          label: INVALID_PHONE_NUMBER_LABEL.button,
          color: 'primary',
          onClick: (closeDialog) => closeDialog(),
        },
      ],
    })
  }

  const showPhoneNumberClaimedDialog = (
    phoneNumber: string,
    restart: () => void
  ): void => {
    setShowAppDialog(true, {
      title: PHONE_NUMBER_CLAIMED_LABEL.title,
      text: PHONE_NUMBER_CLAIMED_LABEL.body(formats.phoneNumber(phoneNumber)),
      buttonDirection: 'column',
      buttons: [
        {
          label: PHONE_NUMBER_CLAIMED_LABEL.cancel,
          color: 'secondary',
          onClick: (closeDialog) => {
            restart()
            closeDialog()
          },
        },
        {
          label: PHONE_NUMBER_CLAIMED_LABEL.downloadApp,
          color: 'primary',
          onClick: () => {
            const currentFlow = getOnboardingFlow(router.pathname)

            if (currentFlow === 'instacash') {
              return goToDownloadApp('instacash')
            }

            goToDownloadApp('default')
          },
        },
      ],
    })
  }

  const showSsnAssociatedDialog = (): void => {
    setShowAppDialog(true, {
      title: SSN_ASSOCIATED_LABEL.title,
      text: SSN_ASSOCIATED_LABEL.description,
      buttonDirection: 'column',
      buttons: [
        {
          label: SSN_ASSOCIATED_LABEL.button,
          onClick: (closeDialog) => closeDialog(),
        },
      ],
    })
  }

  const showRmIneligibleFailedDialog = (): void => {
    setShowAppDialog(true, {
      title: RM_INELIGIBLE_FAILED_LABEL.title,
      text: RM_INELIGIBLE_FAILED_LABEL.description,
      textAlign: 'text-center',
      buttonDirection: 'column',
      buttons: [
        {
          label: RM_INELIGIBLE_FAILED_LABEL.button_01,
          onClick: () => window.open(kustomerLink('S1n50GRzF')),
          color: 'secondary',
        },
        {
          label: RM_INELIGIBLE_FAILED_LABEL.button_02,
          onClick: () => router.push(PROTECTED_PAGES_PATHS.offers),
          color: 'primary',
        },
      ],
    })
  }

  const showIdvBlacklistedDialog = (): void => {
    setShowAppDialog(true, {
      title: IDV_BLACKLISTED_LABEL.title,
      text: IDV_BLACKLISTED_LABEL.description,
      textAlign: 'text-center',
      buttonDirection: 'column',
      buttons: [
        {
          label: IDV_BLACKLISTED_LABEL.button_01,
          onClick: () => window.open(kustomerLink('S1n50GRzF')),
          color: 'secondary',
        },
        {
          label: IDV_BLACKLISTED_LABEL.button_02,
          onClick: () => router.push(PROTECTED_PAGES_PATHS.offers),
          color: 'primary',
        },
      ],
    })
  }

  const showInvalidReferralCodeDialog = (): void => {
    setShowAppDialog(true, {
      title: INVALID_REFERRAL_CODE_FAILED_LABEL.title,
      text: INVALID_REFERRAL_CODE_FAILED_LABEL.description,
      titleAlign: 'text-center',
      buttonDirection: 'column',
      buttons: [
        {
          label: INVALID_REFERRAL_CODE_FAILED_LABEL.button,
          onClick: (closeDialog) => closeDialog(),
        },
      ],
    })
  }

  const showInvalidRoarTagDialog = (): void => {
    setShowAppDialog(true, {
      title: INVALID_ROARTAG_FAILED_LABEL.title,
      text: INVALID_ROARTAG_FAILED_LABEL.description,
      titleAlign: 'text-center',
      buttonDirection: 'column',
      buttons: [
        {
          label: INVALID_ROARTAG_FAILED_LABEL.button,
          onClick: (closeDialog) => closeDialog(),
        },
      ],
    })
  }

  const showReferralRelationExistedDialog = (): void => {
    setShowAppDialog(true, {
      title: REFERRAL_RELATION_EXISTED_FAILED_LABEL.title,
      text: REFERRAL_RELATION_EXISTED_FAILED_LABEL.description,
      titleAlign: 'text-center',
      buttonDirection: 'column',
      buttons: [
        {
          label: REFERRAL_RELATION_EXISTED_FAILED_LABEL.button,
          onClick: (closeDialog) => closeDialog(),
        },
      ],
    })
  }

  const showReferralRelationNotAllowedDialog = (): void => {
    setShowAppDialog(true, {
      title: REFERRAL_RELATION_NOT_ALLOWED_FAILED_LABEL.title,
      text: REFERRAL_RELATION_NOT_ALLOWED_FAILED_LABEL.description,
      titleAlign: 'text-center',
      buttonDirection: 'column',
      buttons: [
        {
          label: REFERRAL_RELATION_NOT_ALLOWED_FAILED_LABEL.button,
          onClick: (closeDialog) => closeDialog(),
        },
      ],
    })
  }

  const showWebToAppDialog = ({
    firstName,
    flow,
    onClickDownloadApp,
    onClickContinue,
  }: WebToAppProps): void => {
    const typeOfFlow = flow === 'crypto' ? 'organic' : flow
    setShowAppDialog(true, {
      dialogSize: 'Large',
      imageSrc: `${ENV.MONEYLION_WEB_CDN}/onboarding/images/${WEB_TO_APP_LABEL[typeOfFlow].image}.webp`,
      title: WEB_TO_APP_LABEL[typeOfFlow].title(firstName),
      text: WEB_TO_APP_LABEL[typeOfFlow].description,
      titleAlign: 'text-center',
      buttonDirection: 'column',
      buttons: [
        {
          label: WEB_TO_APP_LABEL.button_01,
          onClick: (closeDialog) => {
            onClickDownloadApp(WEB_TO_APP_LABEL.button_01)
            closeDialog()
          },
        },
        {
          label: WEB_TO_APP_LABEL.button_02,
          onClick: (closeDialog) => {
            onClickContinue(WEB_TO_APP_LABEL.button_02)
            closeDialog()
          },
          color: 'secondary',
        },
      ],
    })
  }

  return {
    showIdtHardRejectDialog,
    showIdvFailedDialog,
    showCBPlusMembershipDialog,
    showIcRmSuccessDialog,
    showAddCardGetBillingAddressFailedDialog,
    showAddCardAddressEligibilityFailedDialog,
    showAddCardGenerateEsignFailedDialog,
    showAddCardMissingFundIdFailedDialog,
    showAddCardUnrecognizedFundTypeFailedDialog,
    showCustomAddCardLinkDebitCardFailedDialog,
    showCustomAddCardFailedDialog,
    showInstacashHardRejectDialog,
    showInstacashSoftRejectDialog,
    showCBPlusQualifiedCloseDialog,
    showAddressErrorDialog,
    showInvalidPhoneNumberDialog,
    showPhoneNumberClaimedDialog,
    showSsnAssociatedDialog,
    showRmIneligibleFailedDialog,
    showIdvBlacklistedDialog,
    showInvalidReferralCodeDialog,
    showInvalidRoarTagDialog,
    showReferralRelationExistedDialog,
    showReferralRelationNotAllowedDialog,
    showWebToAppDialog,
  }
}

export { useOnboardingDialog }
