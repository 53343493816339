import { getApps, getApp, initializeApp } from 'firebase/app'
import { useEffect, useState } from 'react'
import {
  RemoteConfig,
  fetchAndActivate,
  getRemoteConfig,
  getValue,
  Value,
} from 'firebase/remote-config'
import { ENV } from '@root/config'

const firebaseConfig = ENV.FIREBASE_CONFIG
const firebaseDefaultRemoteConfig = ENV.FIREBASE_DEFAULT_CONFFIG

type Status = 'loading' | 'success' | 'error'
interface UseFirebase {
  treatment: Value | undefined
  status: Status
}

/**
 * useRemoteConfig - A hook to get a feature flag from Firebase Remote Config
 *
 * Usage example:
 *
 * ```jsx
 * // Parse as `JSON`:
 *  const firebase = useFirebase('OB_RESKIN_FLOW')
 *  const parseTreatment: Treatment = JSON.parse(
 *   firebase.treatment?.treatment || '{}'
 * )
 *
 * // Parse as `Boolean`:
 * const firebase = useFirebase('OB_RESKIN_FLOW')
 * const parseTreatment = firebaseTreatment.treatment?.asBoolean()
 *
 * ```
 **/
const useRemoteConfig = (treatmentName: string): UseFirebase => {
  const [treatment, setTreatment] = useState<Value>()
  const [status, setStatus] = useState<Status>('loading')
  useEffect(() => {
    initializeFirebase()
  }, [])

  const initializeFirebase = () => {
    let firebaseApp
    if (getApps().length > 0) {
      firebaseApp = getApp()
    } else {
      firebaseApp = initializeApp(firebaseConfig)
    }
    const remoteConfig = getRemoteConfig(firebaseApp)
    remoteConfig.defaultConfig = firebaseDefaultRemoteConfig
    remoteConfig.settings.minimumFetchIntervalMillis = // [https://firebase.google.com/docs/remote-config/get-started?platform=web#throttling]
      ENV.MINIMUM_FETCH_INTERVAL_MILLIS

    fetchRemoteConfig(remoteConfig)
  }

  const fetchRemoteConfig = async (
    remoteConfig: RemoteConfig
  ): Promise<void> => {
    try {
      let config
      if (remoteConfig.lastFetchStatus === 'success') {
        config = getValue(remoteConfig, treatmentName)
      } else {
        await fetchAndActivate(remoteConfig)
        config = getValue(remoteConfig, treatmentName)
      }
      setStatus('success')
      setTreatment(config)
    } catch (error) {
      console.error('Error fetching and activating remote config:', error)
      setStatus('error')
    }
  }

  return { treatment, status }
}

export { useRemoteConfig }
