import {
  MLDSTheme,
  SEMANTIC_COLOR_V3,
  theme as mldsTheme,
} from '@moneylion/mlds-web'

const theme: MLDSTheme = {
  ...mldsTheme,
  color: SEMANTIC_COLOR_V3,
}

export default theme
