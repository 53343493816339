import React from 'react'
import { Disclaimer } from '..'
import labels from './labels'

interface DisclaimerRoarMoneyProps {
  withCrypto?: boolean
}

const DisclaimerRoarMoney = ({
  withCrypto,
}: DisclaimerRoarMoneyProps): JSX.Element => {
  return (
    <>
      <Disclaimer labels={labels.roarMoneyDisclaimer} />
      {withCrypto && <Disclaimer labels={labels.cryptoDisclaimerList} />}
    </>
  )
}

export { DisclaimerRoarMoney }
