const labels = {
  roarMoneyDisclaimer: [
    'MoneyLion is a financial technology company, not a bank. RoarMoney℠ demand deposit account provided by, and MoneyLion Debit Mastercard®️ issued by, Pathward️, National Association, Member FDIC. RoarMoney is a service mark of MoneyLion. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International. Funds are FDIC insured, subject to applicable limitations and restrictions, when we receive the funds deposited to your account.',
  ],
  cryptoDisclaimerList: [
    'Cryptocurrency transaction and custody services are powered by Zero Hash LLC and Zero Hash Liquidity Services LLC. When you enable MoneyLion Crypto, you must also agree to the Zero Hash LLC and Zero Hash Liquidity LLC terms and conditions and enable your Zero Hash account. Cryptocurrency balances are not covered by FDIC or SIPC insurance.',
    'The cryptocurrency assets in your Zero Hash account will not be held at MoneyLion. MoneyLion is not responsible for the cryptocurrency assets held in any Zero Hash account. MoneyLion is not involved in the purchase, sale, exchange of fiat funds for cryptocurrency, or custody of the cryptocurrencies.',
    '1 MoneyLion Crypto Accounts with an active Crypto Round Ups feature will round-up each eligible transaction from your RoarMoney account to the nearest dollar. Once it accrues to $1 or more, it will be transferred to your MoneyLion Crypto Account, where the funds are invested into Bitcoin. MoneyLion Crypto is powered by Zero Hash.',
  ],
}

export default labels
