import dayjs from 'dayjs'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import {
  TPayFrequencyAnswer,
  TPayFrequency,
  TPayFrequencyType,
} from '@root/types/PaymentSchedule'
import { formats } from '@root/utils'

const dayOfWeekList = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

interface IAnswers {
  payFrequency?: TPayFrequencyAnswer
  weekly?: string
  weeklyLastPaid?: string
  biweekly?: string
  biweeklyLastPaid?: string
  twiceAMonth?: string
  twiceAMonthFirstPayCheckDateOnly?: number
  twiceAMonthFirstPayCheckDate?: number
  twiceAMonthSecondPayCheckDate?: number
  monthly?: string
  monthlyWeek?: string
  monthlyDayOfWeek?: string
  monthlyDateOfMonth?: number
}

interface IFee {
  amount?: number
  date?: number
}

interface PaymentScheduleInitialState {
  answers: IAnswers
  fee: IFee
  lastPayCheckDateOptions?: string[] | undefined
  payFrequency?: TPayFrequency | undefined
  payFrequencyType?: TPayFrequencyType | undefined
}

export interface PaymentScheduleState extends PaymentScheduleInitialState {
  setAnswers: (answers: IAnswers) => void
  setFee: (fee: IFee) => void
  setLastPayCheckDateOptions: (dayOfWeek: string) => void
  setPayFrequency: (payFrequency: TPayFrequency) => void
  setPayFrequencyType: (payFrequency: TPayFrequencyType) => void
  reset: () => void
}

const initialState: PaymentScheduleInitialState = {
  answers: {
    payFrequency: undefined,
    weekly: undefined,
    weeklyLastPaid: undefined,
    biweekly: undefined,
    biweeklyLastPaid: undefined,
    twiceAMonth: undefined,
    twiceAMonthFirstPayCheckDateOnly: undefined,
    twiceAMonthFirstPayCheckDate: undefined,
    twiceAMonthSecondPayCheckDate: undefined,
    monthly: undefined,
    monthlyWeek: undefined,
    monthlyDayOfWeek: undefined,
    monthlyDateOfMonth: undefined,
  },
  fee: {
    amount: undefined,
    date: undefined,
  },
  lastPayCheckDateOptions: undefined,
  payFrequency: undefined,
  payFrequencyType: undefined,
}

const usePaymentScheduleStore = create<PaymentScheduleState>()(
  persist(
    (set, getState) => ({
      ...initialState,
      setAnswers: (answers: IAnswers) => {
        const state = getState()

        set({
          answers: {
            ...state.answers,
            ...answers,
          },
        })
      },
      setFee: (fee: IFee) => {
        const state = getState()

        set({
          fee: {
            ...state.fee,
            ...fee,
          },
        })
      },
      setLastPayCheckDateOptions: (dayOfWeek: string) => {
        const today = dayjs()
        const todayNumber = today.day()
        const payDayNumber = dayOfWeekList.indexOf(dayOfWeek)
        const payDay = dayjs().set('day', payDayNumber)

        const firstPayDay =
          todayNumber > payDayNumber
            ? today.subtract(todayNumber - payDayNumber, 'day')
            : payDay.subtract(7, 'day')
        const secondPayDay = firstPayDay.subtract(7, 'day')

        const formatFirstPayDay = formats.date(
          firstPayDay,
          'dddd, MMM DD, YYYY'
        )
        const formatSecondPayDay = formats.date(
          secondPayDay,
          'dddd, MMM DD, YYYY'
        )

        set({
          lastPayCheckDateOptions: [formatFirstPayDay, formatSecondPayDay],
        })
      },
      setPayFrequency: (payFrequency: TPayFrequency) => {
        set({ payFrequency })
      },
      setPayFrequencyType: (payFrequencyType: TPayFrequencyType) => {
        set({ payFrequencyType })
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        answers: state.answers,
        fee: state.fee,
        payFrequency: state.payFrequency,
        payFrequencyType: state.payFrequencyType,
      }),
      name: 'paymentScheduleStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { usePaymentScheduleStore }
