import { useEffect } from 'react'

const useAbortController = (): {
  abortSignal: AbortSignal
  isAborted: boolean
} => {
  const abortController = new AbortController()
  const abortSignal = abortController.signal
  const isAborted = abortSignal.aborted

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { abortSignal, isAborted }
}

export { useAbortController }
