type TSessionStorageKey =
  | 'chatbot'
  | 'email'
  | 'investmentRiskAnswers'
  | 'irclickid'
  | 'leadUuid'
  | 'mfaOptions'
  | 'offerId'
  | 'plaidBank'
  | 'plaidToken'
  | 'refID'
  | 'tid'
  | 'utmSource'
  | 'utmTerm'

const get = (key: TSessionStorageKey): string | null => {
  if (typeof window === 'undefined') return null
  return window.sessionStorage.getItem(key)
}

const set = (key: TSessionStorageKey, value: string): void => {
  window.sessionStorage.setItem(key, value)
}

const remove = (key: TSessionStorageKey): void => {
  window.sessionStorage.removeItem(key)
}

const clear = (): void => {
  window.sessionStorage.clear()
}

const sessionStorageApi = {
  get,
  set,
  remove,
  clear,
}

export default sessionStorageApi
