import React from 'react'
import { Icon } from '@moneylion/mlds-web'
import styled, { css, CSSProperties } from 'styled-components'
import Image from 'next/image'
import Tooltip from '@root/components/Tooltip'

const imageSizes = {
  large: '128px',
  Medium: '64px',
  Small: '40px',
  XSmall: '24px',
}

const StyledProfileImage = styled.span<ProfileImageProps>`
  background: ${({ image, theme, hasBackground }) =>
    `${image ? `url(${image}), ` : ''}${
      hasBackground
        ? theme.color?.BUTTON_SECONDARY_BACKGROUND
        : theme.color?.BACKGROUND_CONTENT
    }`};
  background-size: cover;
  width: ${({ size }) => imageSizes[size || 'large']};
  height: ${({ size }) => imageSizes[size || 'large']};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size, hasBackground }) =>
    size === 'XSmall' && hasBackground
      ? css`
          i {
            font-size: 15px;
          }
        `
      : ''};
`

interface ProfileImageProps {
  image?: string
  size?: 'Medium' | 'Small' | 'XSmall'
  className?: string
  style?: CSSProperties
  hasBackground?: boolean
  hasVerifiedLabel?: boolean
  wowEarlistSignUpDate?: string
}

const ProfileImage = (props: ProfileImageProps): JSX.Element => {
  const {
    image,
    size,
    className = '',
    style,
    hasBackground = true,
    hasVerifiedLabel = false,
    wowEarlistSignUpDate = '',
  } = props

  return (
    <div style={{ position: 'relative' }}>
      <StyledProfileImage
        className={`br-round ${className}`}
        image={image}
        size={size}
        style={style}
        hasBackground={hasBackground}
      >
        {!image && <Icon name="user" />}
      </StyledProfileImage>
      {hasVerifiedLabel && (
        <div style={{ position: 'absolute', right: 0, zIndex: 10, bottom: 0 }}>
          <Tooltip tooltipText={wowEarlistSignUpDate}>
            <Image
              width={32}
              height={32}
              src="/assets/svgs/Badge.svg"
              alt="verified-label"
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export { ProfileImage }
