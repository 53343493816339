import { segmentApi } from '@root/api'
import { ENV } from '@root/config'
import { onelinkVariant } from './constants'

type OneLinkVariant = keyof typeof onelinkVariant

const goToDownloadApp = (variant: OneLinkVariant): void => {
  segmentApi.trackOnelink(
    {
      onelink_variant: onelinkVariant[variant],
      onelink: ENV.ONE_LINKS_ONBOARDING.generic[variant],
    },
    () => {
      window.open(ENV.ONE_LINKS_ONBOARDING.generic[variant], '_self')
    }
  )
}

export { goToDownloadApp }
