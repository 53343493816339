import { cardAmexRegExp } from './regularExpression'

const isAmexCard = (cardNumber: string): boolean => {
  return cardNumber.search(cardAmexRegExp) === 0
}

/**
 * @usage: For the Add Card Flow
 * @description: To validate if the card cvv is valid
 * @param {cardCvv}: The card cvv
 * @returns {string | undefined}: Returns undefined or an error message
 */
const validateCardCvv = (cardCvv: string): string | undefined => {
  if (cardCvv.length < 3) {
    return 'The card cvc entered is not valid.'
  }
  return undefined
}

/**
 * @usage: For the Add Card Flow
 * @description: To validate if the card number is valid
 * @param {cardNumber}: The card number
 * @returns {string | undefined}: Returns undefined or an error message
 */
const validateCardNumber = (cardNumber: string): string | undefined => {
  const maxCardNumberLength = isAmexCard(cardNumber) ? 15 : 16

  if (cardNumber.replace(/-/g, '').length < maxCardNumberLength) {
    return 'The card number entered is not valid.'
  }

  return undefined
}

export { isAmexCard, validateCardCvv, validateCardNumber }
