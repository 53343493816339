import Image from 'next/image'
import styled from 'styled-components'
import moneyLionLogo from '/public/assets/svgs/ml-logo-tagline.svg'

const ContainerDiv = styled.div`
  z-index: 999999;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;

  .moneylion-logo {
    width: 100%;
    max-width: 220px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

const Spinner = (): JSX.Element => (
  <ContainerDiv id="loading-indicator">
    <Image
      src={moneyLionLogo}
      alt="MoneyLion Logo"
      className="moneylion-logo"
      priority
    />
  </ContainerDiv>
)

export default Spinner
