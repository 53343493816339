import { Dialog, theme } from '@moneylion/mlds-web'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useUIStore } from '@root/store'

const StyledIframe = styled.iframe`
  width: 100%;
  height: 600px;
  border: 1px solid #f3f5f9;

  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    height: 350px;
  }
`

const LegalAgreementDialog: React.FunctionComponent = () => {
  const showLegalAgreementDialog = useUIStore(
    (state) => state.showLegalAgreementDialog
  )
  const setShowLegalAgreementDialog = useUIStore(
    (state) => state.setShowLegalAgreementDialog
  )

  const options = useUIStore((state) => state.legalAgreementDialogOptions)

  useEffect(() => {
    if (showLegalAgreementDialog) {
      showDialog()
      setShowLegalAgreementDialog(false, options)
    }
  }, [showLegalAgreementDialog])

  const showDialog = (): void => {
    return window.showDialogs(
      <Dialog width={600}>
        {({ closeDialog }) => (
          <>
            <Dialog.Title text={options?.title || ''} />
            <StyledIframe title={options?.title} src={options?.src} />
            <Dialog.Button.Container direction="column">
              <Dialog.Button onClick={closeDialog}>Close</Dialog.Button>
            </Dialog.Button.Container>
          </>
        )}
      </Dialog>
    )
  }

  return null
}

export default LegalAgreementDialog
