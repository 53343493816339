import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { PROTECTED_PAGES_PATHS } from '@root/constants'
import { useEsignStore, useInstacashStore, useUIStore } from '@root/store'
import { IEsign } from '@root/types'
import { useAbortController } from './useAbortController'

const useInstacashEsigns = (): {
  isLoading: boolean
  mandatoryEsigns?: IEsign[]
  optionalEsigns?: IEsign[]
  hasSignOptionalEsignDocuments: boolean
  signOptionalEsignDocuments: (isChecked: boolean) => void
} => {
  const router = useRouter()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getSubscriptionEsignDocuments = useEsignStore(
    (state) => state.getSubscriptionEsignDocuments
  )

  const underwritingTransactionId = useInstacashStore(
    (state) => state.underwritingTransactionId
  )

  const subscriptionEsignDocuments = useEsignStore(
    (state) => state.subscriptionEsignDocuments
  )

  const subscriptionOptionalEsignDocuments = useEsignStore(
    (state) => state.subscriptionOptionalEsignDocuments
  )

  const hasSignOptionalEsignDocuments = useEsignStore(
    (state) => state.hasSignOptionalEsignDocuments
  )

  const signOptionalEsignDocuments = (isChecked: boolean): void => {
    useEsignStore.setState({ hasSignOptionalEsignDocuments: isChecked })
  }

  const { abortSignal } = useAbortController()

  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)

  const getEsigns = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const { unsignedOptionalDocuments: backupAuthDocs } =
        await getSubscriptionEsignDocuments(
          {
            productName: 'INSTACASH',
            underwritingTransactionId,
          },
          abortSignal
        )
      useEsignStore.setState({
        subscriptionOptionalEsignDocuments: backupAuthDocs,
      })
    } catch {
      setShowAppDialog(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!underwritingTransactionId) {
      router.push(PROTECTED_PAGES_PATHS.instacashBVPreroll)
    } else getEsigns()
  }, [underwritingTransactionId])

  return {
    isLoading,
    mandatoryEsigns: subscriptionEsignDocuments,
    optionalEsigns: subscriptionOptionalEsignDocuments,
    hasSignOptionalEsignDocuments,
    signOptionalEsignDocuments,
  }
}

export { useInstacashEsigns }
