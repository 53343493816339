export const invalidDobErrorMessage =
  'This is not a valid birthday. Please try again with the format of MM/DD/YYYY'

export const ageLimitErrorMessage =
  'Unfortunately we are not allowed to open accounts for this age category.'

const daysInMonth = (month: number, year: number): number => {
  switch (month) {
    case 2:
      return (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28
    case 4:
    case 6:
    case 9:
    case 11:
      return 30
    default:
      return 31
  }
}

export const checkDobError = (value: string): string | undefined => {
  if (!value) return undefined

  const dateRegExp =
    /((0[1-9]|1[0-2])\/((0|1)[0-9]|2[0-9]|3[0-1])\/(\d\d\d\d))$/
  const dateValue = dateRegExp.test(value) ? Date.parse(value) : undefined

  const dobParts = value.split('/')
  const dobMonth = parseInt(dobParts[0], 10)
  const dobDay = parseInt(dobParts[1], 10)
  const dobYear = parseInt(dobParts[2], 10)

  if (!dateValue || dobDay > daysInMonth(dobMonth, dobYear))
    return invalidDobErrorMessage

  const now = new Date()
  const dateOfBirth = new Date(dateValue)

  const age = now.getFullYear() - dateOfBirth.getFullYear()

  if (age < 18 || age > 111) return ageLimitErrorMessage

  switch (age) {
    case 18:
      if (now < dateOfBirth) return ageLimitErrorMessage
    case 111:
      if (now > dateOfBirth) return ageLimitErrorMessage
    default:
      return undefined
  }
}

export const dobValidationRules = [
  (value: string) => {
    const result = checkDobError(value)
    return result === undefined ? true : result
  },
]
