import { https } from '@root/utils'
import ICrypto from '@root/types/Crypto'

interface IFetchCrypto {
  data: ICrypto
}

const fetchCrypto = async (): Promise<IFetchCrypto> => {
  const response = await (await https()).get('/crypto/account')
  return response.data
}

const cryptoApi = {
  fetchCrypto,
}

export default cryptoApi
