import { cashAdvanceApi, segmentApi, walletApi } from '@root/api'
import { ENV } from '@root/config'
import { ADD_CARD_FAILED_LABEL } from '@root/constants'
import {
  useAuthStore,
  useBackdoorStore,
  useInstacashStore,
  useUIStore,
  useWalletStore,
} from '@root/store'
import { IErrorModalLabel, TAddCardErrorLabelType } from '@root/types/Error'
import { IWalletAddCardRequest } from '@root/types/Wallet'
import { formats } from '@root/utils'
import { useOnboardingDialog } from './useOnboardingDialog'

interface IInstacashRequestAddCardSegmentProperty {
  action: string
  type?: string
  errorCode?: string
  failReason?: string
}

interface CardReturn {
  addCardInstacash: (documentIds?: string[]) => Promise<void>
}

const useCard = (): CardReturn => {
  const entryPointType = 'debit_card'
  const {
    showAddCardMissingFundIdFailedDialog,
    showAddCardUnrecognizedFundTypeFailedDialog,
    showCustomAddCardLinkDebitCardFailedDialog,
    showCustomAddCardFailedDialog,
  } = useOnboardingDialog()
  const instacashAddCardModal = useBackdoorStore(
    (state) => state.instacashAddCardModal
  )
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)
  const user = useAuthStore((state) => state.user)
  const applicationId = useInstacashStore((state) => state.applicationId)
  const card = useWalletStore((state) => state.card)

  const openErrorModal = (type: TAddCardErrorLabelType): void => {
    const labels: IErrorModalLabel = ADD_CARD_FAILED_LABEL[type]

    if (type === 'WAL4001') {
      showAddCardUnrecognizedFundTypeFailedDialog()
    } else if (
      ['WAL1029', 'FUND004', 'LINK_DEBIT_CARD_GENERIC_ERROR'].includes(type)
    ) {
      showCustomAddCardLinkDebitCardFailedDialog(labels)
    } else if (type === 'MISSING_FUND_ID_ERROR') {
      showAddCardMissingFundIdFailedDialog(applicationId || '')
    } else {
      showCustomAddCardFailedDialog(labels)
    }
  }

  const segmentTrackIcRequestAddCardAction = (
    segmentProperty: IInstacashRequestAddCardSegmentProperty
  ): void => {
    const { action, type, errorCode, failReason } = segmentProperty

    segmentApi.trackWithOSPlatform('ca_request_add_card_action', {
      action,
      application_id: applicationId,
      type,
      error_code: errorCode,
      fail_reason: failReason,
    })
  }

  const segmentTrackFailure = (type: string, error: any): void => {
    segmentTrackIcRequestAddCardAction({
      action: 'fail',
      type,
      errorCode: error?.data?.code,
      failReason: error?.data?.message || error,
    })
  }

  const getErrorLabelType = (
    genericErrorType: string,
    errorCode: TAddCardErrorLabelType
  ): string => {
    const isErrorLabelExist = ADD_CARD_FAILED_LABEL.hasOwnProperty(errorCode)
    const errorLabelType = isErrorLabelExist ? errorCode : genericErrorType

    return errorLabelType
  }

  const getPostWalletAddCardRequest = (
    endpointType: string,
    documentIds?: string[]
  ): IWalletAddCardRequest => {
    if (
      !user ||
      !user.email ||
      !card ||
      !card.name ||
      !card.homeStreetAddress ||
      !card.homeCity ||
      !card.homeZip ||
      !card.homeState ||
      !card.number ||
      !card.cvv ||
      !card.expiryDate
    ) {
      segmentTrackIcRequestAddCardAction({
        action: 'fail',
        type: endpointType,
        failReason: 'missing_data',
      })

      throw new Error('ADD_CARD_GENERIC_ERROR')
    }

    const {
      name,
      homeStreetAddress,
      homeApt,
      homeCity,
      homeZip,
      homeState,
      number,
      cvv,
      expiryDate,
    } = card

    return {
      name,
      email: user?.email,
      addrLine1: homeStreetAddress,
      addrLine2: homeApt,
      city: homeCity,
      postCode: homeZip,
      state: homeState,
      number: number.replace(/-/g, ''),
      last4Digits: formats.last4Digit(number),
      cvc: cvv,
      expiryMonth: Number(expiryDate.slice(0, 2)),
      expiryYear: Number(expiryDate.slice(-2)),
      fundOptionType: 'DEBIT_CARD',
      documentIds,
    }
  }

  /**
   * @usage: For the Add Card Flow
   * @description: Step 1 - Add Card
   * @param {documentIds}: The esign document ids
   * @error: Sample
   *  {
   *    code: "400",
   *    data: {
   *      code: "WAL2037",
   *      details: "FundException"
   *      message: "Card already exists",
   *      requestId: string,
   *      status: "ERROR",
   *    message: "Bad Request",
   *  }
   */
  const addCard = async (documentIds?: string[]): Promise<number> => {
    const endpointType = 'ADD_CARD'
    const genericErrorType = 'ADD_CARD_GENERIC_ERROR'

    try {
      segmentApi.trackWithOSPlatform('pay_backup_authorisation_submit', {
        type: entryPointType,
      })

      const postWalletAddCardRequest: IWalletAddCardRequest =
        getPostWalletAddCardRequest(endpointType, documentIds)

      const { data } = await walletApi.postWalletAddCard(
        postWalletAddCardRequest
      )

      if (!data || !data.fundId) {
        segmentTrackIcRequestAddCardAction({
          action: 'fail',
          type: endpointType,
          failReason: 'missing_fund_id',
        })

        return Promise.reject(new Error(genericErrorType))
      }

      return data.fundId
    } catch (error: any) {
      segmentTrackFailure(endpointType, error)

      const errorLabelType = getErrorLabelType(
        genericErrorType,
        error?.data?.code
      )

      return Promise.reject(new Error(errorLabelType))
    }
  }

  /**
   * @usage: For the Instacash Add Card Flow
   * @description: Step 2 - Check whether the 'fundId' exist in the 'DEBIT_CARD' repayment fund options
   */
  const checkRepaymentFundOptions = async (fundId: number): Promise<void> => {
    const endpointType = 'REPAYMENT_FUND_OPTION_CHECK'
    const genericErrorType = 'LINK_DEBIT_CARD_GENERIC_ERROR'

    try {
      const { data } = await cashAdvanceApi.getRepaymentFundOptions(
        'DEBIT_CARD'
      )

      const dataFund = data?.funds.find((fund) => fund.fundId === fundId)

      if (!dataFund || !dataFund.fundId || !fundId) {
        segmentTrackIcRequestAddCardAction({
          action: 'fail',
          type: endpointType,
          failReason: 'missing_fund_data',
        })

        return Promise.reject(new Error('MISSING_FUND_ID_ERROR'))
      }
    } catch (error: any) {
      segmentTrackFailure(endpointType, error)

      const errorLabelType = getErrorLabelType(
        genericErrorType,
        error?.data?.code
      )

      return Promise.reject(new Error(errorLabelType))
    }
  }

  /**
   * @usage: For the Instacash Add Card Flow
   * @description: Step 3 - Link the debit card to the 'INSTACASH_REPAYMENT' product
   */
  const linkProduct = async (fundId: number): Promise<void> => {
    const endpointType = 'LINK_FUND_PRODUCT'
    const genericErrorType = 'LINK_DEBIT_CARD_GENERIC_ERROR'

    try {
      if (!fundId) {
        segmentTrackIcRequestAddCardAction({
          action: 'fail',
          type: endpointType,
          failReason: 'missing_fund_data',
        })

        return Promise.reject(new Error(genericErrorType))
      }

      const { code, message } = await walletApi.postWalletLinkProduct({
        fundId,
        productTypes: 'INSTACASH_REPAYMENT',
      })

      if (code === '200') {
        segmentTrackIcRequestAddCardAction({
          action: 'complete',
        })

        return
      } else {
        segmentTrackIcRequestAddCardAction({
          action: 'fail',
          type: endpointType,
          errorCode: code,
          failReason: message,
        })

        return Promise.reject(new Error(genericErrorType))
      }
    } catch (error) {
      segmentTrackFailure(endpointType, error)

      return Promise.reject(new Error(genericErrorType))
    }
  }

  const addCardInstacash = async (documentIds?: string[]): Promise<void> => {
    try {
      if (
        ENV.APP_ENV !== 'production' &&
        instacashAddCardModal &&
        instacashAddCardModal !== 'GENERATE_ESIGN_GENERIC_ERROR'
      ) {
        segmentTrackIcRequestAddCardAction({
          action: 'fail',
          failReason: 'test_error_modal',
        })

        throw instacashAddCardModal
      }

      const fundId = await addCard(documentIds)
      await checkRepaymentFundOptions(fundId)
      await linkProduct(fundId)
    } catch (error: any) {
      const errorLabelType = error?.message
      const isErrorLabelExist =
        ADD_CARD_FAILED_LABEL.hasOwnProperty(errorLabelType)

      if (isErrorLabelExist) {
        openErrorModal(errorLabelType)
      } else {
        setShowAppDialog(true)
      }

      return Promise.reject(new Error('ADD_CARD_INSTACASH_ERROR'))
    }
  }

  return { addCardInstacash }
}

export { useCard }
