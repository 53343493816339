const labels = {
  title: 'Select your bank',
  description:
    ' To date MoneyLion customers linked 16 million bank accounts with the MoneyLion app',
  button: 'Link my bank account',
  guide: 'Can’t find your bank? Try searching for it above.',
  searchNone: 'Can’t find your bank. Please search again.',
}

export { labels }
