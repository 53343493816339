import styled from 'styled-components'

export const BankListContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color?.BORDER};
  border-radius: 16px;
  overflow: hidden;
`

export const TopBankContainer = styled.div`
  gap: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));

  .top-bank-card {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.color?.BORDER};
    height: 75px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`
