import { TColor } from '@moneylion/mlds-web'
import { SplitFactory } from '@splitsoftware/splitio-react'
import {
  useEffect,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { useRouter } from 'next/router'
import { segmentApi } from '@root/api'
import PlaceholderLoader from '@root/components/Loader/PlaceholderLoader'
import { ENV } from '@root/config'
import {
  idtPrerequisites,
  PROTECTED_PAGES_PATHS,
  UNPROTECTED_PAGES_PATHS,
} from '@root/constants'
import { BackdoorSettings } from '@root/experiences/Onboarding/components'
import {
  useAuth,
  useInstacashDisbursement,
  useOnboardState,
  useOnboardingDialog,
} from '@root/hooks'
import theme from '@root/theme'
import { useOnboardingStore } from '@root/store'
import { goToDownloadApp } from '@root/utils'
import { MetaTags } from '../MetaTags'
import { DashboardLayoutProps } from '../Dashboard'
import { OnboardingBody } from './OnboardingLayout.styles'
import {
  OnboardingHeader,
  OnboardingHeaderProps,
  OnboardingSidebar,
} from '@onboarding/modules'

const defaultHeaderOptions: OnboardingHeaderProps = {
  hideBackButton: false,
  onClickBack: undefined,
  faqLink: '',
  showCloseButton: false,
  onClickClose: undefined,
  showRightCloseButton: false,
}

const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: ENV.SPLIT_IO_KEY_ONBOARDING,
    key: 'anonymous',
  },
}

export const OnboardingHeaderContext: React.Context<{
  setHeaderOptions: Dispatch<SetStateAction<OnboardingHeaderProps>>
}> = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setHeaderOptions: (value) => {},
})

interface OnboardingLayoutProps extends DashboardLayoutProps {
  backgroundPageColor?: TColor
}

const OnboardingLayout = (props: OnboardingLayoutProps): JSX.Element => {
  const { children, maxWidth = '448px', backgroundPageColor, title } = props
  const router = useRouter()
  const { status } = useAuth()
  const { fetchOnboardState, result, canEnterFlow, setCanEnterFlow } =
    useOnboardState()
  const {
    canEnterAddCardFlow,
    canEnterDisbursementFlow,
    checkAddCardRequirements,
    checkDisbursementRequirements,
  } = useInstacashDisbursement()
  const { showIdtHardRejectDialog } = useOnboardingDialog()
  const isIntentFlow = useOnboardingStore((state) => state.isIntentFlow)
  const setIsIntentFlow = useOnboardingStore((state) => state.setIsIntentFlow)

  // redirect to `/login` if user is not logged in
  useEffect(() => {
    if (status === 'unauthorized') {
      router.push(UNPROTECTED_PAGES_PATHS.login)
    }

    // TODO: Specify the first landing page
    const isIntent = new URLSearchParams(window.location.search).get('intent')
    if (isIntentFlow === undefined && isIntent !== null) {
      setIsIntentFlow(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, router.pathname])

  // fetch onboard state on certain pages only
  useEffect(() => {
    if (status !== 'authorized') return

    switch (router.pathname) {
      case PROTECTED_PAGES_PATHS.cryptoIntro:
      case PROTECTED_PAGES_PATHS.cryptoRoarmoneyAccountActivation:
      case PROTECTED_PAGES_PATHS.roarmoneyIntro:
      case PROTECTED_PAGES_PATHS.roarmoneyAccountActivation:
      case PROTECTED_PAGES_PATHS.roarmoneyActivateCard:
        fetchOnboardState({
          productName: 'DDA2',
        })
        break
      case PROTECTED_PAGES_PATHS.instacashAddCardStart:
      case PROTECTED_PAGES_PATHS.instacashAddCard:
      case PROTECTED_PAGES_PATHS.instacashAddCardPickBillingAddress:
      case PROTECTED_PAGES_PATHS.instacashAddCardAddBillingAddress:
      case PROTECTED_PAGES_PATHS.instacashAddCardBackupAuth:
        checkAddCardRequirements()
        break
      case PROTECTED_PAGES_PATHS.instacashDisbursementActivation:
      case PROTECTED_PAGES_PATHS.instacashDisbursementDestination:
        checkDisbursementRequirements()
        break
      default:
        setCanEnterFlow(true)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, status])

  useEffect(() => {
    segmentApi.page()
  }, [router.pathname])

  useEffect(() => {
    const isIDTNeeded = result?.prerequisite.some((items) =>
      idtPrerequisites.includes(items)
    )

    if (isIDTNeeded || result?.stateName === 'ONBOARDED') {
      goToDownloadApp('roarmoney')
      return
    }

    if (result?.status === 'ERR_VERIFICATIONAPI_IDENTITY_NOT_TRUSTED') {
      showIdtHardRejectDialog()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  const [headerOptions, setHeaderOptions] =
    useState<OnboardingHeaderProps>(defaultHeaderOptions)

  const onboardingHeaderProviderValue = useMemo(
    () => ({ setHeaderOptions }),
    [setHeaderOptions]
  )

  const checkShouldShowLoader = (): boolean => {
    if (
      [
        PROTECTED_PAGES_PATHS.cryptoIntro,
        PROTECTED_PAGES_PATHS.cryptoRoarmoneyAccountActivation,
        PROTECTED_PAGES_PATHS.roarmoneyIntro,
        PROTECTED_PAGES_PATHS.roarmoneyAccountActivation,
        PROTECTED_PAGES_PATHS.roarmoneyActivateCard,
      ].includes(router.pathname)
    ) {
      return !canEnterFlow
    } else if (
      [
        PROTECTED_PAGES_PATHS.instacashAddCardStart,
        PROTECTED_PAGES_PATHS.instacashAddCard,
        PROTECTED_PAGES_PATHS.instacashAddCardPickBillingAddress,
        PROTECTED_PAGES_PATHS.instacashAddCardAddBillingAddress,
        PROTECTED_PAGES_PATHS.instacashAddCardBackupAuth,
      ].includes(router.pathname)
    ) {
      return !canEnterAddCardFlow
    } else if (
      [
        PROTECTED_PAGES_PATHS.instacashDisbursementActivation,
        PROTECTED_PAGES_PATHS.instacashDisbursementDestination,
      ].includes(router.pathname)
    ) {
      return !canEnterDisbursementFlow
    }

    return false
  }

  return (
    <OnboardingHeaderContext.Provider value={onboardingHeaderProviderValue}>
      <MetaTags category="Onboarding" title={title} />
      {status !== 'authorized' || checkShouldShowLoader() ? (
        // TODO: replace with skeleton
        <PlaceholderLoader />
      ) : (
        <div
          style={{
            backgroundColor: backgroundPageColor
              ? backgroundPageColor
              : theme.color?.BACKGROUND_PAGE,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <OnboardingSidebar />
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            <OnboardingHeader {...headerOptions} />
            <SplitFactory config={sdkConfig}>
              <OnboardingBody maxWidth={maxWidth}>{children}</OnboardingBody>
            </SplitFactory>
          </div>
          <BackdoorSettings />
        </div>
      )}
    </OnboardingHeaderContext.Provider>
  )
}

export { OnboardingLayout }
