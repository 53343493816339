import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import IUser, {
  IGetUserRewardsResponse,
  IGetInvestmentAccountResponse,
  AddressValidateRes,
} from '@root/types/User'
import {
  biddingApi,
  contactApi,
  lifecycleApi,
  segmentApi,
  userApi,
} from '@root/api'
import { IInputAddress } from '@root/types/Address'
import { isAddressValid } from '@root/utils/validateAddress'
import { ChallengeResponse } from '@root/api/contactApi'
import { useAuthStore } from './authStore'

interface UsersInitialState {
  rewards: IGetUserRewardsResponse | null
  investment: IGetInvestmentAccountResponse | null
  leadGenTransactionId: string | null
  leadUuid: string | null
  offerId: string | null
  utmSource: string | null
  phoneFromUrl: string | null
}

interface VerifyMfaChallengeProps {
  phoneNumber: string
  mfaId: string
  mfaVerificationCode: string
}

export interface UserState extends UsersInitialState {
  getRewards: () => void
  getInvestmentAccount: () => void
  setLeadGenTransactionId: (leadGenTransactionId: string) => void
  prefillPiiFromLeadInfo: (
    abortSignal?: AbortSignal
  ) => Promise<{ address?: IInputAddress; ssn?: string } | undefined>
  getUser: (abortSignal?: AbortSignal) => Promise<IUser | null>
  retrievePii: (abortSignal?: AbortSignal) => Promise<void>
  updateDob: (dob?: string) => Promise<void>
  validateAddress: (
    address?: IInputAddress
  ) => Promise<AddressValidateRes['data']>
  updateAddress: (address?: IInputAddress) => Promise<void>
  contactVerificationChallenge: (
    phoneNumber: string
  ) => Promise<ChallengeResponse['data']>
  updatePhoneNumber: (isSmsConsentChecked: boolean) => Promise<void>
  verifyMfaChallenge: (props: VerifyMfaChallengeProps) => Promise<string>
  checkIsPhoneNumberVerified: () => Promise<boolean>
  verifySsn: (ssn: string) => Promise<void>
  reset: () => void
}

const defaultState: UsersInitialState = {
  rewards: null,
  investment: null,
  leadGenTransactionId: null,
  leadUuid: null,
  offerId: null,
  utmSource: null,
  phoneFromUrl: null,
}

// TODO: Move user data from AuthStore to UserStore
const useUserStore = create<UserState>()(
  persist(
    (set, getState) => ({
      ...defaultState,
      getRewards: async () => {
        const rewards = await userApi.getUserRewards()
        set({ rewards })
      },
      getInvestmentAccount: async () => {
        const investment = await userApi.getUserInvestmentAccount()
        set({ investment })
      },
      setLeadGenTransactionId: (leadGenTransactionId: string) => {
        set({ leadGenTransactionId })
      },
      prefillPiiFromLeadInfo: async (abortSignal) => {
        const { leadUuid, offerId, utmSource } = getState()

        if (!leadUuid) return

        const { data } = await biddingApi.leadGetUserInfo(
          {
            leadUuid,
            utmSource: utmSource || '',
            offerId: offerId || '',
          },
          abortSignal
        )
        const { personalInformation } = data

        if (!personalInformation) return

        const setUserPartialData = useAuthStore.getState().setUserPartialData

        const address = {
          addressLine1: personalInformation.address1,
          addressLine2: personalInformation.address2,
          city: personalInformation.city,
          state: personalInformation.state,
          postalCode: personalInformation.zipcode,
        }

        if (isAddressValid(address) || personalInformation.ssn) {
          setUserPartialData({
            addresses: [
              {
                addressLine1: personalInformation.address1,
                addressLine2: personalInformation.address2,
                city: personalInformation.city,
                state: personalInformation.state,
                postalCode: personalInformation.zipcode,
              },
            ],
            ssn: personalInformation.ssn,
          })
        }

        return {
          address: address,
          ssn: personalInformation.ssn,
        }
      },
      getUser: async (abortSignal) => {
        const retrievePii = getState().retrievePii

        await retrievePii(abortSignal)

        return useAuthStore.getState().user
      },
      retrievePii: async (abortSignal) => {
        /**
         * @note: Address and mobile number should come from Lifecycle API and not from User API
         */
        const { data: latestData } = await lifecycleApi.retrievePii(abortSignal)

        const {
          id,
          countryIsoCode,
          createdOn,
          addressLine1,
          addressLine2,
          city,
          state,
          postalCode,
          verificationStatus,
        } = latestData.address

        const setUserPartialData = useAuthStore.getState().setUserPartialData

        setUserPartialData({
          addresses: [
            {
              id,
              country: countryIsoCode,
              status: verificationStatus,
              createdOn,
              addressLine1,
              addressLine2,
              city,
              state,
              postalCode,
            },
          ],
          phoneNumber: {
            number: latestData.phoneNumber,
            isVerified: true,
          },
        })
      },
      updateDob: async (dob?: string) => {
        const setUserPartialData = useAuthStore.getState().setUserPartialData

        /**
         * Need to use Promise API here in order to propagate exception handling to the higher level try catch block
         */
        userApi
          .updateUser({
            dob: dob?.replace(/[/]/gi, '-'),
          })
          .then((res) => {
            const dob = res.data.dob
            setUserPartialData({
              dateOfBirth: dob,
            })
            segmentApi.trackWithOSPlatform('acc_core_dob_submit', {
              status: 'success',
            })
          })
          .catch((err) => {
            segmentApi.trackWithOSPlatform('acc_core_dob_submit', {
              status: err?.message === 'TIMEOUT' ? 'timeout' : 'fail',
              fail_reason: err?.message || err,
            })
          })
      },
      validateAddress: async (address?: IInputAddress) => {
        const { data } = await userApi.validateAddress({
          addressLine1: address?.streetAddress,
          addressLine2: address?.apt,
          city: address?.city,
          state: address?.state,
          postalCode: address?.zip,
          country: 'US',
        })

        return {
          status: data.status,
          address: data.address,
          reasons: data.reasons,
        }
      },
      updateAddress: async (address?: IInputAddress) => {
        await lifecycleApi.updateAddress({
          coreOnboardAddress: {
            addressLine1: address?.streetAddress,
            addressLine2: address?.apt,
            city: address?.city,
            state: address?.state,
            postalCode: address?.zip,
          },
        })

        const retrievePii = getState().retrievePii

        await retrievePii()
      },
      contactVerificationChallenge: async (phoneNumber: string) => {
        const verificationData = await contactApi.verifyContact(phoneNumber)
        const { sessionId } = verificationData.data
        const { data } = await contactApi.challenge({ sessionId })
        return data
      },
      updatePhoneNumber: async (isSmsConsentChecked: boolean) => {
        await userApi.updateUser({
          textPermission: isSmsConsentChecked,
        })

        const retrievePii = getState().retrievePii

        await retrievePii()
      },
      verifyMfaChallenge: async ({
        phoneNumber,
        mfaId,
        mfaVerificationCode,
      }) => {
        const { data } = await userApi.challengeAndUpdatePhoneNumber({
          phoneNumber,
          mfaVerification: {
            mfaId,
            mfaVerificationCode,
            returnContact: true,
          },
        })

        const user = useAuthStore.getState().user
        const setUserPartialData = useAuthStore.getState().setUserPartialData

        if (data.code !== '1') throw data.description || data

        const mobileNumber =
          data.mfaVerificationResult && data.mfaVerificationResult.contactTarget

        setUserPartialData({
          ...data,
          phoneNumber: {
            number: mobileNumber,
            isVerified: true,
          },
        })

        segmentApi.identify(user?.email, { phone: mobileNumber })

        segmentApi.trackWithOSPlatform('Phone Verification Checked', {
          result: 'success',
        })

        return data.code
      },
      checkIsPhoneNumberVerified: async () => {
        const { data } = await lifecycleApi.retrievePii()
        return !!(data && data.phoneNumber)
      },
      verifySsn: async (ssn) => {
        await userApi.updateUser({ ssn })

        segmentApi.trackWithOSPlatform('acc_core_ssn_submit', {
          status: 'success',
        })

        const setUserPartialData = useAuthStore.getState().setUserPartialData

        const { data } = await userApi.getUserProfile(true)

        if (!data.ssn) throw new Error('SSN not found')

        const lastFourDigitsOfSSN = data.ssn.slice(-4)

        setUserPartialData({
          ssn: data.ssn,
          lastFourDigitsOfSsn: lastFourDigitsOfSSN,
        })
      },
      reset: () => {
        set(defaultState)
        useUserStore.persist.clearStorage()
      },
    }),
    {
      partialize: (state) => ({
        leadGenTransactionId: state.leadGenTransactionId,
        leadUuid: state.leadUuid,
        offerId: state.offerId,
        utmSource: state.utmSource,
        phoneFromUrl: state.phoneFromUrl,
      }),
      name: 'leadInfo',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useUserStore }
