import {
  Icon,
  IconsId,
  Label,
  List,
  ListBulletProps,
  Body,
} from '@moneylion/mlds-web'
import { useTheme } from 'styled-components'
import { ListTitle, ListTitleProps } from '../../components/ListTitle'
import { WhiteRoundedContainer } from '../../components/Containers.styles'

interface IconListProps {
  type: 'icon' | 'image'
  shape?: 'circle' | 'square'
  iconColor?: 'black' | 'white'
  iconBackgroundColor?: 'accent' | 'dark' | 'none'
  divider?: 'none' | 'full'
  items: ItemContent[]
  endingInfo?: string
  listTitle?: ListTitleProps
  style?: React.CSSProperties
}

export interface ItemContent {
  main: string
  sub?: string
  icon?: IconsId
  imageUrl?: string
  rightLabel?: string
  rightIcon?: IconsId
  onClickRightIcon?: (params: unknown) => void
}

const IconList = ({
  type,
  shape = 'circle',
  iconColor = 'black',
  iconBackgroundColor = 'none',
  divider,
  items,
  endingInfo,
  listTitle,
  style,
}: IconListProps): JSX.Element => {
  const theme = useTheme()

  const mldsColorMapping = {
    accent: theme.color?.BACKGROUND_CONTENT_ACCENT,
    dark: theme.color?.BACKGROUND_CONTENT_DARK,
    none: undefined,
    black: theme.color?.CONTENT_PRIMARY,
    white: theme.color?.CONTENT_ON_COLOR_PRIMARY,
  }

  const iconOptions = {
    square: shape === 'square',
    iconColor: mldsColorMapping[iconColor],
    color: mldsColorMapping[iconBackgroundColor],
  }

  const iconOptionsByType = (item: ItemContent): ListBulletProps =>
    type === 'icon'
      ? {
          ...iconOptions,
          icon: item.icon,
        }
      : {
          ...iconOptions,
          url: item.imageUrl || '',
        }

  return (
    <WhiteRoundedContainer id="icon-list" className="mb-3" style={style}>
      <List>
        {listTitle ? (
          <List.Item
            divider="none"
            disabledEffects={['hover', 'active', 'focus', 'visited']}
          >
            <ListTitle
              text={listTitle.text}
              withHelpIcon={listTitle.withHelpIcon}
              onClickOpenHelpModal={listTitle.onClickOpenHelpModal}
            />
          </List.Item>
        ) : (
          <></>
        )}
        {items.map((item, index) => (
          <List.Item
            key={item.main + index}
            divider={index !== items.length - 1 ? divider : 'none'}
            disabledEffects={['hover', 'active', 'focus', 'visited']}
            bullet={iconOptionsByType(item)}
          >
            <List.Label main={item.main} sub={item.sub} />
            {item.rightLabel && (
              <Label
                style={{
                  textAlign: 'right',
                  alignSelf: 'flex-start',
                }}
              >
                {item.rightLabel}
              </Label>
            )}
            {item.rightIcon && (
              <Icon
                data-testid={`icon_${item.rightIcon}`}
                name={item.rightIcon}
                color="tertiary"
                style={{
                  position: 'absolute',
                  alignSelf: 'flex-start',
                  cursor: item.onClickRightIcon ? 'pointer' : 'default',
                }}
                onClick={item.onClickRightIcon}
              />
            )}
          </List.Item>
        ))}
        {endingInfo ? (
          <List.Item disabledEffects={['hover', 'active', 'focus', 'visited']}>
            <Body
              size="medium"
              color="secondary"
              style={{ marginTop: '-14px' }}
            >
              {endingInfo}
            </Body>
          </List.Item>
        ) : (
          <></>
        )}
      </List>
    </WhiteRoundedContainer>
  )
}

export { IconList }
