import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { IEsign, BankingActiveDocument } from '@root/types/Esign'
import {
  IGetSubscriptionEsignRequest,
  IEsignDocument,
} from '@root/types/Subscription'
import { bankingApi, subscriptionApi } from '@root/api'
import { useAuthStore } from './authStore'

interface EsignInitialState {
  cbPlusMandatoryEsignDocuments?: IEsign[]
  cbPlusOptionalEsignDocuments?: IEsign[]
  subscriptionEsignDocuments?: IEsign[]
  subscriptionOptionalEsignDocuments?: IEsign[]
  hasSignOptionalEsignDocuments: boolean
  bankingEsignDocuments?: BankingActiveDocument[]
}

export interface EsignState extends EsignInitialState {
  getSubscriptionEsignDocuments: (
    request: IGetSubscriptionEsignRequest,
    signal?: AbortSignal
  ) => Promise<{
    unsignedMandatoryDocuments?: IEsign[]
    unsignedOptionalDocuments?: IEsign[]
  }>
  signSubscriptionEsignDocuments: (signal?: AbortSignal) => Promise<boolean>
  getBankingDocuments: (signal?: AbortSignal) => Promise<void>
  signBankingDocuments: () => Promise<void>
  setCbPlusMandatoryEsignDocuments: (esignDocuments: IEsignDocument[]) => void
  setCbPlusOptionalEsignDocuments: (esignDocuments: IEsignDocument[]) => void
  reset: () => void
}

const initialState: EsignInitialState = {
  cbPlusMandatoryEsignDocuments: undefined,
  cbPlusOptionalEsignDocuments: undefined,
  subscriptionEsignDocuments: undefined,
  subscriptionOptionalEsignDocuments: undefined,
  hasSignOptionalEsignDocuments: false,
  bankingEsignDocuments: undefined,
}

const getUnsignedDocuments = (esignDocuments: IEsignDocument[]): IEsign[] => {
  const unsignedDocuments = esignDocuments
    .filter(({ esigned }) => !esigned)
    .map((esignDocument) => {
      const { documentId, documentName } = esignDocument
      return {
        id: documentId,
        name: documentName,
      }
    })

  return unsignedDocuments
}

const useEsignStore = create<EsignState>()(
  persist(
    (set, get) => ({
      ...initialState,
      getSubscriptionEsignDocuments: async (
        request: IGetSubscriptionEsignRequest,
        signal?: AbortSignal
      ) => {
        const { data } = await subscriptionApi.postGetSubscriptionEsign(
          request,
          signal
        )

        const unsignedMandatoryDocuments = getUnsignedDocuments(
          data?.mandatoryEsignDocuments
        )

        const unsignedOptionalDocuments = getUnsignedDocuments(
          data?.optionalEsignDocuments
        )

        set({
          subscriptionEsignDocuments: unsignedMandatoryDocuments,
        })

        return {
          unsignedMandatoryDocuments,
          unsignedOptionalDocuments,
        }
      },
      signSubscriptionEsignDocuments: async (signal) => {
        try {
          const { user } = useAuthStore.getState()
          const { subscriptionEsignDocuments } = get()

          if (!user || !subscriptionEsignDocuments?.length) {
            return Promise.reject(new Error('SIGN_ESIGN_BAD_REQUEST'))
          }

          const documentIds = subscriptionEsignDocuments.map(({ id }) => id)
          const signature = `${user.firstName} ${user.lastName}`

          const { data } = await subscriptionApi.postSignSubscriptionEsign(
            {
              documentIds,
              signature,
            },
            signal
          )

          return [0, 115].includes(data?.statusCode)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      getBankingDocuments: async (signal) => {
        const { data } = await bankingApi.getBankingEsign(signal)
        set({
          bankingEsignDocuments: data.activeAgreements,
        })
      },
      signBankingDocuments: async () => {
        const { bankingEsignDocuments } = get()

        if (!bankingEsignDocuments?.length)
          return Promise.reject(new Error('SIGN_BANKING_ESIGN_BAD_REQUEST'))

        const documentIds = bankingEsignDocuments.map(({ id }) => id)
        const payload = {
          documentIds,
        }

        const response = await bankingApi.signBankingEsign(payload)

        if (response.data.message !== `User's e-signed documents processed`)
          return Promise.reject(new Error(response.data.message))
      },
      setCbPlusMandatoryEsignDocuments: (esignDocuments: IEsignDocument[]) => {
        const unsignedDocuments = getUnsignedDocuments(esignDocuments)
        set({ cbPlusMandatoryEsignDocuments: unsignedDocuments })
      },
      setCbPlusOptionalEsignDocuments: (esignDocuments: IEsignDocument[]) => {
        const unsignedDocuments = getUnsignedDocuments(esignDocuments)
        set({ cbPlusOptionalEsignDocuments: unsignedDocuments })
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        cbPlusMandatoryEsignDocuments: state.cbPlusMandatoryEsignDocuments,
        cbPlusOptionalEsignDocuments: state.cbPlusOptionalEsignDocuments,
        subscriptionOptionalEsignDocuments:
          state.subscriptionOptionalEsignDocuments,
        hasSignOptionalEsignDocuments: state.hasSignOptionalEsignDocuments,
      }),
      name: 'esignStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useEsignStore }
