export const looksLikeRoarTagErrorMessage =
  'This looks like a $RoarTag referral code, try adding $ in front of the text.'
export const incorrectFormatErrorMessage = 'Incorrect format'

const ROARTAG_MIN_LENGTH = 3
const ROARTAG_MAX_LENGTH = 20
const REFERRAL_CODE_LENGTH = 8

const validateRoarTag = (roarTag: string): string | undefined => {
  const isValidRoarTagLength =
    roarTag.length >= ROARTAG_MIN_LENGTH && roarTag.length <= ROARTAG_MAX_LENGTH
  const isRoarTagFormat = /^(?=.*[a-zA-Z].*)([a-zA-Z0-9-_]+)$/.test(roarTag)

  if (!isValidRoarTagLength || !isRoarTagFormat)
    return incorrectFormatErrorMessage

  return undefined
}

export const validateReferralCodeRoarTag = (
  value?: string
): string | undefined => {
  if (!value) return undefined

  const startsWithDollar = /^\$/.test(value)
  const isValidReferralCodeLength = value.length === REFERRAL_CODE_LENGTH
  const isValidReferralCodeFormat = /^[0-9a-zA-Z]+$/.test(value)
  const isReferralCode = isValidReferralCodeLength && isValidReferralCodeFormat

  if (startsWithDollar) {
    const roarTag = value.slice(1)
    return validateRoarTag(roarTag)
  }

  if (!startsWithDollar && !isReferralCode) {
    return looksLikeRoarTagErrorMessage
  }
}

export const referralCodeRoarTagValidationRules = [
  (value: string) => {
    const result = validateReferralCodeRoarTag(value)
    return result === undefined ? true : result
  },
]
