import { Body, Label } from '@moneylion/mlds-web'
import React from 'react'
import { lionSignApi } from '@root/api'
import { useUIStore } from '@root/store'
import { createBlobPdfUrl } from '@root/utils'
import LegalAgreementDialog from '@root/experiences/Onboarding/components/LegalAgreementDialog'

interface LegalAgreementDocumentProps {
  id: string
  name: string
  link?: string
  type?: 'body' | 'label'
}

const LegalAgreementDocument = ({
  id,
  link,
  name,
  type = 'body',
}: LegalAgreementDocumentProps): JSX.Element => {
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)
  const setShowLegalAgreementDialog = useUIStore(
    (state) => state.setShowLegalAgreementDialog
  )

  const getPdfDocument = async (
    documentName: string,
    documentId: string,
    documentLink?: string
  ): Promise<void> => {
    try {
      if (documentLink) {
        setShowLegalAgreementDialog(true, {
          title: documentName,
          src: documentLink,
        })

        return
      }

      const { data } = await lionSignApi.postGetPdfDocument(documentId)

      if (!data || !data.document || !data.document.data) {
        setShowAppDialog(true)
        return
      }

      const newDocumentLink = createBlobPdfUrl(data.document.data)

      setShowLegalAgreementDialog(true, {
        title: documentName,
        src: newDocumentLink,
      })
    } catch {
      setShowAppDialog(true)
    }
  }

  const props = {
    className: 'cursor underline',
    onClick: () => getPdfDocument(name, id, link),
  }

  return (
    <>
      {type === 'label' ? (
        <Label size="large" style={{ fontWeight: 400 }} {...props}>
          {name}
        </Label>
      ) : (
        <Body size="medium" {...props}>
          {name}
        </Body>
      )}

      <LegalAgreementDialog />
    </>
  )
}

export { LegalAgreementDocument }
