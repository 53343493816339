import { Dialog, Body } from '@moneylion/mlds-web'
import styled from 'styled-components'

export const StyledDialog = styled.div`
  background: ${({ theme }) => theme.color?.BACKGROUND_CONTENT};
  margin: 0 auto;
  position: relative;
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 580px;

  padding: 36px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    padding: 24px 16px 16px 16px;
  }
`

export const StyledDialogTitle = styled(Dialog.Title)`
  font-size: 28px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    padding: 0 36px;
    text-align: center;
    margin-top: 8px;
    line-height: 36px;
  }
`

export const StyledDescription = styled(Body)`
  margin-top: 24px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    text-align: center;
    margin-top: 8px;
  }
`
export const DownloadAppContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 24px;
  padding: 0 22px;
  margin-top: 24px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    padding: 0;
  }
`

export const DownloadAppTitle = styled(Body)`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 4px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    font-size: 20px;
  }
`
export const DownloadAppDescription = styled(Body)`
  font-size: 16px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    font-size: 14px;
  }
`

export const AppDownloadWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 48px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    gap: 12px;
  }
`
